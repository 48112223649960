#main-nav {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    background: rgba(#000, 0.92);
    z-index: 5000;
    display: none;
    opacity: 0;
    transition: all 400ms ease-out;

    &.visible {
        opacity: 1;
    }

    @include breakpoint($md) {
        overflow-x: hidden;
        overflow-y: auto;
    }


    .breadcrumb {
        margin: 42px 0 0 0;
        display: none;

        @include breakpoint($md) {
            display: block;
        }


        ul {
            list-style: none;

            li {
                float: left;
                font-size: 20px;
                color: #808080;
                line-height: 1;
                font-weight: 300;
                margin-right: 6px;
                text-transform: uppercase;

                a {
                    color: #FFF;

                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }

    .context-mobile {
        @include breakpoint($md) {
            display: none;
        }


        ul {
            float: right;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                float: left;
                width: 56px;
                height: 56px;

                a {
                    display: block;
                    text-indent: -9999px;
                    overflow: hidden;
                    height: 56px;
                    width: 56px;
                    //background: url(../../img/icons/context-account.svg) no-repeat center center;
                    //background-size: 25px 25px;
                    background: url(../../img/icons/mercator-blue.svg) no-repeat right center;
                    background-size: 36px 36px;
                }

                .select-wrapper {
                    width: 56px;
                    height: 56px;
                    margin-left: 10px;
                    background: url(../../img/icons/context-globe-blue.svg) no-repeat center center;
                    background-size: 25px 25px;

                    select {
                        -webkit-appearance: none;
                        width: 56px;
                        height: 56px;
                        border-radius: 0;
                        opacity: 0;
                    }
                }

                &.context-some {
                    display: block;
                    width: 30px;
                    margin-left: 5px;

                    &.facebook {
                        margin-left: 20px;
                        width: 24px;
                    }

                    > a {
                        background-image: none;
                        position: relative;
                        color: $color-blue;
                        &:before{
                            content: '';
                            position: absolute;
                            top: 13px;
                            left: 0;
                            height: 30px;
                            width: 30px;
                            background: url(../../img/icons/facebook_t.svg) 0 0 no-repeat;
                            background-size: contain;
                        }
                    }

                    &.instagram {
                        > a {
                            &:before {
                                top: 13px;
                                background-image: url(../../img/icons/instagram_t.svg);
                            }
                        }
                    }

                    &.linkedin {
                        > a {
                            &:before {
                                top: 12px;
                                background-image: url(../../img/icons/linkedin_t.svg);
                            }
                        }
                    }

                    &.xing {
                        > a {
                            &:before {
                                background-image: url(../../img/icons/xing_t.svg);
                            }
                        }
                    }

                    &.mail {
                        > a {
                            &:before {
                                top: 16px;
                                background-image: url(../../img/icons/mail_w.svg);
                            }
                        }
                    }
                }
            }
        }
    }

    .scroller {
        position: absolute;
        left: 0;
        top: 56px;
        width: 100%;
        height: calc(100vh - 56px - 56px);
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transform: translate3d(0, 0, 0);

        @include breakpoint($md) {
            position: static;
            left: auto;
            top: auto;
            height: auto;
            overflow: visible;
            transform: none;
        }
    }

    .nav {
        padding-bottom: 20px;
    }

    .first-level {
        margin: 0;

        @include breakpoint($md) {
            margin: 17px 0 0 0;
        }


        ul {
            list-style: none;
            counter-reset: nav-count;

            li {
                &.active {
                    a {
                        color: $color-blue;

                        @include breakpoint($md) {
                            padding: 0 0 0 100px;

                            &:before {
                                background: $color-blue;
                            }
                            &:after {
                                color: $color-blue;
                            }

                            &:hover {
                                padding: 0 0 0 103px;

                                &:before {
                                    width: 33px;
                                }
                            }

                            &:before {
                                left: 20px;
                                width: 30px;
                            }

                            &:after {
                                left: 55px;
                            }
                        }
                    }
                }

                a {
                    display: block;
                    position: relative;
                    padding: 0 20px;
                    font-size: 20px;
                    line-height: 55px;
                    font-weight: 300;
                    transition: all 300ms ease;
                    transform: translate3d(0, 0, 0);
                    color: #FFF;

                    // background:rgba(#FFF, .2);

                    &:before {
                        content: "";
                        display: block;
                        width: 11px;
                        height: 11px;
                        background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
                        background-size: 11px 11px;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transition: all 300ms ease;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        color: $color-blue;
                    }

                    @include breakpoint($md) {
                        padding: 0 0 0 60px;
                        font-size: 40px;
                        line-height: 70px;

                        &:hover {
                            padding: 0 0 0 65px;

                            &:before {
                                width: 15px;
                                background: $color-blue;
                            }

                            &:after {
                                transform: translateX(5px);
                                color: $color-blue;
                            }
                        }

                        &:before {
                            width: 10px;
                            height: 2px;
                            background: $color-white;
                            top: 47px;
                            right: auto;
                            left: 0;
                            transform: none;
                        }

                        &:after {
                            counter-increment: nav-count;
                            content: "0" counter(nav-count);
                            display: block;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1;
                            color: $color-white;
                            position: absolute;
                            top: 36px;
                            left: 15px;
                            transition: all 300ms ease;
                        }
                    }
                }
            }
        }
    }

    .sub-level {
        margin: 0;
        display: none;

        @include breakpoint($md) {
            margin: 27px 0 0 0;
        }


        &.visible {
            display: block;
        }

        ul {
            list-style: none;
            counter-reset: nav-count;

            li {
                &.active {
                    a {
                        color: $color-blue;

                        @include breakpoint($md) {
                            padding-left: 48px;

                            &:before {
                                opacity: 1;
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                &.back {
                    a {
                        padding-left: 40px;
                        border-bottom: rgba(#FFF, 0.3) 1px solid;
                        font-size: 16px;

                        &:hover {
                            &:before {
                                background-image: url(../../img/icons/arrow-gt-blue.svg);
                            }
                        }

                        &:before {
                            right: auto;
                            left: 15px;
                            background-image: url(../../img/icons/arrow-gt-white.svg);
                            transform: translateY(-50%) rotateZ(180deg);
                        }

                        @include breakpoint($md) {
                            display: none;
                        }
                    }
                }

                &.has-sub-nav {
                    > a {
                        &:before {
                            width: 12px;
                            height: 12px;
                            background-image: url(../../img/icons/plus-blue.svg);
                            background-size: 12px 12px;
                        }

                        &.is-opened {
                            &:before {
                                background-image: url(../../img/icons/minus-blue.svg);
                            }
                        }
                    }
                }

                a {
                    display: block;
                    position: relative;
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 300;
                    color: #FFF;
                    transition: all 300ms ease;
                    padding: 15px 30px 15px 20px;

                    &:hover {
                        color: $color-blue;
                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 11px;
                        height: 11px;
                        background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
                        background-size: 11px 11px;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transition: all 300ms ease;
                        transform: translateY(-50%);
                    }

                    @include breakpoint($md) {
                        font-size: 26px;
                        line-height: 30px;
                        padding: 15px 52px 15px 0;
                        color: #bab9b9;

                        &:hover {
                            padding-left: 12px;
                            padding-right: 40px;
                        }
                    }
                }
            }
        }
    }

    .third-level {
        display: none;

        @include breakpoint($sm) {
            margin: 0;
        }


        ul {
            li {
                padding-left: 20px;

                a {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
    }

    .main-nav-bottom {
        background: #FFF;
        display: none;

        @include breakpoint($md) {
            display: block;
        }


        .search {
            @include breakpoint($sm) {
                padding-top: 42px;
            }
        }
    }
}
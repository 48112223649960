.teasers-story {
    margin: 32px 0 90px 0;
    background: #35465c;
    background: linear-gradient(to bottom, #35465c 0%, #0b0b1c 100%);

    @include breakpoint($sm) {
        background: transparent;
    }

    .flickity-viewport {
        transition: height 300ms ease;
    }

    .flickity-page-dots {
        top: 100%;
        height: 45px;
        margin: 9px 0 0 0;
        counter-reset: dot-count;
        display: none;

        @include breakpoint($md) {
            display: block;
        }
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 50px;
        line-height: 48px;
        opacity: 1;
        background: transparent;
        border: 0;
        border-bottom: $color-lila 2px solid;
        border-radius: 0;
        color: $color-grey;
        margin: 0 12px;
        transition: all 300ms ease;

        &:after {
            counter-increment: dot-count;
            content: "0" counter(dot-count);
            pointer-events: none;
        }

        &.is-selected {
            color: rgba($color-grey, 0.3);
            border-color: rgba($color-grey, 0.3);
        }
    }

    .carousel {}

    .cell {
        position:absolute;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .cell-inner {
            position: relative;
            width: 100%;
            padding: 24px 24px 24px 24px;

            @include breakpoint($md) {
                width: 50%;
                margin-left: 50%;
                padding: 60px 30px 10px 0;
            }


            @include breakpoint($lg) {
                padding: 60px 30px 10px 90px;
            }
        }
    }

    .link-wrapper {
        text-align: right;
    }

    .box {
        margin: 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }

        @include breakpoint($md) {
            margin: 0;

            h1 {
                margin-bottom: 26px;
            }
        }


        @include max-breakpoint($sm) {
            padding: 24px;
        }


        h1 {
            color: $color-red;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 18px;
        }
    }
}
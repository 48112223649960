.teaser-big {
    padding: 25px 0 32px 0;
    text-align: center;

    @include breakpoint($sm) {
        padding: 55px 0 50px 0;
    }

    h2 {
        position: relative;
        text-transform: uppercase;
        color: $color-black;
        margin: 0 0 45px 0;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 85px;
            height: 4px;
            transform: translateX(-50%);
            background: $color-lila;
            margin: 12px 0 0 0;
        }

        @include breakpoint($sm) {
            margin: 0 0 50px 0;

            &:after {
                margin: 18px 0 0 0;
            }
        }
    }

    p {
        line-height: 24px;
        margin: 0 0 13px 0;

        @include breakpoint($sm) {
            margin: 0 0 33px 0;
        }
    }
}
.media {
    background: $color-grey-light;
    margin: 32px 0;
    padding-top: 32px;
    padding-bottom: 32px;

    @include breakpoint($sm) {
        margin: 64px 0;
        padding: 64px 0 84px 0;
    }

    h3 {
        margin: 0 0 20px 0;
    }

    h4 {
        margin: 44px 0 20px 0;
    }

    .video-box {
        + .video-box {
            margin-top: 40px;
        }
    }

    .link-wrapper {
        text-align: right;
    }

    .image-box {
        position: relative;
        background: #FFF;
        margin: 0 0 24px 0;

        figure {
            width: 50%;
            float: left;
            padding: 10px 0 10px 10px;
        }

        .right-description {
            float: left;
            width: 50%;
            padding: 10px 20px 0 20px;

            p {
                line-height: 24px;
            }

            .link {
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.printOnly {
    display: none;
}

.content-image-text {
    background-color: $color-grey-light;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: $color-blue 6px solid;

    .overlay-light {
        padding-bottom: 20px;
    }

    small {
        margin: 45px 0 30px 0;
    }

    h3 {
        margin: 20px 0 26px 0;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin: 0 0 26px 0;
    }

    .image-wrapper {
        margin-top: 10px;

        img {
            height: auto;
        }
    }

    .mt20-50 {
        margin-top: 20px;
    }

    @include breakpoint($md) {

        .overlay-light {
            padding-bottom: 50px;
        }

        h3 {
            margin-top: 51px;
        }

        .image-wrapper {
            margin-top: 50px;
        }

        .mt20-50 {
            margin-top: 50px;
        }
    }
}
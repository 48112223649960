.upgrades {
    background: $color-grey-light;
    padding-bottom: 25px;

    h3 {
        margin: 32px 0 25px 0;
    }

    @include breakpoint($sm) {
        margin: 64px 0 25px 0;
    }


    .column-text {
        @include breakpoint($sm) {
            padding: 0;
            margin-bottom: 32px;
            column-count: 2;
            column-gap: 30px;
            vertical-align: top;
            transform: translate3d(0, 0, 0);

            p {
                vertical-align: top;
                margin-bottom: 25px;
            }
        }
    }

    .selects-wrapper {
        @include breakpoint($md) {
            max-width: 86%;
            margin: 0 auto;
        }


        @include breakpoint($lg) {
            max-width: 68%;
            margin: 0 auto;
        }
    }

    .select-wrapper {
        display: none;
    }

    .select {
        margin-top: 32px;
        margin-bottom: 32px;

        @include breakpoint($sm) {
            margin-top: 0;
            margin-bottom: 64px;
        }
    }

    .upgrade-wrapper {
        display: none;
        margin-bottom: 32px;

        @include breakpoint($sm) {
            margin-bottom: 64px;
        }
    }
}
.contact-bricks {
    padding: 30px 10px 20px;

    h4 {
        margin-bottom: 20px;
        text-align: center;
    }

    a {

        &.link {

            @include breakpoint($sm) {

                &.link-red {

                    &:not(.link-arrow-light) {
                        font-size: 17px;
                    }
                }
            }

            &.link-arrow-light {
                padding-top: 0;
                padding-bottom: 0;

                &:after {
                    top: 7px;
                }
            }
        }
    }

    &.contact-list {
        background: #f1f1f1;

        .item {
            padding: 20px 10px;
            border-bottom: solid 1px #ccc;

            .distance {
                margin-bottom: 10px;
            }

            &:last-child {
                border-bottom: none;
            }

            h5 {
                font-size: 17px;
                line-height: 30px;
                font-style: italic;
                margin-bottom: 10px;
            }
        }
    }

    &.contact-spareparts {

        .item {
            padding: 20px 0;
        }
    }

    &.contact-locations {
        background: #f1f1f1;

        .item {
            padding: 20px 0;

            .distance {
                margin-bottom: 10px;
            }
        }
    }

    &.representatives {

        .item {
            padding: 20px 0;

            .distance {
                margin-bottom: 10px;
            }
        }
    }

    &.contact-headquarter {

        .item {
            padding: 10px 0;

            > .address {
                padding: 16px 10px;
            }
        }

        .map {
            padding: 20px 10px;
        }

        #map {

            ~ a {
                margin-top: 10px;
            }
        }

    }

    @include breakpoint($sm) {

        h4 {
            text-align: left;
            line-height: 30px;
        }

        &.contact-spareparts {

            .item {
                padding: 0 0 20px;
            }
        }

        &.contact-locations {

            .item {
                padding: 0 0 20px;
            }
        }

        &.representatives {

            .item {
                padding: 0 0 20px;
            }
        }
    }

}



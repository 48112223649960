.visual {
    .skew-cut {
        position: relative;
        display: block;
        height: 70px;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            width: 200%;
            height: 100px;
            position: absolute;
            bottom: 50%;
            left: 50%;
            transform: translateX(-50%) rotateZ(-2deg);
            background: #FFF;
        }

        &.skew-cut-bottom {
            &:before {
                transform: translateX(-50%) rotateZ(2deg);
                bottom: auto;
                top: 50%;
            }
        }
    }

    p {
        color: $color-grey-text;
        line-height: 28px;
        font-weight: 400;

        + p {
            margin-top: 18px;
        }
    }
}

.visual-right {
    margin: 0;
    background: #F8F8FC;

    @include breakpoint($lg) {
        margin: 170px 0 0 0;
    }


    figure {
        margin: 24px 0 0 0;
        position: relative;

        @include breakpoint($sm) {
            margin: 44px 0 0 0;
        }


        @include breakpoint($md) {
            margin: 0;
        }


        @include breakpoint($lg) {
            margin: -170px 0 0 0;
        }
    }

    .left-description {
        @include breakpoint($md) {
            padding-bottom: 64px;
        }


        h4 {
            margin: 32px 0 20px 0;

            @include breakpoint($md) {
                margin: 109px 0 20px 0;
            }
        }
    }

    .center-description {
        padding-top: 24px;
        padding-bottom: 32px;

        h4 {
            margin: 0 0 20px 0;
        }

        @include breakpoint($md) {
            padding-top: 52px;
            padding-bottom: 107px;
        }
    }
}

.visual-left {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    h3 {
        margin: 32px 0 24px 0;
        font-size: 36px;

        @include breakpoint($md) {
            margin: 60px 0 35px 0;
        }
    }

    .center-description {
        padding-bottom: 44px;

        @include breakpoint($md) {
            padding-bottom: 90px;
        }


        p {
            font-size: 20px;
            line-height: 32px;
        }
    }

    .right-description {
        padding-top: 44px;
        padding-bottom: 32px;

        @include breakpoint($md) {
            padding-top: 84px;
            padding-bottom: 54px;
        }


        h4 {
            margin-bottom: 20px;
        }
    }
}

.visual-introduction {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    figure {
        margin-top: 30px;
    }

    .right-description {
        padding-top: 25px;
    }

    h4 {
        margin-bottom: 23px;
        line-height: 1.25;
    }

    .contact-information {
        margin: 15px 0 23px 0;

        p {
            font-weight: 500;
        }
    }

    @include breakpoint($md) {
        .order-md-1 {
            order: 1;
        }
        .order-md-2 {
            order: 2;
        }
    }
}
/* scss-compile-options: --precision 15 */

.con {
    width: 100%;
}

.row {
    display: flex;
    flex-flow: row wrap;
}

[class^="col-"] {
    flex: 1;
    max-width: 100%;
}

@mixin grid($breakPointName, $colCount, $gridWidth, $colPadding) {
    .con {
        margin: 0 auto;
        max-width: $gridWidth;
    }

    .row {
        margin: 0 (-1 * $colPadding / 2);
    }

    [class^="col-"] {
        padding: 0 ($colPadding / 2);
    }

    .col-#{$breakPointName}-offset-0 {
        margin-left: 0;
    }

    @for $i from 1 through $colCount {
        .col-#{$breakPointName}-#{$i} {
            -ms-flex: auto;
            -moz-flex: auto;
            -webkit-flex: auto;
            flex: auto;
            min-width: (100 / $colCount * $i * 1%);
            max-width: (100 / $colCount * $i * 1%);
        }

        .col-#{$breakPointName}-offset-#{$i} {
            margin-left: 100 / $colCount * $i * 1%;
        }
    }
}

@include grid(xs, 12, 100%, 20px);

.con {
    padding: 0 10px;
}

@media screen and (min-width: 768px) {
    @include grid(sm, 12, 720px, 20px);

    .con {
        padding: 0;
    }
}

@media screen and (min-width: 1024px) {
    @include grid(md, 12, 1000px, 30px);
}

@media screen and (min-width: 1440px) {
    @include grid(lg, 12, 1410px, 30px);

    .row-lg-small {
        padding-left: 8.5%;
        padding-right: 8.5%;
    }
}

$sm: 768px;
$md: 1024px;
$lg: 1440px;

@mixin breakpoint($breakpoint) {
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin max-breakpoint($breakpoint) {
    @media screen and (max-width: -1 + $breakpoint) {
        @content;
    }
}

#mobile-wrapper {
    display: block;

    @include breakpoint($md) {
        display: none;
    }
}

#desktop-wrapper {
    display: none;

    @include breakpoint($md) {
        display: block;
    }
}

.mobile-padding {
    @include max-breakpoint($sm) {
        padding-left: 10px;
        padding-right: 10px;

        .overlay-light {
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}
.contact-teaser {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }

    .wrapper {
        padding: 24px 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @include breakpoint($sm) {
            padding: 64px 0;
        }
    }

    h3,
    .h3 {
        max-width: 90%;
        margin: 0 auto 24px auto;

        @include breakpoint($sm) {
            margin: 0 auto 32px auto;
        }
    }

    p {
        max-width: 90%;
        margin: 0 auto 24px auto;

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint($sm) {
            max-width: 50%;
        }
    }
}
.press-form {
    margin: 48px 0 0 0;

    @include breakpoint($sm) {
        margin: 90px 0 0 0;
    }


    p {
        margin: 10px 0 38px 0;
        line-height: 1;
    }
}
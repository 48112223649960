.keyvisual {
    background: $color-grey;
    border-top: $color-lila 4px solid;
    border-bottom: $color-lila 6px solid;
    min-height: calc(100vw * .49);

    @include breakpoint(1920px) {
        min-height: 950px;
    }

    .flickity-viewport {
        transition: height 300ms ease;
    }

    .flickity-page-dots {
        top: 100%;
        height: 45px;
        margin: 9px 0 0 0;
        counter-reset: dot-count;
        display: none;

        @include breakpoint($md) {
            display: block;
        }
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 50px;
        line-height: 48px;
        opacity: 1;
        background: transparent;
        border: 0;
        border-bottom: $color-red 2px solid;
        border-radius: 0;
        color: #FFF;
        margin: 0 12px;
        transition: all 300ms ease;

        &:after {
            counter-increment: dot-count;
            content: "0" counter(dot-count);
            pointer-events: none;
        }

        &.is-selected {
            color: rgba(#FFF, 0.3);
            border-color: rgba(#FFF, 0.3);
        }
    }

    .cell {
        width: 100%;

        .cell-inner {
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include breakpoint($md) {
                height: 0;

                .con {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            @include breakpoint($md) {
                padding: 49% 0 0 0;
            }
        }
    }

    .box {
        margin: 196px 0 30px 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }

        @include breakpoint($md) {
            margin: 0 0 30px 0;

            h1 {
                margin-bottom: 26px;
            }
        }


        h1 {
            color: $color-red;
            margin-bottom: 20px;
            font-weight: 400;
        }

        p {
            margin-bottom: 18px;
        }
    }
}

.animated-hero {

    background: $color-grey;
    border-top: $color-lila 4px solid;
    border-bottom: $color-lila 6px solid;
    min-height: calc(100vw * .49);

    @include breakpoint(1920px) {
        min-height: 950px;
    }

    .cell {
        width: 100%;
        position: relative;

        .cell-inner {
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include breakpoint($md) {
                height: 0;

                .con {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            @include breakpoint($md) {
                padding: 49% 0 0 0;
            }
        }
    }

    .box {
        margin: 196px 0 30px 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }

        @include breakpoint($md) {
            margin: 0 0 30px 0;

            h1 {
                margin-bottom: 26px;
            }
        }


        h1 {
            color: $color-black;
            margin-bottom: 20px;
            font-weight: 400;
        }

        p {
            margin-bottom: 18px;
        }
    }

    .wrap-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        overflow: hidden;
    }

    video {
        display: inline-block;
        vertical-align: baseline;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

}

@include max-breakpoint(1023.98px) {
    .keyvisual {
        .flickity-viewport {
            min-height: calc(100vw * .49);
        }
        .cell {
            .cell-inner {
                min-height: calc(100vw * .49);
            }
        }
    }
}
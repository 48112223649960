.booklet {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }


    .booklet-wrapper {
        width: 100%;
        max-width: 330px;
        margin: 0 auto;

        figure {
            margin-bottom: 2px;
        }
    }
}
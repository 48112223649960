#footer {
    padding: 180px 0 0 0;
    background: url(../../img/bg/footer-bg-new.svg) no-repeat top right;
    background-size: 100%;
    -webkit-box-shadow: 0 0 10px 0 rgba(153,153,153,0.8);
    box-shadow: 0 0 10px 0 rgba(153,153,153,0.8);

  @include breakpoint($sm) {
        padding: 150px 0;
        background-size: 35%;
      &.with-footer-logos {
        padding: 150px 0 0 0;
      }
    }


    .logo-wrapper {
        display: block;
        margin: 0 auto 68px auto;
        text-align: center;
        max-width: 170px;

        @include breakpoint($sm) {
            max-width: 220px;
            margin: 0 auto 32px auto;
        }


        @include breakpoint($md) {
            max-width: none;
            margin: 7px 0 0 0;
        }


        small {
            text-transform: none;
            font-size: 12px;

            @include breakpoint($sm) {
                font-size: 15px;
            }
        }
    }

    .nav-wrapper {
        text-align: center;

        @include breakpoint($sm) {
            background: transparent;
            text-align: left;
        }


        &.nav-wapper-first {
            padding-top: 32px;

            @include breakpoint($sm) {
                padding-top: 0;
            }
        }
    }

    .nav-footer-top {
        ul {
            list-style: none;

            li {
                padding: 0 0 0 30px;

                @include breakpoint($md) {
                    padding: 0;
                }


                a {
                    display: inline-block;
                    position: relative;
                    font-size: 16px;
                    text-transform: uppercase;
                    line-height: 20px;
                    padding: 10px 0;
                    font-weight: 400;
                    color: $color-grey;
                    transition: all 300ms ease;

                    &:hover {
                        color: $color-blue;

                        &:after {
                            transform: translate3d(8px, 0, 0);
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 14px;
                        left: -20px;
                        display: block;
                        width: 11px;
                        height: 11px;
                        background: url(../../img/icons/arrow-gt-blue.svg) no-repeat top center;
                        background-size: 11px 11px;
                        transition: all 300ms ease;
                    }

                    @include breakpoint($sm) {
                        display: block;
                    }
                }
            }
        }
    }
  &.with-footer-logos {
    .nav-footer-center  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin: 2rem auto;
      .footer-logo-wrapper {
        max-width: 150px;
        padding: 0 -20px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
        @include breakpoint($sm) {
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 8.3333333333%;
          margin-right: inherit;
        }
      }
  }

    .footer-breadcrumb-wrapper {
        margin-top: 32px;
        padding-top: 32px;
        padding-bottom: 32px;
        text-transform: uppercase;

        @include breakpoint($sm) {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .footer-breadcrumb {
        ul {
            list-style: none;

            li {
                float: left;
                // padding-left: 5px;
                color: $color-grey;

                @include breakpoint($sm) {
                    // padding-left: 18px;

                    &:first-child {
                        a {
                            padding-left: 0;
                        }
                    }
                }


                a {
                    display: inline-block;
                    color: $color-grey;
                    font-size: 16px;
                    transition: all 300ms ease;
                    font-weight: 500;

                    &:hover {
                        color: $color-blue;
                    }

                    @include breakpoint($sm) {
                        //padding-left: 11px;
                    }
                }
            }
        }
    }

  &.editmode {
    .footer-logo-wrapper {
      img {
        min-width: 150px;
        height: auto;
      }
    }
  }

}

.footer-bottom {
  margin: 0;
  background: #FFF;

  @include breakpoint($sm) {
    margin: 18px 0 0 0;
  }

  .nav-footer-bottom {
    ul {
      list-style: none;

      li {
        float: left;
        font-size: 14px;
        line-height: 40px;
        font-weight: 400;
        color: $color-grey;
        padding: 0;
        margin-right: 15px;
        letter-spacing: .05em;

        &:first-child {
          float: none;
        }

        @include breakpoint($sm) {
          padding: 8px 0;
          font-size: 16px;

          &:first-child {
            float: left;
          }
        }


        @include breakpoint($md) {
          margin-right: 35px;
        }


        a {
          color: inherit;
          transition: all 300ms ease;
          letter-spacing: 0;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }
  }

  &.lp-footer {
    -webkit-box-shadow: 0 0 10px 0 rgba(153,153,153,0.8);
    box-shadow: 0 0 10px 0 rgba(153,153,153,0.8);

    @include breakpoint($sm) {
      padding: 10px 0;
    }
  }
}

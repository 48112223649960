.contact-form {
    margin: 0 0 32px 0;

    h3 {
        font-size: 24px;
        margin: 12px 0 24px 0;

        @include breakpoint($sm) {
            font-size: 36px;
            margin: 40px 0 44px 0;
        }
    }
}

.form {
    .input-wrapper {
        position: relative;
        margin: 0 0 40px 0;

        @include breakpoint($md) {
            padding-right: 50px;

            &.wide {
                padding-right: 0;
            }
        }


        &.label-above {
            label {
                font-size: 14px;
                line-height: 15px;
                color: $color-grey;
                top: -15px;
            }
        }

        label {
            color: #a7a7a7;
            position: absolute;
            top: 0;
            left: 20px;
            font-size: 16px;
            line-height: 40px;
            pointer-events: none;
            transition: all 300ms ease;

            @include breakpoint($sm) {
                left: 30px;
                line-height: 62px;
            }
        }

        input[type=text], input[type=email], input[type=password], textarea {
            -webkit-appearance: none;
            background: #F8F8F8;
            border: 1px solid $color-blue;
            outline: none;
            border-radius: 20px;
            height: 40px;
            padding: 0 20px;
            width: 100%;
            font-size: 16px;
            color: $color-grey;

            @include breakpoint($sm) {
                height: 62px;
                border-radius: 31px;
                padding: 0 30px;
            }
        }

        textarea {
            height: 165px;
            padding-top: 8px;
            line-height: 24px;

            @include breakpoint($sm) {
                height: 165px;
                padding-top: 19px;
            }
        }
    }

    .checkbox-wrapper {
        position: relative;
        max-width: 540px;

        input[type=checkbox] {
            opacity: .5;
            position: absolute;
            top: 0;
            left: 0;

            &:checked {
                + label {
                    &:after {
                        background: #F8F8F8 url(../../img/icons/check-grey.svg) no-repeat center center;
                        background-size: 11px 11px;
                    }
                }
            }
        }

        label {
            display: block;
            position: relative;
            padding: 3px 0 0 40px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 300;
            color: $color-grey-text;

            a {
                color: $color-red;

                &:hover {
                    text-decoration: none;
                }
            }

            &:after {
                content: "";
                display: block;
                width: 22px;
                height: 22px;
                border: 1px solid $color-blue;
                border-radius: 4px;
                background: #F8F8F8;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    .btn-wrapper {
        padding: 10px 0 0 0;
        text-align: center;

        @include breakpoint($sm) {
            padding: 15px 50px 0 0;
            text-align: right;
        }
        @include breakpoint($md) {
            padding: 36px 50px 0 0;
            text-align: right;
        }
    }

    
    .recaptcha-wrapper{
        text-align: center;
       
        .recaptcha-block{
            display: inline-block;
        }

        @include breakpoint($sm) {
            padding: 15px 50px 0 0;
            text-align: right;
        }
        @include breakpoint($md) {
            text-align: left;
        }
    }
}

#contact-form-extern,
#press-form-extern {
    min-height: 120px;

    h3 {
        margin-bottom: 6px;

        @include breakpoint($sm) {
            margin-bottom: 24px;
        }
    }

    #easyXDM-wrapper {
        margin: 0 -10px;

        @include breakpoint($md) {
            margin-right: 0;
            width: calc(100% - 30px);
        }

        > iframe {
            width: 100%;
        }
    }
}

@include breakpoint($sm) {
    .registration-form {
        &.btn-left {
            .form {
                .btn-wrapper {
                    text-align: left;
                }
            }
        }
    }
}
.boards_accordion {
    margin-bottom: 40px;

    .image-wrapper {
        margin-bottom: 16px;

        img {
            margin: 0 auto;
        }
    }

    .text-wrapper {
        text-align: center;

        h2 {
            margin-bottom: 4px;

            + p {
                line-height: 1.4;
            }
        }
    }

    @include breakpoint($sm) {

        .image-wrapper {
            flex: 0 0 auto;
            margin-right: 5px;
            margin-bottom: 0;
            min-width: 0;
            width: auto;

            img {
                margin: 0;
                height: auto;
            }
        }

        .text-wrapper {
            text-align: left;
            min-width: 0;
            width: auto;
            flex: 1 1 0;
        }

        .img-right,
        .img-left {
            flex-wrap: nowrap;
            align-items: center;
        }

        .img-right {

            .image-wrapper {
                order: 2;
                margin-right: 0;
                margin-left: 5px;
            }

            .text-wrapper {
                order: 1;
                text-align: right;
            }
        }
    }

    .accordion {
        margin-top: 8px;

        .accordion-link {

            p {
                font-size: 16px;
            }
        }
    }
}
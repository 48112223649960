/* HEADLINES */

h1, h2, h3, h4, h5 {
    padding: 0;
    font-weight: 500;
    position: relative;
    font-family: Dax, serif;
    color: $color-grey;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        color: inherit;
    }
}

h1, .h1 {
    font-size: 24px;
    line-height: 1.29;
    letter-spacing: 0;

    @include breakpoint($md) {
        font-size: 78px;

        /**/
        line-height: 1;
        letter-spacing: .015em;
    }
}

h2, .h2 {
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: .0em;

    @include breakpoint($sm) {
        font-size: 43px;
        line-height: 1;
        letter-spacing: -.035em;
    }
}

h3, .h3 {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 0.01em;
    font-weight: 500;

    @include breakpoint($sm) {
        font-size: 30px;
        line-height: 1;
    }
}

h4, .h4 {
    font-size: 20px;
    line-height: 1;
}

p, .p {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: $color-grey;
    font-family: Dax, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include breakpoint($sm) {
        font-size: 17px;
        line-height: 30px;
    }

    a {
        color: $color-lila;
    }
}

table {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    color: #000;
    -webkit-font-smoothing: subpixel-antialiased;
}

code {
    font-size: 14px;
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
}

ul, ol {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    color: #12171A;
    -webkit-font-smoothing: subpixel-antialiased;
}

button, input, textarea {
    font-family: Dax, sans-serif;
}

small, .small {
    display: block;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 500;
    color: $color-grey;
    text-transform: uppercase;
    letter-spacing: .05em;

    &.underscore {
        position: relative;
        padding-left: 15px;

        &.underscore-shadow {
            &:after {
                box-shadow: 0 1px 2px rgba(#000, 0.5);
            }
        }

        &:after {
            content: "";
            display: block;
            width: 8px;
            height: 2px;
            background: #033770;
            position: absolute;
            left: 0;
            bottom: 6px;
        }

        &.underscore-white {
            &:after {
                background: #FFF;
            }
        }

        &.underscore-red {
            &:after {
                background: $color-red;
            }
        }

        &.underscore-blue {
            &:after {
                background: #00489A;
            }
        }

        &.underscore-green {
            &:after {
                background: #48A019;
            }
        }

        &.underscore-purple {
            &:after {
                background: #6B1256;
            }
        }

        &.underscore-turquoise {
            &:after {
                background: $color-blue;
            }
        }
    }

    &.underscore_placeholder {
        height: 21px;
    }
}

.red {
    color: $color-red;
}

.ls-normal {
    letter-spacing: normal;
}
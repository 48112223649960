#header {
    position: fixed;
    top: 0;
    left: 58px;
    width: calc(100% - 58px);
    height: 56px;
    background: rgba(#FFF, 0.7);
    z-index: 5009;
    transition: height 300ms ease;

    @include breakpoint($sm) {
        left: 107px;
        width: calc(100% - 107px);
    }


    @include breakpoint(1920px) {
        width: 1920px - 107px;
        left: 50%;
        transform: translateX(-1*(1920px/2) + (107px));
    }


    &.small {
        height: 42px;

        #toggle-nav {
            height: 42px;
        }

        #logo-wrapper {
            height: 42px;

            a {
                padding: 4px 0;
            }
        }

        #context-nav {
            > ul {
                > li {
                    width: 42px;
                    height: 42px;

                    > a {
                        transform: scale(0.75);
                        transform-origin: 0 0;
                    }

                    &.context-some {
                        width: 30px;

                        &.facebook {
                            margin-left: 30px;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }

    &.header-lp {
        width: 100%;
        left: 0;
        transform: none;

        #logo-wrapper {
            margin-right: 0;
        }
    }
}

#header-spacer {
    height: 56px;
}

#toggle-nav {
    overflow: hidden;
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0 0 0 -58px;
    border: 0;
    background: rgba(#FFF, 0.7);
    float: left;
    outline: none;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
        background: rgba(#FFF, 0.9);
    }

    @include breakpoint($sm) {
        width: 105px;
        margin: 0 0 0 -107px;
    }


    &.nav-open {
        >div {
            width: 0;

            .top {
                animation: toggle-nav-top-to-x 600ms ease forwards;
            }

            .bottom {
                animation: toggle-nav-bottom-to-x 600ms ease forwards;
            }
        }
    }

    span {
        margin-left: -9999px;
    }

    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 2px;
        background: $color-black;
        transform: translate3d(-50%, -50%, 0);
        transition: all 600ms ease;

        >div {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 2px;
            background: $color-black;
            transform: translate3d(-50%, -10px, 0);
            margin: -1px 0 0 0;
            transition: all 600ms ease;
        }

        .top {
            animation: toggle-nav-top-to-burger 600ms ease forwards;
        }

        .bottom {
            animation: toggle-nav-bottom-to-burger 600ms ease forwards;
            transform: translate3d(-50%, 10px, 0);
        }
    }
}

@keyframes toggle-nav-top-to-x {
    0% {
        transform: translate3d(-50%, -10px, 0);
    }

    50% {
        transform: translate3d(-50%, -5px, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0) rotateZ(45deg);
    }
}

@keyframes toggle-nav-top-to-burger {
    0% {
        transform: translate3d(-50%, 0px, 0) rotateZ(45deg);
    }

    50% {
        transform: translate3d(-50%, -5px, 0);
    }

    100% {
        transform: translate3d(-50%, -10px, 0);
    }
}

@keyframes toggle-nav-bottom-to-x {
    0% {
        transform: translate3d(-50%, 10px, 0);
    }

    50% {
        transform: translate3d(-50%, 5px, 0);
    }

    100% {
        transform: translate3d(-50%, 0px, 0) rotateZ(-45deg);
    }
}

@keyframes toggle-nav-bottom-to-burger {
    0% {
        transform: translate3d(-50%, 0, 0) rotateZ(-45deg);
    }

    50% {
        transform: translate3d(-50%, 5px, 0);
    }

    100% {
        transform: translate3d(-50%, 10px, 0);
    }
}

#context-nav {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;

    > ul {
        list-style: none;
        padding: 0;

        @include breakpoint($md) {
            margin: 0 30px 0 0;
        }


        > li {
            position: relative;
            float: left;
            width: 56px;
            height: 56px;
            margin-left: 15px;
            transition: all 300ms ease;

            &.context-search {}

            &.context-some {
                display: none;

                @include breakpoint($md) {
                    display: block;
                    width: 30px;
                    margin-left: 10px;

                    &.facebook {
                        margin-left: 20px;
                        width: 24px;
                    }
                }

                > a {
                    background-image: none;
                    position: relative;
                    width: 100%;

                    &:before{
                        content: '';
                        position: absolute;
                        top: 13px;
                        left: 0;
                        height: 30px;
                        width: 30px;
                        background: url(../../img/icons/facebook_t.svg) 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                &.instagram {
                    > a {
                        &:before {
                            top: 13px;
                            background-image: url(../../img/icons/instagram_t.svg);
                        }
                    }
                }

                &.linkedin {
                    > a {
                        &:before {
                            top: 12px;
                            background-image: url(../../img/icons/linkedin_t.svg);
                        }
                    }
                }

                &.xing {
                    > a {
                        &:before {
                            background-image: url(../../img/icons/xing_t.svg);
                        }
                    }
                }

                &.mail {
                    > a {
                        &:before {
                            top: 16px;
                            background-image: url(../../img/icons/mail_b.svg);
                        }
                    }
                }
            }

            &.context-language {
                display: none;

                @include breakpoint($md) {
                    display: block;
                }


                > a {
                    background-image: none;
                    position: relative;
                    color: $color-black;
                    margin-left: -7px;
                    //background-image: url(../../img/icons/context-globe.svg);
                    span{
                        visibility: visible;
                        position: absolute;
                        top: 24%;
                        left: 40px;
                        &:after{
                            content: '\203A';
                            position: absolute;
                            top: 7%;
                            left: 7%;
                            transform: rotate(90deg);
                            font-size: 1.8rem;
                        }
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        top: 26%;
                        left: 10px;
                        height: 45%;
                        width: 45%;
                        background-image: url(../../img/icons/context-globe-black.svg);
                        background-position: center center;
                        background-repeat: no-repeat;
                    }

                }
            }

            &.context-extranet {
                display: none;

                @include breakpoint($md) {
                    display: block;
                }


                > a {
                    background-image: url(../../img/icons/context-account.svg);
                }
            }

            &.context-contact {
                display: none;

                @include breakpoint($md) {
                    display: block;
                }


                > a {
                    background-image: url(../../img/icons/mercator-black.svg);
                    background-size: 38px 38px;
                }
            }

            > a {
                display: block;
                height: 56px;
                width: 56px;
                background: url(../../img/icons/context-magnifier-black.svg) no-repeat center center;
                background-size: 25px 25px;
                transition: all 300ms ease;

                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    visibility: hidden;
                }
            }

            &.context-career {
                display: none;

                @include breakpoint($md) {
                    display: block;
                }


                > a {
                    background-image: url(../../img/icons/career.svg);
                    background-size: 24px 24px;
                }
            }
        }
    }
}

#nav-language {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(#FFF, 0.9);
    z-index: 2000;
    display: none;

    &.visible {
        display: block;

        + .nav-language-shadow {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1900;
        }
    }

    ul {
        list-style: none;
        padding: 20px 30px;

        li {
            a {
                font-size: 16px;
                line-height: 30px;
                font-weight: 400;
                color: $color-grey;
                transition: all 300ms ease;

                &:hover {
                    color: $color-lila;
                }
            }
        }
    }
}

#logo-wrapper {
    text-align: center;
    height: 56px;
    margin-right: 58px;
    transition: all 300ms ease;

    @include breakpoint($sm) {
        margin-right: 107px;
    }


    a {
        display: block;
        margin: 0 auto;
        width: 120px;
        padding: 11px 0;
        transition: all 300ms ease;
    }

    img {
        width: 120px;
        height: 34px;
        margin: 0 auto;
    }
}
// flyout contact

#sticky-contact {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 499;

  > ul {
    width: 60px;
    position: relative;
    padding: 0;
    list-style-type: none;
    margin: 0;

    > li {
      background-color: rgba(0, 0, 0, .72);
      height: 60px;
      margin-bottom: 8px;

      &:hover {
        background-color: rgba(0, 145, 175, .72);
      }

      &.active {
        background-color: rgba(0, 145, 175, .72);
      }

      > a {
        display: block;
        height: 60px;

        span {
          position: absolute !important;
          width: 1px;
          height: 1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0,0,0,0);
          white-space: nowrap;
          border: 0;
        }

        &:after {
          content: '';
          display: block;
          height: 100%;
          position: relative;
          background: transparent url('../../img/icons/fly-partner.png') center center no-repeat;
        }
      }

      &.location {

        a {

          &:after {
            background-image: url('../../img/icons/mercator-white.svg');
            background-size: 40px 40px;
          }
        }
      }

      &.career {

        a {

          &:after {
            background-image: url('../../img/icons/career-white.svg');
            background-size: 36px 36px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}



.fly-container {
  display: none;
  position: fixed;
  top: 56px;
  right: 60px;
  width: calc(100% - 60px);
  height: calc(100% - 56px);
  background: #fff;
  border: solid 1px #808080;
  z-index: 499;

  @media screen and (min-width: 1980px) {
    max-width: 1920px;
    width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  &.open {
    display: block;
  }

  .frame {
    padding: 60px;
    position: relative;
    max-height: 100%;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;

    > .outer {
      max-width: 90rem;//75rem;
      margin: 0 auto;
    }

    .fly-inner {
      //overflow: auto;

      &.light-grey {
        background: #f1f1f1;
      }

      .contact-item {
        padding: 2vw 0;

        @include breakpoint($lg) {
          padding: 32px 0;
        }

        &.row {
          padding: 22px 0 10px;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        &.link {

          &.link-arrow-light {
            padding-top: 0;
            padding-bottom: 0;

            &:after {
              top: 7px;
            }
          }
        }

        @include breakpoint($sm) {

          &.link-red {

            &:not(.link-arrow-light) {
              font-size: 17px;
            }

          }
        }

      }

      h2 {
        font-size: 36px;
        line-height: 1;
        letter-spacing: -.035em;
        margin-bottom: 15px;
      }

      h3 {
        margin-bottom: 4px;
        font-style: italic;
      }

      p {
        line-height: 1.5;
        margin-bottom: 12px;
      }

      .sub-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  /*.close {
    cursor: pointer;
    position: absolute;
    width: 33px;
    height: 33px;
    top: 60px;
    right: 60px;
    background: url('../../img/icons/close.png') 0 0 no-repeat;

    &:hover {
      background-position: 0 -33px;
    }
  }*/

  .close {
    cursor: pointer;
    position: absolute;
    width: 33px;
    height: 33px;
    top: 60px;
    right: 60px;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      background: $color-blue;
      transform: translate3d(-50%, -50%, 0);
      width: 0;

      > div {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 2px;
        background: $color-blue;
        transform: translate3d(-50%, -10px, 0);
        margin: -1px 0 0 0;
      }

      .top {
        transform: translate3d(-50%, 0px, 0) rotateZ(-45deg);
      }

      .bottom {
        transform: translate3d(-50%, 0px, 0) rotateZ(45deg);
      }
    }

    &:hover {

      > div {
        background: #505050;

        > div {
          background: #505050;
        }
      }
    }
  }

}

#header.small ~ .fly-container {
  top: 42px;
  height: calc(100% - 42px);
}

@include breakpoint($lg) {

  /*.contact-item {
    display: flex;
    flex-wrap: wrap;
  }*/

  .col-lg-fifth-part {
    min-width: 20%;
    max-width: 20%;
  }

  .col-lg-two-fifth {
    min-width: 40%;
    max-width: 40%;
  }

  .col-lg-three-fifth {
    min-width: 60%;
    max-width: 60%;
  }

  .col-lg-four-fifth {
    min-width: 80%;
    max-width: 80%;
  }
}

@include max-breakpoint($sm) {

  body {
    padding-bottom: 60px;
  }

  #sticky-contact {
    transform: translateY(0);
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      width: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      > li {
        margin-bottom: 0;
        width: 33.333%;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 5px;
          right: -1px;
          width: 2px;
          height: 50px;
          background: #d40009;
        }

        &:last-child {

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .fly-container {
    right: 0;
    width: 100%;
    border: none;

    .frame {
      padding: 30px 20px 80px;

      .fly-inner {
        text-align: center;

        .contact-item {
          padding: 15px 0;

          &.light-grey {
            background: #f1f1f1;
          }

          &:last-child {
            padding-bottom: 15px;
          }
        }

        h2 {
          font-size: 18px;
          padding-right: 40px;
        }

        h3 {
          font-size: 16px;
        }

        p {
          margin-bottom: 0;
        }

        .sub-item {
          //margin-bottom: 2vw;
        }

        .txt-left {
          text-align: center;
        }
      }
    }

    .close {
      top: 20px;
      right: 20px;
    }
  }
}

#toTop {
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 70px;
  right: 24px;
  border-radius: 100%;
  background-color: $color-blue;
  border: 0;
  line-height: 1;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  display: none;

  @include breakpoint($md) {
    bottom: 50px;
    right: 50px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 26px;
    height: 26px;
    left: 17px;
    top: 23px;
    transform: rotate(-135deg);
    border: solid $color-white;
    border-width: 0 4px 4px 0;
  }
}
.spare-parts {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }

    h3 {
        margin: 0 0 24px 0;
    }

    h4 {
        margin: 48px 0 0 0;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
.teasers-press {
    .filter-wrapper {
        width: 210px;
        margin: 0 14px 40px 14px;
    }

    .teasers-wrapper {
        background: $color-grey-light;
    }

    .link-wrapper {
        text-align: right;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

.teasers-press-left {

    .teasers-wrapper {

        @include max-breakpoint($sm) {
            margin-left:-10px;
            margin-right:-10px;
            padding-left:10px;
            padding-right:10px;
        }
    }

    .filter-wrapper {
        margin: 44px 14px 14px 14px;
    }

    .box-press {
        margin-left: 10px;
        margin-right: 10px;

        @include breakpoint($sm) {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
}
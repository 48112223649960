.btn {
    -webkit-appearance: none;
    background: $color-blue;
    display: inline-block;
    line-height: 45px;
    padding: 0 25px;
    height: 45px;
    border-radius: 28px;
    border: 0;
    box-shadow: none;
    color: $color-white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    cursor: pointer;

    @include breakpoint($sm) {
        line-height: 55px;
        padding: 0 25px;
        height: 55px;
        font-size: 22px;
    }


    &.btn-arrow {
        padding: 0 20px 0 50px;
        position: relative;

        &:hover {
            span {
                &:after {
                    transition: transform 300ms linear;
                    transform: translate3d(250%, -50%, 0);
                }

                &:before {
                    transition: transform 300ms linear;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        @include breakpoint($sm) {
            padding: 0 40px 0 75px;
        }


        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 5px;
            width: 36px;
            height: 36px;
            border: rgba($color-white, 0.5) 2px solid;
            transform: translateY(-50%);
            border-radius: 50%;
            overflow: hidden;

            &:before, &:after {
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: url(../../img/icons/arrow-gt-white.svg) no-repeat top center;
                background-size: 11px 11px;
                transition: none;
            }

            &:before {
                transform: translate3d(-350%, -50%, 0);
            }

            @include breakpoint($sm) {
                width: 45px;
                height: 45px;
            }
        }
    }

    &.btn-red {
        padding: 0 25px 0 60px;
        background: $color-blue;
        color: $color-white;

        span {
            border: $color-white 2px solid;

            &:before, &:after {
                background-image: url(../../img/icons/arrow-gt-white.svg);
            }
        }
    }

    &.btn-transparent {
        outline: none;
        background: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
    }

    &.btn-no-arrow {
        padding: 0 45px;
        transition: all 300ms ease;

        &.btn-red {
            &:hover {
                background: darken($color-blue, 10%);
            }
        }
    }
}

.link {
    font-weight: 500;

    &.link-arrow {
        position: relative;
        display: inline-block;
        color: $color-blue;

        /**/
        font-size: 16px;
        text-transform: uppercase;
        line-height: 36px;
        padding: 0 0 0 47px;

        &:hover {
            span {
                &:after {
                    transition: transform 300ms linear;
                    transform: translate3d(250%, -50%, 0);
                }

                &:before {
                    transition: transform 300ms linear;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        @include breakpoint($sm) {
            line-height: 45px;
            padding: 0 0 0 66px;
        }


        &.link-red {
            color: $color-blue;
            span {
                border-color: $color-blue;

                &:before, &:after {
                    background-image: url(../../img/icons/arrow-gt-blue.svg);
                }
            }
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 36px;
            height: 36px;
            border: $color-blue 2px solid;
            border-radius: 50%;
            overflow: hidden;

            &:before, &:after {
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: url(../../img/icons/arrow-gt-blue.svg) no-repeat top center;
                background-size: 11px 11px;
                transition: none;
            }

            &:before {
                transform: translate3d(-350%, -50%, 0);
            }

            @include breakpoint($sm) {
                width: 45px;
                height: 45px;
            }
        }
    }

    &.link-arrow-light {
        position: relative;
        display: inline-block;
        color: $color-grey-light;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 23px;
        padding: 11px 0 11px 38px;

        &:after {
            content: "";
            position: absolute;
            top: 18px;
            left: 0;
            width: 22px;
            height: 9px;
            background: url(../../img/icons/arrow-light-lila.svg) no-repeat center center;
            background-size: 22px 11px;
            transition: all 300ms ease;
        }

        &:hover {
            &:after {
                transform: translate3d(8px, 0, 0);
            }
        }

        &.link-white {
            &:after {
                background-image: url(../../img/icons/arrow-light-white.svg);
            }
        }

        i {
            color: #656565;
            font-style: normal;
        }
    }

    &.link-arrow-gt {
        position: relative;
        padding: 0 0 0 20px;
        line-height: 45px;
        display: inline-block;
        text-transform: uppercase;
        color: $color-grey;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 11px;
            height: 11px;
            background: url(../../img/icons/arrow-gt-grey.svg) no-repeat center center;
            background-size: 11px 11px;
            transform: translateY(-50%);
            transition: all 300ms ease;
        }

        &:hover {
            &:after {
                transform: translate3d(8px, -50%, 0);
            }
        }
    }

    &.link-red {
        color: $color-lila;
    }
}

.sidebar-link-wrapper {
    text-align: right;
}

.flickity-prev-next-button {

    &:disabled {
        display: none;
    }
}
.keyvisual {

    &.low {
        .flickity-page-dots {
            display: none;
        }
    }
}
.teasers-light {
    padding: 3px 0 32px 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .col-xs-12 {
        padding-top: 27px;
    }

    @include breakpoint($sm) {
        padding: 52px 0 91px 0;

        .col-xs-12 {
            padding-top: 30px;
        }
    }

    @include breakpoint($md) {
        padding: 32px 0 91px 0;

        .col-xs-12 {
            padding-top: 50px;
        }
    }

    .box {
        position: relative;
        padding-bottom: 54px;
        height: 100%;

        small {
            margin: 0 0 10px 0;
        }

        > small {
            padding-top: 16px;
            margin-left: 10px;
            margin-bottom: 15px;
            letter-spacing: 0.02em;
        }

        h3,
        .h3 {
            text-transform: uppercase;
            margin-bottom: 5px;

            color: $color-blue;
            letter-spacing: 0;
        }

        .link {
            margin-top: 15px;

            @include breakpoint($md) {
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
            }

            &.link-red {
              color: $color-blue;

              span {
                border-color: $color-blue;

                &:before, &:after {
                  background-image: url(../../img/icons/arrow-gt-blue.svg);
                }
              }
            }
        }

        @include max-breakpoint($md) {
            padding-bottom: 10px;

            .box-inner {
                padding: 14px 10px;

                p {
                    line-height: 30px;
                }
            }
        }
    }

    > .con {

        > .row {
            justify-content: center;
        }
    }
}

.background-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.figure-wrapper {
    position: relative;
    display: block;
    overflow: hidden;

    &:hover {
        &:after {
            opacity: 1;
        }

        figure {
            transform: scale(1.1);
        }
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.25);
        z-index: 100;
        opacity: 0;
        transition: all 300ms ease;
    }

    figure {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 0;
        padding: 53.333333333% 0 0 0;
        transition: all 300ms ease;

        @include breakpoint($sm) {
            padding: 43.25% 0 0 0;
        }


        img {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
}
.content-standard-image {
    margin: 32px 10px 32px 10px;

    &.fullsize {
        margin: 32px 0;
    }

    @include breakpoint($sm) {
        margin: 80px 0 95px 0;

        &.fullsize {
            margin: 58px 0 70px 0;
        }
    }

    figure {
        figcaption {
            margin-top: 10px;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            color: $color-grey-text;
        }
    }
}

.content-text {
    padding: 0 10px;

    @include breakpoint($sm) {
        padding: 0;
    }


    h4,
    .h4 {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: normal;
        color: $color-grey-text;
        margin-top: 44px;
        margin-bottom: 20px;

        @include breakpoint($sm) {
            margin-top: 78px;
        }
    }

    p {
        line-height: 24px;
        color: $color-grey-text;
        margin-bottom: 25px;

        &.text-red {
            color: $color-red;
        }

        &.text-bigger {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 32px;

            @include breakpoint($sm) {
                margin-bottom: 68px;
            }
        }

        @include breakpoint($sm) {
            line-height: 26px;
        }
    }

    ul {
        margin-bottom: 15px;
        padding: 0 0 0 20px;

        li {
            line-height: 24px;
            color: $color-grey-text;
            margin-bottom: 10px;
        }
    }

    .column-text {
        @include breakpoint($sm) {
            padding: 0;
            margin-top: 36px;
            column-count: 2;
            column-gap: 30px;
        }


        h5 {
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
        }

        ul {
            margin-bottom: 25px;

            li {
                color: $color-blue;
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    hr {
        width: 85px;
        height: 4px;
        background: $color-green;
        margin: 44px auto 24px auto;
        border: 0;

        @include breakpoint($sm) {
            margin: 32px auto 24px auto;
        }


        @include breakpoint($md) {
            margin: 80px auto 30px auto;
        }
    }

    blockquote {
        padding: 24px 0 0 20px;

        @include breakpoint($sm) {
            padding: 26px 0 0 30px;
        }


        &.big {
            position: relative;
            padding: 0 0 0 26px;
            margin: 24px 0 24px 0;

            @include breakpoint($sm) {
                padding: 0 0 0 55px;
                margin: 60px 0 50px 0;
            }


            /* Roter strich nicht mit border gemacht, da line-height den Rand zu sehr vertikal aufspannt */

            &:after {
                content: "";
                display: block;
                width: 5pX;
                position: absolute;
                top: 7px;
                bottom: 8px;
                left: 0;
                background: $color-lila;

                @include breakpoint($sm) {
                    top: 15px;
                    bottom: 12px;
                }
            }

            p {
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                font-style: italic;
                color: $color-grey-text;

                @include breakpoint($sm) {
                    font-size: 36px;
                    line-height: 60px;
                }
            }
        }

        p {
            color: $color-lila;
            font-weight: 500;
            font-style: italic;
        }
    }

    .link-arrow-light {
        + p {
            margin-top: 25px;
        }
    }
}

.content-headline {
    text-align: center;
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 32px 0 72px 0;
    }


    h3,
    .h3 {
        font-size: 24px;
        line-height: 1.25;
        color: $color-grey-text;
        margin-bottom: 64px;

        @include breakpoint($sm) {
            font-size: 36px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 85px;
            height: 4px;
            transform: translateX(-50%);
            background: $color-green;
            margin: 30px 0 0 0;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: $color-grey-text;
        font-weight: 500;

        @include breakpoint($sm) {
            font-size: 20px;
            line-height: 32px;
            font-weight: 400;
        }
    }
}

.content-links {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 107px 0 32px 0;
    }

    &:not(.acc-brick) {
        h3,
        .h3 {
            font-size: 36px;
            line-height: 1.25;
            margin: 0 0 30px 0;
        }
    }


    p {
        font-size: 20px;
        line-height: 32px;
    }

    .link-wrapper {
        padding: 20px 0;

        .link {
            margin: 0 0 18px 0;
        }
    }
}

.skew-cut-image {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }


    figure {
        margin: 24px 0;

        @include breakpoint($sm) {
            margin: 32px 0;
        }


        figcaption {
            font-size: 22px;
            margin-top: 24px;
            font-weight: 400;
            text-align: center;
        }
    }

    background: #F8F8FC;
}

.image-slider {
    margin: 32px 0 90px 0;

    @include breakpoint($md) {}


    .cell {
        width: 100%;
    }

    .flickity-viewport {
        transition: height 300ms ease;
    }

    .flickity-page-dots {
        bottom: -55px;
        height: 45px;
        margin: 0;
        counter-reset: dot-count;
        z-index: 500;
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 50px;
        line-height: 48px;
        opacity: 1;
        background: transparent;
        border: 0;
        border-bottom: $color-lila 2px solid;
        border-radius: 0;
        color: $color-grey;
        margin: 0 12px;
        transition: all 300ms ease;

        &:after {
            counter-increment: dot-count;
            content: "0" counter(dot-count);
            pointer-events: none;
        }

        &.is-selected {
            color: rgba($color-grey, 0.3);
            border-color: rgba($color-grey, 0.3);
        }
    }
}

.content-video {
    margin: 38px 0 44px 0;
}

.image-video-gallery {
    padding: 24px 0 30px 0;
    background: $color-grey-light;

    .headline {
        padding-bottom: 32px;
    }

    .text {
        padding-bottom: 20px;
    }

    .carousel {
        margin: 0 80px;
    }

    .carousel-cell {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .col-auto {
        width: auto;
        flex: 1 1 0;
    }

    .col-shrink {
        width: auto;
        flex: 0 0 auto;
    }

    .image {
        display: inline-block;
        position: relative;

        .link-download {
            display: block;
            position: absolute;
            right: 10px;
            bottom: 16px;
            height: 40px;
            width: 40px;
            background: url(../../img/icons/download-blue.svg) no-repeat center center;
            background-size: 75%;
            background-color: rgba(255,255,255,.45);
        }
    }

    figcaption {
        font-size: .75em;
        padding: 6px 0;
    }

    .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: .75em;
        padding: 6px 0;
    }

    .flickity-slider {
        display: flex;
        align-items: center;
    }

    .with-video {
        .flickity-slider {
            padding-bottom: 30px;
        }
        .video-wrapper {
            margin-bottom: 40px;
        }
    }

    .flickity-prev-next-button {
        background-color: $color-blue;
        right: -80px;

        svg {
            display: none;
        }

        &:after {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            left: 11px;
            top: 14px;
            transform: rotate(-45deg);
            border: solid $color-white;
            border-width: 0 4px 4px 0;
        }

        &.previous {
            right: auto;
            left: -80px;

            &:after {
                left: 17px;
                top: 14px;
                transform: rotate(135deg);
            }
        }
    }

    .thumbs {
        margin-top: 16px;

        .videothumb {

            .image {
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-20px, -20px);
                    width: 40px;
                    height: 40px;
                    background: url(../../img/icons/play-video.png) no-repeat center center;
                    background-size: 75%;
                    background-color: rgba(255,255,255,.45);
                }
            }
        }
    }

    .item {
        margin-bottom: 30px;

        .inner {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-white;
            height: 100%;
            border: solid 3px transparent;
            cursor: pointer;
        }

        &.is--active {

            .inner {
                border-color: $color-blue;
            }
        }
    }

    .asset-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include breakpoint($sm) {
        padding: 60px 0;

        .text {
            padding-bottom: 40px;
        }

        figcaption {
            font-size: .875em;
        }

        .caption {
            font-size: .875em;
        }

        .flickity-prev-next-button {
            height: 60px;
            width: 60px;
            right: -80px;

            &:after {
                width: 26px;
                height: 26px;
                left: 11px;
                top: 17px;
            }

            &.previous {
                right: auto;
                left: -80px;

                &:after {
                    left: 23px;
                    top: 17px;
                }
            }
        }
    }
}
.maps {
    margin: 64px 0 0 0;

    .wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding: 150% 0 0 0;

        @include breakpoint($sm) {
            padding: 75% 0 0 0;
        }

        @include breakpoint($md) {
            padding: 50% 0 0 0;
        }

        #map {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        h3 {
            position: absolute;
            bottom: 100%;
            width: 100%;
            left: 0;
            pointer-events: none;
            text-align: center;

            @include breakpoint($sm) {
                bottom: auto;
                top: 24px;
            }
        }

        .map-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../../img/bg/aixtron_maps_placeholder.jpg') no-repeat center center;

            img {
                display: none;
            }

            @include breakpoint($md) {
                background: transparent;

                img {
                    display: block;
                }
            }

            .inner {
                position: absolute;
                text-align: center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 20px;
                background-color: rgba(244, 244, 244, 0.7);

                @include max-breakpoint(480px) {

                    p {
                        font-size: 14px;
                    }

                    .btn {
                        height: 34px;
                        line-height: 34px;
                        font-size: 14px;
                    }
                }

                a {
                  color: $color-blue;
                }
            }

            .activateGoogleMap {
                margin-top: 10px;

                @include breakpoint(480px) {
                    margin-top: 20px;
                }

                @include breakpoint($md) {
                    margin-top: 30px;
                }
            }
        }
    }
}

.map {
    position: relative;

    .map-overlay {
        height: 240px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../../img/bg/aixtron_maps_placeholder_small.jpg') no-repeat center center;
        background-size: cover;

        .inner {
            position: absolute;
            text-align: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            background-color: rgba(244, 244, 244, 0.9);
        }

    }
}
.reference {
    background: #35465c;
    background: linear-gradient(to bottom, #35465c 0%, #0b0b1c 100%);

    @include breakpoint($sm) {
        + .share {
            margin-top: 134px;
        }
    }

    .carousel {
      @include breakpoint($sm) {
        margin-bottom: 50px;
      }
    }

    .cell {
        padding: 25px 0 32px 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;

        @include breakpoint($sm) {
            padding: 60px 0 12px 0;
        }
    }

    .flickity-slider {
        .cell {
            position:absolute; 
        }
    }

    .flickity-page-dots {
        top: 100%;
        height: 45px;
        margin: 7px 0 0 0;
        counter-reset: dot-count;
        display: none;
        z-index: 500;

        @include breakpoint($sm) {
            display: block;
        }
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 50px;
        line-height: 48px;
        opacity: 1;
        background: transparent;
        border: 0;
        border-bottom: $color-lila 2px solid;
        border-radius: 0;
        color: $color-lila;
        margin: 0 12px;
        transition: all 300ms ease;

        &:after {
            counter-increment: dot-count;
            content: "0" counter(dot-count);
            pointer-events: none;
        }

        &.is-selected {
            color: lighten($color-lila, 30%);
            border-color: lighten($color-lila, 30%);
        }
    }

    .figure-wrapper {
        margin: -28px 0 18px 0;

        @include breakpoint($sm) {
            display: none;
        }


        figure {
            padding: 54% 0 0 0;
        }
    }

    .box.box-standard-margin {
        h3, p {
            margin-left: 10px;
            margin-right: 10px;

            @include breakpoint($sm) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    p {
        font-weight: 300;
        color: #a7a6a6;
    }

    .link {
        &.link-arrow {
            margin-top: 12px;
            margin-left: 10px;
            margin-bottom: 20px;

            @include breakpoint($sm) {
                margin-top: 2px;
                margin-bottom: 0;
            }
        }

        &.link-arrow-light {
            padding-left: 57px;

            &:after {
                display: none;
            }

            @include breakpoint($sm) {
                float: right;
                padding-left: 38px;

                &:after {
                    display: block;
                }
            }
        }
    }
}
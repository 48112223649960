.contact-person {
    background: #F7F7F7;
    margin: 32px 0 32px 0;

    @include breakpoint($sm) {
        margin: 58px 0 32px 0;
    }


    p {
        color: $color-grey-text;
        line-height: 28px;
        font-weight: 400;
    }

    figure {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin: 0 auto;
        width: 176px;
        height: 176px;
        border-radius: 50%;

        @include breakpoint($sm) {
            width: 100%;
            padding: 100% 0 0 0;
            margin: 0 auto 40px auto;
        }


        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
        }
    }

    h3 {
        margin: 0 0 10px 0;

        @include breakpoint($sm) {
            margin: 35px 0 50px 0;
        }
    }

    .description {
        padding-top: 8px;

        @include breakpoint($sm) {
            padding-top: 53px;
        }
    }

    .person {
        text-align: center;
        padding-left: 10px;

        @include breakpoint($sm) {
            max-width: 250px;
            float: left;
            text-align: left;
        }


        .competence {
            color: #a0a0a0;
            font-size: 16px;
            margin: 0 0 14px 0;
        }

        .name {
            font-size: 20px;
            line-height: 24px;
            color: $color-grey-text;
            font-weight: 500;
            margin-bottom: 24px;
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            color: $color-grey-text;
            margin-bottom: 8px;

            @include breakpoint($sm) {
                margin-bottom: 0;
            }
        }
    }

    .contact-information {
        padding: 0 0 0 30px;
        float: left;

        a {
          color: $color-lila;
        }

        .link {
            &.link-arrow-light {
                padding-top:0;
                padding-bottom:0;

                &:after {
                    top:9px;
                }
            }
        }
    }
}

.choose-contact-person {
    padding: 0 0 34px 0;

    h2 {
        text-align: center;
        text-transform: uppercase;
        margin: 0 10px 45px;
        color: #636364;
        letter-spacing: 0;
        font-size: 24px;
        line-height: 1.25;
        font-weight: 500;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 85px;
            height: 4px;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            background: $color-lila;
            margin: 12px 0 0 0;
        }

        + p {
            padding: 20px 10px 16px;
        }
    }

    .distance {
        @include breakpoint($sm) {
            height: 40px;
        }
    }

    figure {
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin: 0 auto;
        width: 176px;
        height: 176px;
        border-radius: 50%;

        @include breakpoint($sm) {
            margin: -40px auto 20px 0;
        }


        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
        }
    }

    .centered {
        padding-top: 10px;
        text-align: center;

        @include breakpoint($sm) {
            text-align: left;
        }
    }

    #chooser {
        font-size: 14px;
        line-height: 1;
        padding: 10px 5px;
        cursor: pointer;
        width: 80%;
        max-width: calc(100% - 20px);
        margin: 0 10px 30px;

        option {
            font-size: 14px;
            line-height: 1;
            padding: 10px 5px;
        }
    }

    .result {
        display: none;
        padding: 0 10px;

        &.show {
            display: flex;
        }

        .name {
            display: block;
            margin-bottom: 16px;
        }

        .position {
            margin-bottom: 16px;
        }
    }

    @include breakpoint($md) {
        background: #f7f7f7;
        padding: 30px 0;
        margin: 30px 0 60px;
        position: relative;

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 30px;
            width: 100%;
            top: 0;
            margin-top: -30px;
            left: 0;
            background: transparent url('../../img/bg/bg-top.svg') 0 0 no-repeat;
            background-size: cover;
        }

        &:after {
            background-image: url('../../img/bg/bg-bottom.svg');
            top: auto;
            margin: 0 0 -30px;
            bottom: 0;
        }

        h2 {
            font-size: 30px;
            line-height: 1;
        }

    }
}
.box {
    &.box-big {
        padding: 16px 20px 18px 20px;
        text-align: center;
        background: rgba(#FFF, 0.7);

        @include breakpoint($sm) {
            padding: 23px 30px;
        }
    }

    &.box-shine {
        background: rgba(255, 255, 255, 0.7);
        &.less-opacity {
            background: rgba(255, 255, 255, 0.875);
        }

        @include breakpoint($sm) {
            padding: 17px 40px 21px 40px;
          background: rgba(255, 255, 255, 0.7);
            &.less-opacity {
                background: rgba(255, 255, 255, 0.875);
            }
        }

      h3 {
        color: $color-black;
      }
      .link {
        &.link-red {
          color: $color-blue;

          span {
            border-color: $color-blue;

            &:before, &:after {
              background-image: url(../../img/icons/arrow-gt-blue.svg);
            }
          }
        }
      }
    }

    &.box-background {
        position: relative;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow: hidden;

        &:hover {
            .box-bg {
                transform: scale(1.1);
                opacity: 0.5;
            }

            .link {
                &.link-arrow {
                    span {
                        &:after {
                            transition: transform 300ms linear;
                            transform: translate3d(250%, -50%, 0);
                        }

                        &:before {
                            transition: transform 300ms linear;
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }
                }
            }
        }

        .box-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 300ms ease;
            opacity: .15;
        }

        .box-inner {
            position: relative;
            transition: all 250ms ease;
            padding-bottom: 128px;
            height: 100%;

            @include breakpoint($sm) {
                padding-bottom: 212px;
            }
        }
    }

    &.box-standard-margin {
        small {
            margin: 0 0 32px 0;
        }

        h3,
        .h3 {
            margin: 0 0 15px 0;
            text-transform: uppercase;
        }

        p {
            margin: 0 0 15px 0;
            line-height: 24px;
        }
    }

    &.box-white {
        background: #FFF;
    }

    &.box-dark {
        small {
            color: $color-black;

            /**/
        }

        h3,
        .h3 {
            color: $color-black;
        }

        p {
            color: $color-grey-text;
        }
    }

    &.box-press {
        padding: 15px 20px 3px 20px;
        background: #FFF;
        margin: 40px auto 0 auto;
        border: #DDD 1px solid;

        small {
            font-size: 16px;
            color: $color-grey;
            margin: 0 0 15px 0;
        }

        h4,
        .h4 {
            font-size: 20px;
            line-height: 32px;
            letter-spacing: normal;
            text-transform: uppercase;
            color: $color-blue;
            margin: 0 0 15px 0;

            a {
                color: inherit;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 26px 0;
        }
    }

    &.box-sidebar {
        margin-top: 24px;

        @include breakpoint($sm) {
            margin-top: 44px;
        }


        @include breakpoint($sm) {
            margin-top: 64px;
        }


        &:first-child {
            margin-top: 0;
        }
    }

    .box-inner {
        padding: 25px 20px;
    }
}
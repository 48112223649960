html.with-featherlight {
  /* disable global scrolling when featherlights are visible */
  overflow: hidden;
}

.featherlight {
  display: none;

  /* dimensions: spanning the background from edge to edge */
  position:fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 2147483647; /* z-index needs to be >= elements on the site. */

  /* position: centering content */
  text-align: center;

  /* insures that the ::before pseudo element doesn't force wrap with fixed width content; */
  white-space: nowrap;

  /* styling */
  cursor: pointer;
  background: #333;
  /* IE8 "hack" for nested featherlights */
  background: rgba(24, 23, 29, 0);

  /* support for nested featherlights. Does not work in IE8 (use JS to fix) */
  &:last-of-type {
    background: rgba(24, 23, 29, 0.86);
  }
  &:before {
    /* position: trick to center content vertically */
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .featherlight-content {
    /* make content container for positioned elements (close button) */
    position: relative;

    /* position: centering vertical and horizontal */
    text-align: left;
    vertical-align: middle;
    display: inline-block;

    /* dimensions: cut off images */
    overflow: auto;
    padding: 25px 59px 0;
    border-bottom: 25px solid transparent;

    /* dimensions: handling large content */
    margin-left: 5%;
    margin-right: 5%;
    max-height: 95%;

    /* styling */
    background: #fff;
    cursor: auto;

    /* reset white-space wrapping */
    white-space: normal;
  }
  /* contains the content */
  .featherlight-inner {
    /* make sure its visible */
    display: block;
  }
  /* don't show these though */
  script.featherlight-inner,
  link.featherlight-inner,
  style.featherlight-inner {
    display: none;
  }
  .featherlight-close-icon {
    /* position: centering vertical and horizontal */
    position: absolute;
    z-index: 9999;
    top: 50px;
    right: 50px;


    /* dimensions: 25px x 25px */
    line-height: 25px;
    width: 25px;

    /* styling */
    cursor: pointer;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 32px;
    background: #fff; /* Set the background in case it overlaps the content */
    background: rgba(255, 255, 255, 0.3);
    color: $color-red;
    border: none;
    padding: 0;
  }

  /* See http://stackoverflow.com/questions/16077341/how-to-reset-all-default-styles-of-the-html5-button-element */
  .featherlight-close-icon::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .featherlight-image {
    /* styling */
    width: 100%;
  }


  .featherlight-iframe .featherlight-content {
    /* removed the border for image croping since iframe is edge to edge */
    border-bottom: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }

  iframe {
    /* styling */
    border: none;
  }

  /* handling phones and small screens */
  @media only screen and (max-width: 1024px) {
    .featherlight-content {
      /* dimensions: maximize lightbox with for small screens */
      margin-left: 0;
      margin-right: 0;
      max-height: 98%;

      padding: 10px 15px 0;
      border-bottom: 10px solid transparent;
    }
  }
}















/* hide non featherlight items when printing */
@media print {
  html.with-featherlight > * > :not(.featherlight) {
    display: none;
  }
}

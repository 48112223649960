.share {
    padding: 20px 0 12px 0;
    text-align: center;

    h4 {
        margin: 0 0 18px 0;

        @include breakpoint($md) {
            margin: 0 0 30px 0;
        }

      &.red {
        color: $color-blue;
      }
    }

    nav {
        ul {
            list-style: none;
            display: inline-block;

            li {
                float: left;
                padding-bottom: 10px;
                width: 33.33%;

                @include breakpoint($sm){
                    margin: 0 20px;
                    padding-bottom: 0;
                    width: auto;
                }
                
                &.share-linkedin {
                    a {
                        background-image: url(../../img/icons/share-linkedin.svg);
                    }
                }
                
                &.share-whatsapp {
                    a {
                        background-image: url(../../img/icons/share-whatsapp.svg);
                        
                        @include breakpoint($sm){
                            display: none;
                            margin: 0;
                        }
                    }
                }

                &.share-xing {
                    a {
                        background-image: url(../../img/icons/share-xing.svg);
                    }
                }
                
                &.share-facebook {}

                &.share-twitter {
                    a {
                        background-image: url(../../img/icons/share-twitter.svg);
                    }
                }

                &.share-mail {
                    a {
                        background-image: url(../../img/icons/share-mail.svg);
                    }
                }

                a {
                    display: block;
                    width: 44px;
                    height: 44px;
                    text-indent: -9999px;
                    overflow: hidden;
                    background: url(../../img/icons/share-facebook.svg) no-repeat center center;
                    background-size: 100% 100%;
                    margin: 0 auto;

                    @include breakpoint($sm) {
                        margin: 0;
                    }

                    @include breakpoint($md) {
                        width: 54px;
                        height: 54px;
                    }
                }
            }
        }
    }
}
.video {
    background-color: $color-grey-light;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: $color-green 6px solid;
    position: relative;

    .overlay-blue-edge {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url(../../img/bg/flaeche-blau.png);
      background-position: bottom left;
      background-size: 30%;
      background-repeat: no-repeat;
    }

    .overlay-light {
      background-image: url(../../img/bg/flaeche-gruen.png);
      background-position: top right;
      background-size: 30%;
      background-repeat: no-repeat;
    }

    small {
        margin: 45px 0 30px 0;
    }

    h3,
    .h3 {
        margin: 0 0 12px 0;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin: 0 0 26px 0;
    }

    .right-side {
        text-align: right;
    }

    .video-wrapper {
        margin: 50px 0 4px 0;
    }

    .link {
        margin-bottom: 36px;
    }
}

.video .video-wrapper {

    &.asset {
        margin: 20px 0;

        @include breakpoint($md) {
            margin: 50px 0;
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
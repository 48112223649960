.intro {
    text-align: center;
    margin: 64px 0 32px;

    @include breakpoint($sm) {
        margin: 32px 0 32px;

        &:first-child {
            margin-top: 127px;
        }
    }


    p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
        color: $color-grey-text;
        font-weight: 500;

        &:first-child {
            text-transform: uppercase;
            font-size: 17px;
        }

        @include breakpoint($sm) {
            font-size: 20px;
            line-height: 32px;
            font-weight: 400;

            &:first-child {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    h2,
    .h2 {
        font-size: 24px;
        line-height: 30px;
        color: $color-grey;
        margin-bottom: 70px;

        @include breakpoint($sm) {
            font-size: 48px;
            line-height: 60px;
        }


        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 85px;
            height: 4px;
            transform: translateX(-50%);
            background: $color-lila;
            margin: 25px 0 0 0;
        }

        @include breakpoint($sm) {
            margin-bottom: 62px;
        }
    }
}

.cta-button {
    padding-top: 70px;
}
.header {
    position: relative;
    background: $color-grey;
    border-top: $color-lila 4px solid;
    margin-bottom: 10px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0%;
        display: block;
        width: 100%;
        height: 90px;
        background: url(../../img/bg/header-border-lila.svg) no-repeat center center;
        background-size: 1920px 90px;
        z-index: 400;
    }

    &.without-skew-cut {
        &:after {
            display: none;
        }
    }

    .flickity-viewport {
        transition: height 300ms ease;
    }

    .flickity-page-dots {
        bottom: -12px;
        height: 45px;
        margin: 0;
        counter-reset: dot-count;
        display: none;
        z-index: 500;

        @include breakpoint($md) {
            display: block;
        }
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 50px;
        line-height: 48px;
        opacity: 1;
        background: transparent;
        border: 0;
        border-bottom: $color-red 2px solid;
        border-radius: 0;
        color: $color-grey;
        margin: 0 12px;
        transition: all 300ms ease;

        &:after {
            counter-increment: dot-count;
            content: "0" counter(dot-count);
            pointer-events: none;
        }

        &.is-selected {
            color: rgba($color-grey, 0.3);
            border-color: rgba($color-grey, 0.3);
        }
    }

    .cell {
        width: 100%;

        .cell-inner {
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            padding: 80% 0 0 0;

            @include breakpoint($sm) {
                padding: 36.11111% 0 0 0;
            }
        }
    }

    &.header-lp {
        border-top: none;

        .cell-inner {
            min-height: 320px;

            @include breakpoint($sm) {
                min-height: 600px;
            }
        }

        &:after {
            background-image: url(../../img/bg/header-border-blue.svg);
        }

        .layer-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .layer {
            display: inline-block;
            color: #fff;
            font-size: 32px;
            line-height: 1.15;
            font-weight: 500;
            position: relative;
            background-color: rgba(0, 145,175, .85);
            padding: 30px 20px;

            @include breakpoint($sm) {
                font-size: 60px;
                padding: 50px 40px 30px;
            }

            @include breakpoint($md) {
                font-size: 74px;
                padding: 72px 90px 68px 110px;
            }

            .skew {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 35px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 35px;
                    opacity: .85;
                    background: url(../../img/bg/header-border-blue-total.svg) 100% 100% no-repeat;
                    transform: scale(-1);
                }
            }
        }
    }
}

.animation {
    background: $color-grey;
    border-top: $color-red 4px solid;
    border-bottom: $color-red 6px solid;
    min-height: calc(100vw * .49);

    @include breakpoint(1920px) {
        min-height: 950px;
    }

    .cell {
        width: 100%;

        .cell-inner {
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include breakpoint($md) {
                height: 0;

                .con {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            @include breakpoint($md) {
                padding: 49% 0 0 0;
            }
        }
    }

    .video-wrapper {  /*    object-fit: cover;*/

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .box {
        margin: 196px 0 30px 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }

        @include breakpoint($md) {
            margin: 0 0 30px 0;

            h1 {
                margin-bottom: 26px;
            }
        }


        h1 {
            color: $color-red;
            margin-bottom: 20px;
            font-weight: 400;
        }

        p {
            margin-bottom: 18px;
        }
    }
}
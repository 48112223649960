* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:before, &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, var, b, i, dl, dt, dd, ol, ul, li, form, fieldset, label, legend, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, menu, nav, section, summary, time, audio, video {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    vertical-align: baseline;
    font-size: 100%;
}

html {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    background: #FFF;
    font-family: Dax, sans-serif;
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility;

    &.not-scrollable {
        overflow: hidden;
        height: 101vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;

        body {
            overflow: hidden;
            height: 101vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
        }

        main {
            pointer-events: none;
        }
    }
}

body {
    margin: 0 auto;
    padding: 0;
    background: $color-grey;
    min-height: 100vh;

    &.slide-hide {
        overflow: hidden;

        &:after {
            content: '';
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            background: #fff;
        }
    }
}

article, aside, figure, figcaption, footer, header, nav, section, small {
    display: block;
}

h1, h2, h3, h4, p, ul, ol, dl, pre, address, table, form, hr, figure, audio, video {
    margin-bottom: 0;
}

img {
    display: block;
    max-width: 100%;
}

a {
    text-decoration: none;
}

strong {
    font-weight: 500;
}

figure {
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

main {
    max-width: 100%;
    width: 1920px;
    margin: 0 auto;
    background: #FFF;
    overflow-x: hidden;
    overflow-y: visible;
    display:block;
}

.teasers-white {
    padding: 10px 0 32px 0;
    background: $color-grey-light;

    @include breakpoint($sm) {
        padding: 12px 0 112px 0;
    }

    .col-xs-12 {
        margin-top: 20px;

        @include breakpoint($sm) {
            margin-top: 70px;
        }
    }

    .box {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid $color-blue;

        @include breakpoint($lg) {
            padding-bottom: 54px;

            &.box-shares {
                padding-bottom: 21px;
            }
        }

        .figure-wrapper {
            figure {
                padding: 45% 0 0 0;
            }
        }

        .box-inner {
            padding: 17px 10px 4px 10px;

            @include breakpoint($sm) {
                padding: 19px 20px 5px 20px;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column
            }

            @include breakpoint($lg) {
                padding: 19px 20px 25px 20px;
            }
        }

        small {
            background: $color-blue;
            color: $color-white;
            padding: 14px 10px 14px 10px;

            @include breakpoint($sm) {
              padding: 14px 10px 14px 10px;
            }

            @include breakpoint($md) {
              padding: 14px 10px 14px 10px;
            }
        }

        h4 {
            text-transform: uppercase;
            margin-bottom: 22px;
            letter-spacing: 0;

            @include breakpoint($sm) {
                margin-bottom: 0;
            }

            &.red {
              color: $color-black;
            }
        }

        .multi {

            h4 {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 20px;
            }
        }

        p {
            /* In Extra Light Klasse auslagern*/
            line-height: 20px;
            font-weight: 300;
            font-size: 16px;

            + p {
                margin-top: 20px;
            }
        }

        .link-wrapper {
            margin-top: 34px;

            .link {
                line-height: 40px;
                width: 100%;
                transition: all 300ms ease;
                padding: 11px 0 11px 18px;

                &:hover {
                  &:after {
                    transform: translate3d(8px, 0, 0);
                  }
                }

                &:after {
                  content: "";
                  position: absolute;
                  top: 25px;
                  left: 0;
                  display: block;
                  width: 11px;
                  height: 11px;
                  background: url(../../img/icons/arrow-gt-lila.svg) no-repeat top center;
                  background-size: 11px 11px;
                  transition: all 300ms ease;
                }
            }

            @include breakpoint($sm) {
                margin-top: auto;
            }

            @include breakpoint($lg) {
                margin-top: 0;
                position: absolute;
                bottom: 5px;
                left: 20px;
                right: 20px;
            }
        }

        .link {
            &.link-arrow-gt {
                margin-left: 18px;

                @include breakpoint($sm) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin: 7px 0 0 0;
                }
            }
        }

        iframe {
            margin: 25px 0 0 0;
            max-width: 100%;

            &.hidden {
                display: none;
            }
        }

        &.box-contact {
          border: 1px solid $color-green;

          small {
            background: $color-green;
          }

          .link-wrapper {

            .link {
              color: $color-green;

              &:after {
                background: url(../../img/icons/arrow-gt-green.svg) no-repeat top center;
              }
            }
          }
       }
    }
    .green {
      color: $color-green;
    }
}

.nav-tab {
    margin: 21px 0 0 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            margin: 0;
            padding: 0 3px 0 0;

            &.active {
                a {
                    color: $color-red;
                    border-color: $color-red;
                }
            }

            a {
                display: block;
                font-size: 16px;
                text-transform: uppercase;
                border-bottom: #e5e4e4 1px solid;
                line-height: 24px;
                padding: 0 10px;
                color: #737374;
            }
        }
    }
}
.search {
    padding: 35px 0 32px 0;

    @include breakpoint($sm) {
        padding: 70px 0 60px 0;
    }

    .form-wrapper {
        @include breakpoint($sm) {
            margin: 0 -35px;
        }
    }

    form {
        margin: 0;
        position: relative;
        width: 100%;
        height: 62px;
        border-radius: 31px;
        border: $color-grey-light 1px solid;

        input {
            font-size: 16px;
            font-weight: 300;
            border: 1px solid $color-blue;
            background: transparent;
            height: 60px;
            width: 100%;
            padding: 0 115px 0 60px;
            outline: none;
            color: $color-grey;
            border-radius: 28px;

            @include breakpoint($sm) {
                padding: 0 120px 0 65px;
                font-size: 22px;
            }
        }

        .btn {
            position: absolute;
            top: 3px;
            right: 4px;
            padding: 0 15px;
            outline: none;
            height: 55px;

            @include breakpoint($sm) {
                padding: 0 45px;
            }
        }

        span {
            position: absolute;
            top: 50%;
            left: 18px;
            display: block;
            width: 36px;
            height: 36px;
            border: $color-blue 2px solid;
            transform: translateY(-50%);
            border-radius: 50%;
            background: url(../../img/icons/magnifier-blue.svg) no-repeat center center;
            background-size: 20px 20px;
            pointer-events: none;

            @include breakpoint($sm) {
                width: 45px;
                height: 45px;
                left: 8px;
                background-size: 20px 22px;
            }
        }
    }
}

main {
    .search {
        &:first-child {
            padding-top: 91px;

            @include breakpoint($sm) {
                padding-top: 126px;
            }
        }
    }
}

.autocomplete-suggestions {
    background: $color-white;
    font-size: 16px;
    font-weight: 300;
    color: $color-grey;
    border-radius: 0 0 24px 24px;
    box-shadow: 0 4px 6px 0 rgba(80,80,80,0.4);
    .autocomplete-suggestion {
        margin: 5px 0;
        padding-left: 65px;
    }
}
#header-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 5009;
    transition: all 400ms ease-out;
    display: none;
    opacity: 0;
}

#header-search-close {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0;
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:hover {
        div {
            &:before, &:after {
                background: $color-grey;
            }
        }
    }

    span {
        margin-left: -9999px;
    }

    div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 2px;
        background: transparent;
        transform: translate3d(-50%, -50%, 0);

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 2px;
            background: $color-red;
            transform: translate3d(-50%, -50%, 0);
            margin: -10px 0 0 0;
            transition: all 300ms ease;
        }

        &:before {
            transform: translate3d(-50%, calc(-50% + 10px), 0) rotateZ(45deg);
        }

        &:after {
            margin: 10px 0 0 0;
            transform: translate3d(-50%, calc(-50% - 10px), 0) rotateZ(-45deg);
        }
    }
}
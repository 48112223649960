.cf:before, .cf:after {
    display: table;
    content: "";
}

.cf:after, .clear {
    clear: both;
}

.hide-mobile {
    display: none !important;

    @include breakpoint($sm) {
        display: block !important;
    }
}

.hide-desktop {
    display: block !important;

    @include breakpoint($sm) {
        display: none !important;
    }
}

.text-center {
    text-align: center;
}

.skew-cut {
    position: relative;
    display: block;
    height: 70px;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        width: 200%;
        height: 100px;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%) rotateZ(-2deg);
        background: #FFF;
    }

    &.skew-cut-bottom {
        &:before {
            transform: translateX(-50%) rotateZ(2deg);
            bottom: auto;
            top: 50%;
        }
    }
}


.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding: 56.79% 0 0 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .no-cookie {
        display: none;
    }

    &.forbidden {

        .no-cookie {
            display: block;
            text-align: center;
            position: absolute;
            width: 90%;
            top: 50%;
            left: 50%;
            color: #505050;
            transform: translate(-50%, -50%);

            a {
                color: #d40009;
            }
        }

        @include max-breakpoint($md) {

            padding: 0;
            height: initial;

            .no-cookie {
                font-size: 16px;
                position: relative;
                width: initial;
                top: auto;
                left: auto;
                transform: none;

                .video & {
                    padding-bottom: 30px;
                }
            }
        }
    }
}

.select {
    position: relative;
    height: 44px;
    width: 100%;

    &.active {
        z-index: 1500;

        button {
            //color: $color-grey;

            &:after {
                //background-image: url(../../img/icons/arrow-gt-down-grey.svg);
              transform: rotate(180deg);
            }
        }

        .options {
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }

        + .select-shadow {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1250;
        }
    }

    @include breakpoint($md) {
        height: 57px;
    }


    button {
        text-align: left;
        display: block;
        width: 100%;
        background: $color-blue;
        height: 42px;
        line-height: 42px;
        border: 0;
        padding: 0 20px;
        text-transform: uppercase;
        color: $color-white;
        font-weight: 500;
        font-size: 14px;
        outline: none;
        cursor: pointer;

        @include breakpoint($md) {
            height: 55px;
            line-height: 55px;
        }


        &:after {
            content: "";
            display: block;
            width: 52px;
            height: 100%;
            background: url(../../img/icons/arrow-gt-down-white.svg) no-repeat center center;
            background-size: 11px 11px;
            position: absolute;
            top: 0;
            right: 0;
            border-left: rgba(#000, 0.05) 1px solid;
        }
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        opacity: 0;

        @include breakpoint($md) {
            display: none;
        }
    }

    .options {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        list-style: none;
        overflow: hidden;
        height: 0;
        opacity: 0;
        transform: translateY(10px);
        transition: all 300ms ease;
        z-index: 500;

        li {
            background: rgba(#d5d3d3, 0.92);
            border-top: rgba(#000, 0.05) 1px solid;

            /**/
            transition: all 150ms ease;

            &:hover {
                background: #d5d3d3;

                a {
                    border-color: $color-blue;
                    color: $color-blue;
                }
            }

            a {
                display: block;
                line-height: 59px;
                border-top: 1px;
                color: $color-grey;
                border-left: transparent 2px solid;
                padding: 0 20px 0 18px;
                font-size: 16px;
                transition: all 150ms ease;
            }
        }
    }
}

.filter-wrapper {
    position: relative;
    display: inline-block;

    &:hover {
        .select-button {
            color: $color-lila;
        }
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 0;
        cursor: pointer;
    }

    .select-button {
        border-bottom: $color-grey 1px solid;
        font-size: 20px;
        line-height: 45px;
        text-align: center;
        transition: all 300ms ease;
        white-space: nowrap;

        &:after {
            content: "";
            display: inline-block;
            white-space: nowrap;
            width: 15px;
            height: 15px;
            background: url(../../img/icons/arrow-gt-lila.svg) no-repeat center center;
            background-size: 11px 11px;
            transform: rotateZ(90deg);
        }
    }
}

.contact-information {
    @include breakpoint($sm) {
        max-width: 300px;
    }


    p {
        font-weight: 500;
    }

    .link.link-arrow-light {
        font-size: 16px;

        @include breakpoint($sm) {
            line-height: 27px;
        }
    }
}

.pagination {
    text-align: center;

    ul {
        list-style: none;
        display: inline-block;
        margin: 10px 0 13px 0;

        li {
            float: left;
            margin: 0 25px 0 0;

            &:last-child {
                margin: 0;
            }

            &.active {
                a {
                    border-color: rgba($color-grey, 0.3);
                    color: rgba($color-grey, 0.3);
                }
            }

            a {
                display: block;
                width: 45px;
                border-bottom: $color-lila 2px solid;
                text-align: center;
                font-size: 16px;
                line-height: 45px;
                font-weight: 400;
                color: $color-grey;
                transition: all 300ms ease;

                &:hover {
                    color: $color-lila;
                }
            }
        }
    }
}


.tabs {
    max-width: 100%;
    margin: 0 10px 10px 10px;

    @include breakpoint($sm) {
        margin: 0;
    }


    ul {
        list-style: none;
        width: 100%;
        max-width: 100%;
        background: #FFF;

        li {
            width: 100 / 1 * 1%;

            @include breakpoint($sm) {
                float: left;
            }


            &.active {
                background: $color-grey-light;
                color: $color-red;
            }

            a {
                display: block;
                text-align: center;
                font-size: 20px;
                line-height: 54px;
                color: $color-grey;
                transition: all 300ms ease;
                padding: 0 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-justify: inter-word;

                &:hover {
                    color: $color-red;
                }
            }
        }

        @include breakpoint($sm) {
            li:first-child:nth-last-child(2), li:first-child:nth-last-child(2) ~ li {
                width: 100 / 2 * 1%;
            }

            li:first-child:nth-last-child(3), li:first-child:nth-last-child(3) ~ li {
                width: 100 / 3 * 1%;
            }

            li:first-child:nth-last-child(4), li:first-child:nth-last-child(4) ~ li {
                width: 100 / 4 * 1%;
            }
        }
    }
}

table {
    width: 100%;
    text-align: left;

    thead {
        tr {}

        th {
            font-size: 20px;
            line-height: 1;
            font-weight: 500;
            color: $color-grey;
            padding: 10px 0;
            border-bottom: rgba($color-grey, 0.3) 1px solid;
            vertical-align: middle;

            &:last-child {
                text-align: right;
            }
        }
    }

    tbody {
        tr {}

        td {
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
            color: $color-grey;
            padding: 10px 0;
            border-bottom: rgba($color-grey, 0.3) 1px solid;
            vertical-align: middle;

            &:first-child {
                font-weight: 500;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}
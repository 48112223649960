.press-kit {
    margin: 32px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }


    h3 {
        margin: 0 0 20px 0;
    }

    table {
        margin: 30px 0 0 0;

        @include max-breakpoint($sm) {
            display: block;

            thead {
                display: none;
            }

            tbody {
                display: block;
            }
        }


        th {
            width: 100 / 3 * 1%;
        }

        tr {
            @include max-breakpoint($sm) {
                display: block;
                border-bottom: rgba($color-grey, 0.3) 1px solid;
            }
        }

        td {
            vertical-align: top;

            @include max-breakpoint($sm) {
                display: block;
                border-bottom: 0;

                &:last-child {
                    padding: 0;

                    .dropdown-wrapper {
                        padding-bottom: 10px;
                        padding-left: 25px;
                    }
                }
            }
        }
    }

    .toggle-accordion {
        position: relative;
        display: block;
        padding: 11px 0 11px 25px;
        cursor: pointer;

        &.opened {
            &:after {
                transform: translateY(-50%) rotateZ(90deg);
            }
        }

        &:after {
            content: "";
            display: block;
            width: 11px;
            height: 11px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: url(../../img/icons/arrow-gt-lila.svg) no-repeat center center;
            background-size: 11px 11px;
            transition: all 300ms ease;
        }
    }

    .dropdown-wrapper {
        display: none;
    }

    .document {
        display: block;
        color: $color-grey;
        padding: 6px 0;

        &:hover {
            color: $color-red;
        }

        p {
            float: left;
            line-height: 24px;
            font-weight: 500;
            color: inherit;
        }

        div {
            float: right;
            font-size: 15px;
            line-height: 20px;
            font-weight: 300;

            span {
                display: inline;
            }
        }

        img {
            width: 20px;
            height: 20px;
            float: left;
            margin-right: 20px;
        }
    }
}

table {
    width: 100%;
    text-align: left;

    thead {
        tr {}

        th {
            font-size: 20px;
            line-height: 1;
            font-weight: 500;
            color: $color-grey;
            padding: 10px 0;
            border-bottom: rgba($color-grey, 0.3) 1px solid;
            vertical-align: middle;

            &:last-child {
                text-align: right;
            }
        }
    }

    tbody {
        tr {}

        td {
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
            color: $color-grey;
            padding: 10px 0;
            border-bottom: rgba($color-grey, 0.3) 1px solid;
            vertical-align: middle;

            &:first-child {
                font-weight: 500;
            }
        }
    }
}

.news-details {

    tbody {

        td {

            &:last-child {
                text-align: left;
            }

            table {

                td {
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
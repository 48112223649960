.press-dates {
    background: $color-grey-light;
    margin: 64px auto;
    padding-top: 32px;
    padding-bottom: 48px;

    @include breakpoint($sm) {
        margin: 90px auto;
        padding: 64px 0 84px 0;
    }


    h3 {
        margin: 0 0 30px 0;
    }

    .filter-wrapper {
        min-width: 210px;
    }

    .reminder, .email-reminder {
        display: block;
        overflow: hidden;
        text-indent: -9999px;
        width: 30px;
        height: 30px;
        background: $color-grey;
        float: right;
        text-align: left;
        background: url(../../img/icons/reminder-email.svg) no-repeat center center;
        background-size: 25px 25px;
    }

    .reminder {
        background-image: url(../../img/icons/calender-grey.svg);
    }

    .email-reminder {
        margin-left: 10px;

        @include breakpoint($sm) {
            margin-left: 25px;
        }
    }

    table {
        margin: 30px 0 0 0;

        @include max-breakpoint($sm) {
            display: block;

            thead {
                display: none;
            }
        }


        tbody {
            @include max-breakpoint($sm) {
                display: block;

                tr {
                    display: block;
                    border-bottom: rgba($color-grey, 0.3) 1px solid;

                    &:after {
                        content: "";
                        display: table;
                        clear: both;
                    }
                }
            }


            td {
                @include max-breakpoint($sm) {
                    display: block;
                    border: 0;
                    width: 50%;
                    float: left;

                    &:first-child {
                        width: 100%;
                        float: none;
                    }

                    &:nth-child(2) {
                        line-height: 30px;
                    }
                }
            }
        }
    }

    small {
        display: none;

        @include max-breakpoint($sm) {
            display: block;
            opacity: .5;
        }
    }

    tr {

        &[data-type="cid_archive"] {
            display: none;
        }
    }
}

main {

    > .press-dates {

        &.press-dates-with-tabs {
            max-width: 1170px;
            padding-right: 10px;
            padding-left: 10px;
        }
        &.dates-landingpage {
            max-width: 720px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.h30 {
    height: 30px;
}

.press-dates-with-tabs {
    background: #FFF;
    margin: 32px auto;
    padding: 0;

    @include breakpoint($sm) {
        margin: 64px auto;
        padding: 0;
    }


    h3 {
        margin: 0 0 30px 0;
    }

    .tab-wrapper {
        background: $color-grey-light;
        padding: 32px 0 48px 0;
        margin-left: -10px;
        margin-right: -10px;

        @include breakpoint($sm) {
            padding: 64px 0 84px 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.press-dates-left {
    table {
        width: calc(100% - 20px - 20px);
        margin: 30px auto 0 auto;

        @include breakpoint($sm) {
            width: calc(100% - 40px - 40px);
        }
    }
}

.dates-landingpage {
    background: #fff;

    h3 {
        margin-bottom: 8px;
    }

    .reminder {
        background-image: url(../../img/icons/calender-blue.svg);
    }

    .link-blue {
        color: $color-blue;
    }
}
.teasers-search {
    .uppercase {
        text-transform: uppercase;
        margin-bottom: 10px;

        + .uppercase {
            margin-bottom: 44px;
        }

        @include breakpoint($sm) {
            margin-left: -35px;
            margin-right: -35px;
        }
    }

    .search-result {
        border-top: #DDD 1px solid;
        padding: 24px 0;

        &:last-child {
            border-bottom: #DDD 1px solid;
            margin-bottom: 24px;
        }

        @include breakpoint($sm) {
            margin: 0 -35px;
        }


        h4 {
            margin-bottom: 24px;

            a {
                color: $color-red;
                text-transform: uppercase;
            }
        }

        p {
            margin-bottom: 24px;

            strong {
                font-weight: 600;
            }
        }
    }
}
.career-offer {
  img {
    height: auto !important;
  }
  .button-holder {
    text-align: center;
    &.second {
      margin-top: 45px;
    }
  }
  h2 {
    font-size: 22px;
    color: $color-green;
    margin: 6px 0 20px;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 6px 0 18px;
  }

  .widget-holder {
    margin: 30px auto;
    width: 256px;
    height: 154px;

    @include breakpoint($sm) {
      margin: 50px auto 56px;
    }
  }
}

#jobSwitcher {

  ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;

    li {
      a {
        display: block;
        width: 27px;
        height: 20px;
        cursor: pointer;
        background: url('../../img/icons/filter-liste.svg') 0 0 no-repeat;
        background-size: contain;

        span {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0,0,0,0);
          white-space: nowrap;
          border: 0;
        }

        &.is-active {
          background-image: url('../../img/icons/filter-liste-aktiv.svg');
        }
      }

      #showTiles {
        width: 20px;
        background-image: url('../../img/icons/filter-kachel.svg');

        &.is-active {
          background-image: url('../../img/icons/filter-kachel-aktiv.svg');
        }
      }

      + li {
        margin-left: 15px;
      }
    }
  }
}

#tilesView,
#listView {
  display: none;

  &.is-active {
    display: flex;
  }
}

.tabsContent {
  .jobRows {
    padding-top: 5px;

    &:first-child {
      padding-top: 20px;

      @include breakpoint($sm) {
        padding-top: 48px;
      }
    }

    &:nth-child(even) {

      .box-background {
        background: #fafafa;
      }
    }
  }
}

#listView {

  .jobRows {

    .box {

      .box-inner {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 20px 15px;
      }

      h3 {
        color: $color-blue;
        font-size: 20px;
        margin-bottom: 3px;
      }

      .location {
        font-size: 14px;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .details {

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .link-arrow {
        padding: 0;
        margin: 1px 0 0 20px;
        width: 36px;
        height: 36px;
        position: relative;
        bottom: auto;
        left: 0;
        right: auto;
      }

      .title-and-details {
        width: 100%;
      }

      .id-and-link {
        width: 100%;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        small {
          margin-bottom: 0;
        }
      }

      @include breakpoint($sm) {

        .title-and-details {
          flex: 1 1 0;
          width: auto;
          min-width: auto;
        }

        .id-and-link {
          flex: 0 0 auto;
          width: auto;
          min-width: auto;
          margin-left: 20px;

          > div {
            align-items: flex-start;
          }
        }

        .link-arrow {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}

.jobs-anchor {
  margin-top: 50px;
}
.jobRows {
  .title-and-details {
    ul {
      padding: 5px 0 0 20px;
    }
  }
}
@include breakpoint($sm) {
  .content-text {
    .career-offer {
      h4 {
        margin-top: 52px;
      }
      h2 {
        font-size: 24px;
      }
    }
  }
  .jobRows {
    .title-and-details {
      .details {
        overflow: hidden;
        height: 0;
      }
    }

    > .box {
      &:hover {
        .title-and-details {
          .details {
            height: auto;
          }
        }
      }
    }
  }
}
.article-nav {
    margin: 24px 10px 24px 10px;

    .newsplaceholder {
        height: 60px;
    }

    @include breakpoint($sm) {
        margin: 97px 0 50px 0;

        .newsplaceholder {
            height: 90px;
        }
    }


    .arrow-wrapper {
        @include breakpoint($sm) {
            display: table;
            width: 100%;
        }
    }

    .arrow {
        display: block;
        position: relative;
        font-size: 18px;
        line-height: 24px;
        width: 29px;
        height: 58px;
        transition: all 300ms ease;
        overflow: hidden;
        text-indent: -9999px;
        color: transparent;

        @include breakpoint($sm) {
            width: 100%;
            overflow: visible;
            text-indent: 0;
            color: #4e4e4e;
            height: 90px;
            vertical-align: middle;
            display: table-cell;

            &:hover {
                color: $color-blue;

                &:after {
                    transform: translateY(-50%) translateX(10px);
                }

                span {
                    color: $color-blue;
                }
            }

            span {
                vertical-align: middle;
            }
        }

        @include breakpoint($md) {
            font-size: 24px;
            line-height: 30px;
        }


        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            display: block;
            width: 29px;
            height: 58px;
            background: url(../../img/icons/arrow-big-blue.svg) no-repeat center center;
            background-size: 29px 58px;
            transition: all 300ms ease;

            @include breakpoint($sm) {
                left: 60px;
            }
        }
    }

    .previous {
        @include breakpoint($sm) {
            padding: 0 0 0 120px;
        }


        @include breakpoint($sm) {
            &:hover {
                &:after {
                    transform: rotateZ(180deg) translateY(50%) translateX(10px);
                }
            }
        }


        &:after {
            transform: rotateZ(180deg) translateY(50%);
        }

        span {}
    }

    .next {
        float: right;

        @include breakpoint($sm) {
            float: none;
            text-align: right;
            padding: 0 120px 0 0;
        }


        &:after {
            @include breakpoint($sm) {
                left: auto;
                right: 60px;
            }
        }
    }

    .more-link {
        display: block;
        font-size: 18px;
        line-height: 24px;
        color: $color-grey;
        margin-top: 18px;

        &:hover {
            color: $color-blue;
        }

        @include breakpoint($sm) {
            margin:0;
            position:relative;
            top:50%;
            transform:translateY(-50%);
        }

        @include breakpoint($md) {
            font-size: 24px;
            line-height: 30px;
        }
    }
}
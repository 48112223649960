.quotes {
    padding: 30px 0 0 0;
    background: $color-white url(../../img/bg/quotes-bg_new.png) no-repeat center center;
    text-align: center;
    border-bottom: 6px solid $color-blue;

    @include breakpoint($sm) {
        padding: 34px 0 0 0;
    }

    .flickity-page-dots {
        bottom: 38px;
        counter-reset: dot-count;

        @include breakpoint($sm) {
            bottom: 30px;
        }
    }

    .flickity-page-dots .dot {
        width: 45px;
        height: 2px;
        opacity: 1;
        background: lighten($color-lila, 30%);
        border: 0;
        border-radius: 0;
        color:  $color-lightblue;
        margin: 0 12px;
        transition: all 300ms ease;
        text-indent: 1rem;
    }

    .flickity-page-dots .dot.is-selected {
        background: $color-lila;
    }

    .cell {
        width: 100%;
        padding: 0 0 58px 0;

        &:first-child {
            h3 {
                opacity: 1;
            }

            .author {
                opacity: 1;
            }
        }
    }

    .title {
        text-transform: uppercase;
        color: $color-black;
        margin: 0 40px 17px 40px;
        line-height: 20px;

        @include breakpoint($sm) {
            margin: 0 0 5px 0;
        }
    }

    h3 {
        color: $color-blue;
        margin: 0 20px 9px 20px;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: .08em;
        font-weight: 500;
        font-style: italic;
        opacity: 0;

        @include breakpoint($md) {
            margin: 9px 0;
            font-size: 39px;
            line-height: 60px;
        }
    }

    .author {
        font-size: 17px;
        letter-spacing: .02em;
        margin: 0 30px;
        opacity: 0;

        @include breakpoint($sm) {
            font-size: 20px;
            margin: 0;
        }
    }
}
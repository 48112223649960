/* DEFINITION */

$color-grey-text: #4e4e4e;
$color-grey: #505050;
$color-grey-light: #F1F2F1;
$color-red: #d40009;
$color-white: #FFFFFF;
$color-blue: #0091AF;
$color-lila: #A71680;
$color-green: #90BF51;
$color-red-new: #C1092B;
$color-black: #000000;

$color-lightblue: lighten($color-blue, 80%);

/* FONTS */

@font-face {
    font-family: "Dax";
    src: url("../fonts/5557960/ece88577-b8b8-43cf-a4b2-cee1bf0640b7.eot?#iefix");
    src: url("../fonts/5557960/ece88577-b8b8-43cf-a4b2-cee1bf0640b7.eot?#iefix") format("eot"), url("../fonts/5557960/c945938b-c6f1-45ce-81b4-27cdab6611c5.woff2") format("woff2"), url("../fonts/5557960/d050c6b0-688c-4ffa-a322-80eee7ecdbd1.woff") format("woff"), url("../fonts/5557960/e300767a-3d9e-49db-ae5c-ad1e0609e480.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Dax";
    src: url("../fonts/5558927/dc1d0de4-1ef7-4deb-bc40-6e2d14daf845.eot?#iefix");
    src: url("../fonts/5558927/dc1d0de4-1ef7-4deb-bc40-6e2d14daf845.eot?#iefix") format("eot"), url("../fonts/5558927/458e836d-651d-4d59-9678-d7a4a3469f81.woff2") format("woff2"), url("../fonts/5558927/2a854633-1ce5-4605-9da6-00b9fe321771.woff") format("woff"), url("../fonts/5558927/92be2e91-849a-4e45-8434-e68154e28504.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Dax";
    src: url("../fonts/5559351/5323bc39-8987-47cd-8f94-4402b78570a1.eot?#iefix");
    src: url("../fonts/5559351/5323bc39-8987-47cd-8f94-4402b78570a1.eot?#iefix") format("eot"), url("../fonts/5559351/4c02235c-5b87-4303-ad70-89daa8187ef1.woff2") format("woff2"), url("../fonts/5559351/b6096034-911a-4987-80e2-102c62f58fb2.woff") format("woff"), url("../fonts/5559351/7926c9b3-d774-429b-a204-2ef9f5a1e178.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Dax";
    src: url("../fonts/5559363/814c112d-7508-4b2f-9720-6d1bde7d8cf8.eot?#iefix");
    src: url("../fonts/5559363/814c112d-7508-4b2f-9720-6d1bde7d8cf8.eot?#iefix") format("eot"), url("../fonts/5559363/d1e54ad8-a635-4efd-9e8c-53a91107696a.woff2") format("woff2"), url("../fonts/5559363/4325e702-8be5-4bc6-9f70-48157b1e2c8a.woff") format("woff"), url("../fonts/5559363/d461dfee-37bf-4fed-ae7b-25511621134b.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
.training-center {
    margin: 32px 0 64px 0;

    @include breakpoint($sm) {
        margin: 64px 0;
    }


    h3 {
        margin: 0 0 32px 0;
    }

    p {
        margin-bottom: 25px;
    }

    ul {
        margin-bottom: 20px;
        padding: 0 0 0 20px;

        li {
            line-height: 24px;
            color: $color-grey-text;
            margin-bottom: 5px;
        }
    }

    .select-wrapper {
        padding-top: 24px;
    }

    .select {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        ul {
            margin: 0;
            padding: 0;

            li {
                margin: 0;
            }
        }
    }
}
@charset "UTF-8";
/* DEFINITION */
/* FONTS */
@font-face {
  font-family: "Dax";
  src: url("../fonts/5557960/ece88577-b8b8-43cf-a4b2-cee1bf0640b7.eot?#iefix");
  src: url("../fonts/5557960/ece88577-b8b8-43cf-a4b2-cee1bf0640b7.eot?#iefix") format("eot"), url("../fonts/5557960/c945938b-c6f1-45ce-81b4-27cdab6611c5.woff2") format("woff2"), url("../fonts/5557960/d050c6b0-688c-4ffa-a322-80eee7ecdbd1.woff") format("woff"), url("../fonts/5557960/e300767a-3d9e-49db-ae5c-ad1e0609e480.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Dax";
  src: url("../fonts/5558927/dc1d0de4-1ef7-4deb-bc40-6e2d14daf845.eot?#iefix");
  src: url("../fonts/5558927/dc1d0de4-1ef7-4deb-bc40-6e2d14daf845.eot?#iefix") format("eot"), url("../fonts/5558927/458e836d-651d-4d59-9678-d7a4a3469f81.woff2") format("woff2"), url("../fonts/5558927/2a854633-1ce5-4605-9da6-00b9fe321771.woff") format("woff"), url("../fonts/5558927/92be2e91-849a-4e45-8434-e68154e28504.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Dax";
  src: url("../fonts/5559351/5323bc39-8987-47cd-8f94-4402b78570a1.eot?#iefix");
  src: url("../fonts/5559351/5323bc39-8987-47cd-8f94-4402b78570a1.eot?#iefix") format("eot"), url("../fonts/5559351/4c02235c-5b87-4303-ad70-89daa8187ef1.woff2") format("woff2"), url("../fonts/5559351/b6096034-911a-4987-80e2-102c62f58fb2.woff") format("woff"), url("../fonts/5559351/7926c9b3-d774-429b-a204-2ef9f5a1e178.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Dax";
  src: url("../fonts/5559363/814c112d-7508-4b2f-9720-6d1bde7d8cf8.eot?#iefix");
  src: url("../fonts/5559363/814c112d-7508-4b2f-9720-6d1bde7d8cf8.eot?#iefix") format("eot"), url("../fonts/5559363/d1e54ad8-a635-4efd-9e8c-53a91107696a.woff2") format("woff2"), url("../fonts/5559363/4325e702-8be5-4bc6-9f70-48157b1e2c8a.woff") format("woff"), url("../fonts/5559363/d461dfee-37bf-4fed-ae7b-25511621134b.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: white;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #0091AF;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

html.with-featherlight {
  /* disable global scrolling when featherlights are visible */
  overflow: hidden;
}

.featherlight {
  display: none;
  /* dimensions: spanning the background from edge to edge */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647; /* z-index needs to be >= elements on the site. */
  /* position: centering content */
  text-align: center;
  /* insures that the ::before pseudo element doesn't force wrap with fixed width content; */
  white-space: nowrap;
  /* styling */
  cursor: pointer;
  background: #333;
  /* IE8 "hack" for nested featherlights */
  background: rgba(24, 23, 29, 0);
  /* support for nested featherlights. Does not work in IE8 (use JS to fix) */
  /* contains the content */
  /* don't show these though */
  /* See http://stackoverflow.com/questions/16077341/how-to-reset-all-default-styles-of-the-html5-button-element */
  /* handling phones and small screens */
}
.featherlight:last-of-type {
  background: rgba(24, 23, 29, 0.86);
}
.featherlight:before {
  /* position: trick to center content vertically */
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.featherlight .featherlight-content {
  /* make content container for positioned elements (close button) */
  position: relative;
  /* position: centering vertical and horizontal */
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  /* dimensions: cut off images */
  overflow: auto;
  padding: 25px 59px 0;
  border-bottom: 25px solid transparent;
  /* dimensions: handling large content */
  margin-left: 5%;
  margin-right: 5%;
  max-height: 95%;
  /* styling */
  background: #fff;
  cursor: auto;
  /* reset white-space wrapping */
  white-space: normal;
}
.featherlight .featherlight-inner {
  /* make sure its visible */
  display: block;
}
.featherlight script.featherlight-inner,
.featherlight link.featherlight-inner,
.featherlight style.featherlight-inner {
  display: none;
}
.featherlight .featherlight-close-icon {
  /* position: centering vertical and horizontal */
  position: absolute;
  z-index: 9999;
  top: 50px;
  right: 50px;
  /* dimensions: 25px x 25px */
  line-height: 25px;
  width: 25px;
  /* styling */
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 32px;
  background: #fff; /* Set the background in case it overlaps the content */
  background: rgba(255, 255, 255, 0.3);
  color: #d40009;
  border: none;
  padding: 0;
}
.featherlight .featherlight-close-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.featherlight .featherlight-image {
  /* styling */
  width: 100%;
}
.featherlight .featherlight-iframe .featherlight-content {
  /* removed the border for image croping since iframe is edge to edge */
  border-bottom: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}
.featherlight iframe {
  /* styling */
  border: none;
}
@media only screen and (max-width: 1024px) {
  .featherlight .featherlight-content {
    /* dimensions: maximize lightbox with for small screens */
    margin-left: 0;
    margin-right: 0;
    max-height: 98%;
    padding: 10px 15px 0;
    border-bottom: 10px solid transparent;
  }
}

/* hide non featherlight items when printing */
@media print {
  html.with-featherlight > * > :not(.featherlight) {
    display: none;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, var, b, i, dl, dt, dd, ol, ul, li, form, fieldset, label, legend, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, menu, nav, section, summary, time, audio, video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background: #FFF;
  font-family: Dax, sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
}
html.not-scrollable {
  overflow: hidden;
  height: 101vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
html.not-scrollable body {
  overflow: hidden;
  height: 101vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
html.not-scrollable main {
  pointer-events: none;
}

body {
  margin: 0 auto;
  padding: 0;
  background: #505050;
  min-height: 100vh;
}
body.slide-hide {
  overflow: hidden;
}
body.slide-hide:after {
  content: "";
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
}

article, aside, figure, figcaption, footer, header, nav, section, small {
  display: block;
}

h1, h2, h3, h4, p, ul, ol, dl, pre, address, table, form, hr, figure, audio, video {
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 500;
}

figure img {
  width: 100%;
  height: auto;
  display: block;
}

main {
  max-width: 100%;
  width: 1920px;
  margin: 0 auto;
  background: #FFF;
  overflow-x: hidden;
  overflow-y: visible;
  display: block;
}

/* scss-compile-options: --precision 15 */
.con {
  width: 100%;
}

.row {
  display: flex;
  flex-flow: row wrap;
}

[class^=col-] {
  flex: 1;
  max-width: 100%;
}

.con {
  margin: 0 auto;
  max-width: 100%;
}

.row {
  margin: 0 -10px;
}

[class^=col-] {
  padding: 0 10px;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-1 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-2 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-3 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 25%;
  max-width: 25%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-4 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-5 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-6 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 50%;
  max-width: 50%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-7 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-8 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-9 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 75%;
  max-width: 75%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-10 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-11 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-12 {
  -ms-flex: auto;
  -moz-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  min-width: 100%;
  max-width: 100%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.con {
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  .con {
    margin: 0 auto;
    max-width: 720px;
  }
  .row {
    margin: 0 -10px;
  }
  [class^=col-] {
    padding: 0 10px;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-1 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 25%;
    max-width: 25%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 50%;
    max-width: 50%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 75%;
    max-width: 75%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 100%;
    max-width: 100%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .con {
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .con {
    margin: 0 auto;
    max-width: 1000px;
  }
  .row {
    margin: 0 -15px;
  }
  [class^=col-] {
    padding: 0 15px;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-1 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 25%;
    max-width: 25%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 50%;
    max-width: 50%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 75%;
    max-width: 75%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 100%;
    max-width: 100%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .con {
    margin: 0 auto;
    max-width: 1410px;
  }
  .row {
    margin: 0 -15px;
  }
  [class^=col-] {
    padding: 0 15px;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-1 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 25%;
    max-width: 25%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 50%;
    max-width: 50%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 75%;
    max-width: 75%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    -ms-flex: auto;
    -moz-flex: auto;
    -webkit-flex: auto;
    flex: auto;
    min-width: 100%;
    max-width: 100%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .row-lg-small {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
}
#mobile-wrapper {
  display: block;
}
@media screen and (min-width: 1024px) {
  #mobile-wrapper {
    display: none;
  }
}

#desktop-wrapper {
  display: none;
}
@media screen and (min-width: 1024px) {
  #desktop-wrapper {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .mobile-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobile-padding .overlay-light {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}

.cf:before, .cf:after {
  display: table;
  content: "";
}

.cf:after, .clear {
  clear: both;
}

.hide-mobile {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .hide-mobile {
    display: block !important;
  }
}

.hide-desktop {
  display: block !important;
}
@media screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.skew-cut {
  position: relative;
  display: block;
  height: 70px;
  overflow: hidden;
}
.skew-cut:before {
  content: "";
  display: block;
  width: 200%;
  height: 100px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) rotateZ(-2deg);
  background: #FFF;
}
.skew-cut.skew-cut-bottom:before {
  transform: translateX(-50%) rotateZ(2deg);
  bottom: auto;
  top: 50%;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 56.79% 0 0 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrapper .no-cookie {
  display: none;
}
.video-wrapper.forbidden .no-cookie {
  display: block;
  text-align: center;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  color: #505050;
  transform: translate(-50%, -50%);
}
.video-wrapper.forbidden .no-cookie a {
  color: #d40009;
}
@media screen and (max-width: 1023px) {
  .video-wrapper.forbidden {
    padding: 0;
    height: initial;
  }
  .video-wrapper.forbidden .no-cookie {
    font-size: 16px;
    position: relative;
    width: initial;
    top: auto;
    left: auto;
    transform: none;
  }
  .video .video-wrapper.forbidden .no-cookie {
    padding-bottom: 30px;
  }
}

.select {
  position: relative;
  height: 44px;
  width: 100%;
}
.select.active {
  z-index: 1500;
}
.select.active button:after {
  transform: rotate(180deg);
}
.select.active .options {
  height: auto;
  opacity: 1;
  transform: translateY(0);
}
.select.active + .select-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1250;
}
@media screen and (min-width: 1024px) {
  .select {
    height: 57px;
  }
}
.select button {
  text-align: left;
  display: block;
  width: 100%;
  background: #0091AF;
  height: 42px;
  line-height: 42px;
  border: 0;
  padding: 0 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .select button {
    height: 55px;
    line-height: 55px;
  }
}
.select button:after {
  content: "";
  display: block;
  width: 52px;
  height: 100%;
  background: url(../../img/icons/arrow-gt-down-white.svg) no-repeat center center;
  background-size: 11px 11px;
  position: absolute;
  top: 0;
  right: 0;
  border-left: rgba(0, 0, 0, 0.05) 1px solid;
}
.select select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  opacity: 0;
}
@media screen and (min-width: 1024px) {
  .select select {
    display: none;
  }
}
.select .options {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transform: translateY(10px);
  transition: all 300ms ease;
  z-index: 500;
}
.select .options li {
  background: rgba(213, 211, 211, 0.92);
  border-top: rgba(0, 0, 0, 0.05) 1px solid;
  /**/
  transition: all 150ms ease;
}
.select .options li:hover {
  background: #d5d3d3;
}
.select .options li:hover a {
  border-color: #0091AF;
  color: #0091AF;
}
.select .options li a {
  display: block;
  line-height: 59px;
  border-top: 1px;
  color: #505050;
  border-left: transparent 2px solid;
  padding: 0 20px 0 18px;
  font-size: 16px;
  transition: all 150ms ease;
}

.filter-wrapper {
  position: relative;
  display: inline-block;
}
.filter-wrapper:hover .select-button {
  color: #A71680;
}
.filter-wrapper select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0;
  cursor: pointer;
}
.filter-wrapper .select-button {
  border-bottom: #505050 1px solid;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  transition: all 300ms ease;
  white-space: nowrap;
}
.filter-wrapper .select-button:after {
  content: "";
  display: inline-block;
  white-space: nowrap;
  width: 15px;
  height: 15px;
  background: url(../../img/icons/arrow-gt-lila.svg) no-repeat center center;
  background-size: 11px 11px;
  transform: rotateZ(90deg);
}

@media screen and (min-width: 768px) {
  .contact-information {
    max-width: 300px;
  }
}
.contact-information p {
  font-weight: 500;
}
.contact-information .link.link-arrow-light {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .contact-information .link.link-arrow-light {
    line-height: 27px;
  }
}

.pagination {
  text-align: center;
}
.pagination ul {
  list-style: none;
  display: inline-block;
  margin: 10px 0 13px 0;
}
.pagination ul li {
  float: left;
  margin: 0 25px 0 0;
}
.pagination ul li:last-child {
  margin: 0;
}
.pagination ul li.active a {
  border-color: rgba(80, 80, 80, 0.3);
  color: rgba(80, 80, 80, 0.3);
}
.pagination ul li a {
  display: block;
  width: 45px;
  border-bottom: #A71680 2px solid;
  text-align: center;
  font-size: 16px;
  line-height: 45px;
  font-weight: 400;
  color: #505050;
  transition: all 300ms ease;
}
.pagination ul li a:hover {
  color: #A71680;
}

.tabs {
  max-width: 100%;
  margin: 0 10px 10px 10px;
}
@media screen and (min-width: 768px) {
  .tabs {
    margin: 0;
  }
}
.tabs ul {
  list-style: none;
  width: 100%;
  max-width: 100%;
  background: #FFF;
}
.tabs ul li {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .tabs ul li {
    float: left;
  }
}
.tabs ul li.active {
  background: #F1F2F1;
  color: #d40009;
}
.tabs ul li a {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 54px;
  color: #505050;
  transition: all 300ms ease;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-justify: inter-word;
}
.tabs ul li a:hover {
  color: #d40009;
}
@media screen and (min-width: 768px) {
  .tabs ul li:first-child:nth-last-child(2), .tabs ul li:first-child:nth-last-child(2) ~ li {
    width: 50%;
  }
  .tabs ul li:first-child:nth-last-child(3), .tabs ul li:first-child:nth-last-child(3) ~ li {
    width: 33.3333333333%;
  }
  .tabs ul li:first-child:nth-last-child(4), .tabs ul li:first-child:nth-last-child(4) ~ li {
    width: 25%;
  }
}

table {
  width: 100%;
  text-align: left;
}
table thead th {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #505050;
  padding: 10px 0;
  border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  vertical-align: middle;
}
table thead th:last-child {
  text-align: right;
}
table tbody td {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #505050;
  padding: 10px 0;
  border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  vertical-align: middle;
}
table tbody td:first-child {
  font-weight: 500;
}
table tbody td:last-child {
  text-align: right;
}

/* HEADLINES */
h1, h2, h3, h4, h5 {
  padding: 0;
  font-weight: 500;
  position: relative;
  font-family: Dax, serif;
  color: #505050;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 a, h2 a, h3 a, h4 a, h5 a {
  color: inherit;
}

h1, .h1 {
  font-size: 24px;
  line-height: 1.29;
  letter-spacing: 0;
}
@media screen and (min-width: 1024px) {
  h1, .h1 {
    font-size: 78px;
    /**/
    line-height: 1;
    letter-spacing: 0.015em;
  }
}

h2, .h2 {
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: 43px;
    line-height: 1;
    letter-spacing: -0.035em;
  }
}

h3, .h3 {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: 30px;
    line-height: 1;
  }
}

h4, .h4 {
  font-size: 20px;
  line-height: 1;
}

p, .p {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #505050;
  font-family: Dax, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 768px) {
  p, .p {
    font-size: 17px;
    line-height: 30px;
  }
}
p a, .p a {
  color: #A71680;
}

table {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
}

code {
  font-size: 14px;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
}

ul, ol {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: #12171A;
  -webkit-font-smoothing: subpixel-antialiased;
}

button, input, textarea {
  font-family: Dax, sans-serif;
}

small, .small {
  display: block;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
  color: #505050;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
small.underscore, .small.underscore {
  position: relative;
  padding-left: 15px;
}
small.underscore.underscore-shadow:after, .small.underscore.underscore-shadow:after {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
small.underscore:after, .small.underscore:after {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #033770;
  position: absolute;
  left: 0;
  bottom: 6px;
}
small.underscore.underscore-white:after, .small.underscore.underscore-white:after {
  background: #FFF;
}
small.underscore.underscore-red:after, .small.underscore.underscore-red:after {
  background: #d40009;
}
small.underscore.underscore-blue:after, .small.underscore.underscore-blue:after {
  background: #00489A;
}
small.underscore.underscore-green:after, .small.underscore.underscore-green:after {
  background: #48A019;
}
small.underscore.underscore-purple:after, .small.underscore.underscore-purple:after {
  background: #6B1256;
}
small.underscore.underscore-turquoise:after, .small.underscore.underscore-turquoise:after {
  background: #0091AF;
}
small.underscore_placeholder, .small.underscore_placeholder {
  height: 21px;
}

.red {
  color: #d40009;
}

.ls-normal {
  letter-spacing: normal;
}

.btn {
  -webkit-appearance: none;
  background: #0091AF;
  display: inline-block;
  line-height: 45px;
  padding: 0 25px;
  height: 45px;
  border-radius: 28px;
  border: 0;
  box-shadow: none;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .btn {
    line-height: 55px;
    padding: 0 25px;
    height: 55px;
    font-size: 22px;
  }
}
.btn.btn-arrow {
  padding: 0 20px 0 50px;
  position: relative;
}
.btn.btn-arrow:hover span:after {
  transition: transform 300ms linear;
  transform: translate3d(250%, -50%, 0);
}
.btn.btn-arrow:hover span:before {
  transition: transform 300ms linear;
  transform: translate3d(-50%, -50%, 0);
}
@media screen and (min-width: 768px) {
  .btn.btn-arrow {
    padding: 0 40px 0 75px;
  }
}
.btn.btn-arrow span {
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
  width: 36px;
  height: 36px;
  border: rgba(255, 255, 255, 0.5) 2px solid;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
}
.btn.btn-arrow span:before, .btn.btn-arrow span:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: url(../../img/icons/arrow-gt-white.svg) no-repeat top center;
  background-size: 11px 11px;
  transition: none;
}
.btn.btn-arrow span:before {
  transform: translate3d(-350%, -50%, 0);
}
@media screen and (min-width: 768px) {
  .btn.btn-arrow span {
    width: 45px;
    height: 45px;
  }
}
.btn.btn-red {
  padding: 0 25px 0 60px;
  background: #0091AF;
  color: #FFFFFF;
}
.btn.btn-red span {
  border: #FFFFFF 2px solid;
}
.btn.btn-red span:before, .btn.btn-red span:after {
  background-image: url(../../img/icons/arrow-gt-white.svg);
}
.btn.btn-transparent {
  outline: none;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}
.btn.btn-no-arrow {
  padding: 0 45px;
  transition: all 300ms ease;
}
.btn.btn-no-arrow.btn-red:hover {
  background: rgb(0, 102.7428571429, 124);
}

.link {
  font-weight: 500;
}
.link.link-arrow {
  position: relative;
  display: inline-block;
  color: #0091AF;
  /**/
  font-size: 16px;
  text-transform: uppercase;
  line-height: 36px;
  padding: 0 0 0 47px;
}
.link.link-arrow:hover span:after {
  transition: transform 300ms linear;
  transform: translate3d(250%, -50%, 0);
}
.link.link-arrow:hover span:before {
  transition: transform 300ms linear;
  transform: translate3d(-50%, -50%, 0);
}
@media screen and (min-width: 768px) {
  .link.link-arrow {
    line-height: 45px;
    padding: 0 0 0 66px;
  }
}
.link.link-arrow.link-red {
  color: #0091AF;
}
.link.link-arrow.link-red span {
  border-color: #0091AF;
}
.link.link-arrow.link-red span:before, .link.link-arrow.link-red span:after {
  background-image: url(../../img/icons/arrow-gt-blue.svg);
}
.link.link-arrow span {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border: #0091AF 2px solid;
  border-radius: 50%;
  overflow: hidden;
}
.link.link-arrow span:before, .link.link-arrow span:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: url(../../img/icons/arrow-gt-blue.svg) no-repeat top center;
  background-size: 11px 11px;
  transition: none;
}
.link.link-arrow span:before {
  transform: translate3d(-350%, -50%, 0);
}
@media screen and (min-width: 768px) {
  .link.link-arrow span {
    width: 45px;
    height: 45px;
  }
}
.link.link-arrow-light {
  position: relative;
  display: inline-block;
  color: #F1F2F1;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 23px;
  padding: 11px 0 11px 38px;
}
.link.link-arrow-light:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  width: 22px;
  height: 9px;
  background: url(../../img/icons/arrow-light-lila.svg) no-repeat center center;
  background-size: 22px 11px;
  transition: all 300ms ease;
}
.link.link-arrow-light:hover:after {
  transform: translate3d(8px, 0, 0);
}
.link.link-arrow-light.link-white:after {
  background-image: url(../../img/icons/arrow-light-white.svg);
}
.link.link-arrow-light i {
  color: #656565;
  font-style: normal;
}
.link.link-arrow-gt {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 45px;
  display: inline-block;
  text-transform: uppercase;
  color: #505050;
}
.link.link-arrow-gt:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 11px;
  height: 11px;
  background: url(../../img/icons/arrow-gt-grey.svg) no-repeat center center;
  background-size: 11px 11px;
  transform: translateY(-50%);
  transition: all 300ms ease;
}
.link.link-arrow-gt:hover:after {
  transform: translate3d(8px, -50%, 0);
}
.link.link-red {
  color: #A71680;
}

.sidebar-link-wrapper {
  text-align: right;
}

.flickity-prev-next-button:disabled {
  display: none;
}

.keyvisual.low .flickity-page-dots {
  display: none;
}

.box.box-big {
  padding: 16px 20px 18px 20px;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
}
@media screen and (min-width: 768px) {
  .box.box-big {
    padding: 23px 30px;
  }
}
.box.box-shine {
  background: rgba(255, 255, 255, 0.7);
}
.box.box-shine.less-opacity {
  background: rgba(255, 255, 255, 0.875);
}
@media screen and (min-width: 768px) {
  .box.box-shine {
    padding: 17px 40px 21px 40px;
    background: rgba(255, 255, 255, 0.7);
  }
  .box.box-shine.less-opacity {
    background: rgba(255, 255, 255, 0.875);
  }
}
.box.box-shine h3 {
  color: #000000;
}
.box.box-shine .link.link-red {
  color: #0091AF;
}
.box.box-shine .link.link-red span {
  border-color: #0091AF;
}
.box.box-shine .link.link-red span:before, .box.box-shine .link.link-red span:after {
  background-image: url(../../img/icons/arrow-gt-blue.svg);
}
.box.box-background {
  position: relative;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  overflow: hidden;
}
.box.box-background:hover .box-bg {
  transform: scale(1.1);
  opacity: 0.5;
}
.box.box-background:hover .link.link-arrow span:after {
  transition: transform 300ms linear;
  transform: translate3d(250%, -50%, 0);
}
.box.box-background:hover .link.link-arrow span:before {
  transition: transform 300ms linear;
  transform: translate3d(-50%, -50%, 0);
}
.box.box-background .box-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
  opacity: 0.15;
}
.box.box-background .box-inner {
  position: relative;
  transition: all 250ms ease;
  padding-bottom: 128px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .box.box-background .box-inner {
    padding-bottom: 212px;
  }
}
.box.box-standard-margin small {
  margin: 0 0 32px 0;
}
.box.box-standard-margin h3,
.box.box-standard-margin .h3 {
  margin: 0 0 15px 0;
  text-transform: uppercase;
}
.box.box-standard-margin p {
  margin: 0 0 15px 0;
  line-height: 24px;
}
.box.box-white {
  background: #FFF;
}
.box.box-dark small {
  color: #000000;
  /**/
}
.box.box-dark h3,
.box.box-dark .h3 {
  color: #000000;
}
.box.box-dark p {
  color: #4e4e4e;
}
.box.box-press {
  padding: 15px 20px 3px 20px;
  background: #FFF;
  margin: 40px auto 0 auto;
  border: #DDD 1px solid;
}
.box.box-press small {
  font-size: 16px;
  color: #505050;
  margin: 0 0 15px 0;
}
.box.box-press h4,
.box.box-press .h4 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #0091AF;
  margin: 0 0 15px 0;
}
.box.box-press h4 a,
.box.box-press .h4 a {
  color: inherit;
}
.box.box-press p {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 26px 0;
}
.box.box-sidebar {
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .box.box-sidebar {
    margin-top: 44px;
  }
}
@media screen and (min-width: 768px) {
  .box.box-sidebar {
    margin-top: 64px;
  }
}
.box.box-sidebar:first-child {
  margin-top: 0;
}
.box .box-inner {
  padding: 25px 20px;
}

.overlay-light {
  background-color: rgba(255, 255, 255, 0.8);
}

.overlay-dark {
  background-color: rgba(0, 145, 175, 0.25);
}

#header {
  position: fixed;
  top: 0;
  left: 58px;
  width: calc(100% - 58px);
  height: 56px;
  background: rgba(255, 255, 255, 0.7);
  z-index: 5009;
  transition: height 300ms ease;
}
@media screen and (min-width: 768px) {
  #header {
    left: 107px;
    width: calc(100% - 107px);
  }
}
@media screen and (min-width: 1920px) {
  #header {
    width: 1813px;
    left: 50%;
    transform: translateX(-853px);
  }
}
#header.small {
  height: 42px;
}
#header.small #toggle-nav {
  height: 42px;
}
#header.small #logo-wrapper {
  height: 42px;
}
#header.small #logo-wrapper a {
  padding: 4px 0;
}
#header.small #context-nav > ul > li {
  width: 42px;
  height: 42px;
}
#header.small #context-nav > ul > li > a {
  transform: scale(0.75);
  transform-origin: 0 0;
}
#header.small #context-nav > ul > li.context-some {
  width: 30px;
}
#header.small #context-nav > ul > li.context-some.facebook {
  margin-left: 30px;
  width: 24px;
}
#header.header-lp {
  width: 100%;
  left: 0;
  transform: none;
}
#header.header-lp #logo-wrapper {
  margin-right: 0;
}

#header-spacer {
  height: 56px;
}

#toggle-nav {
  overflow: hidden;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0 0 0 -58px;
  border: 0;
  background: rgba(255, 255, 255, 0.7);
  float: left;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
}
#toggle-nav:hover {
  background: rgba(255, 255, 255, 0.9);
}
@media screen and (min-width: 768px) {
  #toggle-nav {
    width: 105px;
    margin: 0 0 0 -107px;
  }
}
#toggle-nav.nav-open > div {
  width: 0;
}
#toggle-nav.nav-open > div .top {
  animation: toggle-nav-top-to-x 600ms ease forwards;
}
#toggle-nav.nav-open > div .bottom {
  animation: toggle-nav-bottom-to-x 600ms ease forwards;
}
#toggle-nav span {
  margin-left: -9999px;
}
#toggle-nav > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 2px;
  background: #000000;
  transform: translate3d(-50%, -50%, 0);
  transition: all 600ms ease;
}
#toggle-nav > div > div {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 2px;
  background: #000000;
  transform: translate3d(-50%, -10px, 0);
  margin: -1px 0 0 0;
  transition: all 600ms ease;
}
#toggle-nav > div .top {
  animation: toggle-nav-top-to-burger 600ms ease forwards;
}
#toggle-nav > div .bottom {
  animation: toggle-nav-bottom-to-burger 600ms ease forwards;
  transform: translate3d(-50%, 10px, 0);
}

@keyframes toggle-nav-top-to-x {
  0% {
    transform: translate3d(-50%, -10px, 0);
  }
  50% {
    transform: translate3d(-50%, -5px, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0) rotateZ(45deg);
  }
}
@keyframes toggle-nav-top-to-burger {
  0% {
    transform: translate3d(-50%, 0px, 0) rotateZ(45deg);
  }
  50% {
    transform: translate3d(-50%, -5px, 0);
  }
  100% {
    transform: translate3d(-50%, -10px, 0);
  }
}
@keyframes toggle-nav-bottom-to-x {
  0% {
    transform: translate3d(-50%, 10px, 0);
  }
  50% {
    transform: translate3d(-50%, 5px, 0);
  }
  100% {
    transform: translate3d(-50%, 0px, 0) rotateZ(-45deg);
  }
}
@keyframes toggle-nav-bottom-to-burger {
  0% {
    transform: translate3d(-50%, 0, 0) rotateZ(-45deg);
  }
  50% {
    transform: translate3d(-50%, 5px, 0);
  }
  100% {
    transform: translate3d(-50%, 10px, 0);
  }
}
#context-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
#context-nav > ul {
  list-style: none;
  padding: 0;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul {
    margin: 0 30px 0 0;
  }
}
#context-nav > ul > li {
  position: relative;
  float: left;
  width: 56px;
  height: 56px;
  margin-left: 15px;
  transition: all 300ms ease;
}
#context-nav > ul > li.context-some {
  display: none;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul > li.context-some {
    display: block;
    width: 30px;
    margin-left: 10px;
  }
  #context-nav > ul > li.context-some.facebook {
    margin-left: 20px;
    width: 24px;
  }
}
#context-nav > ul > li.context-some > a {
  background-image: none;
  position: relative;
  width: 100%;
}
#context-nav > ul > li.context-some > a:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  height: 30px;
  width: 30px;
  background: url(../../img/icons/facebook_t.svg) 0 0 no-repeat;
  background-size: contain;
}
#context-nav > ul > li.context-some.instagram > a:before {
  top: 13px;
  background-image: url(../../img/icons/instagram_t.svg);
}
#context-nav > ul > li.context-some.linkedin > a:before {
  top: 12px;
  background-image: url(../../img/icons/linkedin_t.svg);
}
#context-nav > ul > li.context-some.xing > a:before {
  background-image: url(../../img/icons/xing_t.svg);
}
#context-nav > ul > li.context-some.mail > a:before {
  top: 16px;
  background-image: url(../../img/icons/mail_b.svg);
}
#context-nav > ul > li.context-language {
  display: none;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul > li.context-language {
    display: block;
  }
}
#context-nav > ul > li.context-language > a {
  background-image: none;
  position: relative;
  color: #000000;
  margin-left: -7px;
}
#context-nav > ul > li.context-language > a span {
  visibility: visible;
  position: absolute;
  top: 24%;
  left: 40px;
}
#context-nav > ul > li.context-language > a span:after {
  content: "›";
  position: absolute;
  top: 7%;
  left: 7%;
  transform: rotate(90deg);
  font-size: 1.8rem;
}
#context-nav > ul > li.context-language > a:before {
  content: "";
  position: absolute;
  top: 26%;
  left: 10px;
  height: 45%;
  width: 45%;
  background-image: url(../../img/icons/context-globe-black.svg);
  background-position: center center;
  background-repeat: no-repeat;
}
#context-nav > ul > li.context-extranet {
  display: none;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul > li.context-extranet {
    display: block;
  }
}
#context-nav > ul > li.context-extranet > a {
  background-image: url(../../img/icons/context-account.svg);
}
#context-nav > ul > li.context-contact {
  display: none;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul > li.context-contact {
    display: block;
  }
}
#context-nav > ul > li.context-contact > a {
  background-image: url(../../img/icons/mercator-black.svg);
  background-size: 38px 38px;
}
#context-nav > ul > li > a {
  display: block;
  height: 56px;
  width: 56px;
  background: url(../../img/icons/context-magnifier-black.svg) no-repeat center center;
  background-size: 25px 25px;
  transition: all 300ms ease;
}
#context-nav > ul > li > a span {
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
#context-nav > ul > li.context-career {
  display: none;
}
@media screen and (min-width: 1024px) {
  #context-nav > ul > li.context-career {
    display: block;
  }
}
#context-nav > ul > li.context-career > a {
  background-image: url(../../img/icons/career.svg);
  background-size: 24px 24px;
}

#nav-language {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  display: none;
}
#nav-language.visible {
  display: block;
}
#nav-language.visible + .nav-language-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1900;
}
#nav-language ul {
  list-style: none;
  padding: 20px 30px;
}
#nav-language ul li a {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #505050;
  transition: all 300ms ease;
}
#nav-language ul li a:hover {
  color: #A71680;
}

#logo-wrapper {
  text-align: center;
  height: 56px;
  margin-right: 58px;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  #logo-wrapper {
    margin-right: 107px;
  }
}
#logo-wrapper a {
  display: block;
  margin: 0 auto;
  width: 120px;
  padding: 11px 0;
  transition: all 300ms ease;
}
#logo-wrapper img {
  width: 120px;
  height: 34px;
  margin: 0 auto;
}

#main-nav {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  background: rgba(0, 0, 0, 0.92);
  z-index: 5000;
  display: none;
  opacity: 0;
  transition: all 400ms ease-out;
}
#main-nav.visible {
  opacity: 1;
}
@media screen and (min-width: 1024px) {
  #main-nav {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
#main-nav .breadcrumb {
  margin: 42px 0 0 0;
  display: none;
}
@media screen and (min-width: 1024px) {
  #main-nav .breadcrumb {
    display: block;
  }
}
#main-nav .breadcrumb ul {
  list-style: none;
}
#main-nav .breadcrumb ul li {
  float: left;
  font-size: 20px;
  color: #808080;
  line-height: 1;
  font-weight: 300;
  margin-right: 6px;
  text-transform: uppercase;
}
#main-nav .breadcrumb ul li a {
  color: #FFF;
}
#main-nav .breadcrumb ul li a:hover {
  color: #0091AF;
}
@media screen and (min-width: 1024px) {
  #main-nav .context-mobile {
    display: none;
  }
}
#main-nav .context-mobile ul {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}
#main-nav .context-mobile ul li {
  float: left;
  width: 56px;
  height: 56px;
}
#main-nav .context-mobile ul li a {
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  height: 56px;
  width: 56px;
  background: url(../../img/icons/mercator-blue.svg) no-repeat right center;
  background-size: 36px 36px;
}
#main-nav .context-mobile ul li .select-wrapper {
  width: 56px;
  height: 56px;
  margin-left: 10px;
  background: url(../../img/icons/context-globe-blue.svg) no-repeat center center;
  background-size: 25px 25px;
}
#main-nav .context-mobile ul li .select-wrapper select {
  -webkit-appearance: none;
  width: 56px;
  height: 56px;
  border-radius: 0;
  opacity: 0;
}
#main-nav .context-mobile ul li.context-some {
  display: block;
  width: 30px;
  margin-left: 5px;
}
#main-nav .context-mobile ul li.context-some.facebook {
  margin-left: 20px;
  width: 24px;
}
#main-nav .context-mobile ul li.context-some > a {
  background-image: none;
  position: relative;
  color: #0091AF;
}
#main-nav .context-mobile ul li.context-some > a:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  height: 30px;
  width: 30px;
  background: url(../../img/icons/facebook_t.svg) 0 0 no-repeat;
  background-size: contain;
}
#main-nav .context-mobile ul li.context-some.instagram > a:before {
  top: 13px;
  background-image: url(../../img/icons/instagram_t.svg);
}
#main-nav .context-mobile ul li.context-some.linkedin > a:before {
  top: 12px;
  background-image: url(../../img/icons/linkedin_t.svg);
}
#main-nav .context-mobile ul li.context-some.xing > a:before {
  background-image: url(../../img/icons/xing_t.svg);
}
#main-nav .context-mobile ul li.context-some.mail > a:before {
  top: 16px;
  background-image: url(../../img/icons/mail_w.svg);
}
#main-nav .scroller {
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  height: calc(100vh - 56px - 56px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
}
@media screen and (min-width: 1024px) {
  #main-nav .scroller {
    position: static;
    left: auto;
    top: auto;
    height: auto;
    overflow: visible;
    transform: none;
  }
}
#main-nav .nav {
  padding-bottom: 20px;
}
#main-nav .first-level {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  #main-nav .first-level {
    margin: 17px 0 0 0;
  }
}
#main-nav .first-level ul {
  list-style: none;
  counter-reset: nav-count;
}
#main-nav .first-level ul li.active a {
  color: #0091AF;
}
@media screen and (min-width: 1024px) {
  #main-nav .first-level ul li.active a {
    padding: 0 0 0 100px;
  }
  #main-nav .first-level ul li.active a:before {
    background: #0091AF;
  }
  #main-nav .first-level ul li.active a:after {
    color: #0091AF;
  }
  #main-nav .first-level ul li.active a:hover {
    padding: 0 0 0 103px;
  }
  #main-nav .first-level ul li.active a:hover:before {
    width: 33px;
  }
  #main-nav .first-level ul li.active a:before {
    left: 20px;
    width: 30px;
  }
  #main-nav .first-level ul li.active a:after {
    left: 55px;
  }
}
#main-nav .first-level ul li a {
  display: block;
  position: relative;
  padding: 0 20px;
  font-size: 20px;
  line-height: 55px;
  font-weight: 300;
  transition: all 300ms ease;
  transform: translate3d(0, 0, 0);
  color: #FFF;
}
#main-nav .first-level ul li a:before {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
  background-size: 11px 11px;
  position: absolute;
  top: 50%;
  right: 20px;
  transition: all 300ms ease;
  transform: translateY(-50%);
}
#main-nav .first-level ul li a:hover {
  color: #0091AF;
}
@media screen and (min-width: 1024px) {
  #main-nav .first-level ul li a {
    padding: 0 0 0 60px;
    font-size: 40px;
    line-height: 70px;
  }
  #main-nav .first-level ul li a:hover {
    padding: 0 0 0 65px;
  }
  #main-nav .first-level ul li a:hover:before {
    width: 15px;
    background: #0091AF;
  }
  #main-nav .first-level ul li a:hover:after {
    transform: translateX(5px);
    color: #0091AF;
  }
  #main-nav .first-level ul li a:before {
    width: 10px;
    height: 2px;
    background: #FFFFFF;
    top: 47px;
    right: auto;
    left: 0;
    transform: none;
  }
  #main-nav .first-level ul li a:after {
    counter-increment: nav-count;
    content: "0" counter(nav-count);
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #FFFFFF;
    position: absolute;
    top: 36px;
    left: 15px;
    transition: all 300ms ease;
  }
}
#main-nav .sub-level {
  margin: 0;
  display: none;
}
@media screen and (min-width: 1024px) {
  #main-nav .sub-level {
    margin: 27px 0 0 0;
  }
}
#main-nav .sub-level.visible {
  display: block;
}
#main-nav .sub-level ul {
  list-style: none;
  counter-reset: nav-count;
}
#main-nav .sub-level ul li.active a {
  color: #0091AF;
}
@media screen and (min-width: 1024px) {
  #main-nav .sub-level ul li.active a {
    padding-left: 48px;
  }
  #main-nav .sub-level ul li.active a:before {
    opacity: 1;
    transform: scaleX(1);
  }
}
#main-nav .sub-level ul li.back a {
  padding-left: 40px;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  font-size: 16px;
}
#main-nav .sub-level ul li.back a:hover:before {
  background-image: url(../../img/icons/arrow-gt-blue.svg);
}
#main-nav .sub-level ul li.back a:before {
  right: auto;
  left: 15px;
  background-image: url(../../img/icons/arrow-gt-white.svg);
  transform: translateY(-50%) rotateZ(180deg);
}
@media screen and (min-width: 1024px) {
  #main-nav .sub-level ul li.back a {
    display: none;
  }
}
#main-nav .sub-level ul li.has-sub-nav > a:before {
  width: 12px;
  height: 12px;
  background-image: url(../../img/icons/plus-blue.svg);
  background-size: 12px 12px;
}
#main-nav .sub-level ul li.has-sub-nav > a.is-opened:before {
  background-image: url(../../img/icons/minus-blue.svg);
}
#main-nav .sub-level ul li a {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  color: #FFF;
  transition: all 300ms ease;
  padding: 15px 30px 15px 20px;
}
#main-nav .sub-level ul li a:hover {
  color: #0091AF;
}
#main-nav .sub-level ul li a:before {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
  background-size: 11px 11px;
  position: absolute;
  top: 50%;
  right: 20px;
  transition: all 300ms ease;
  transform: translateY(-50%);
}
@media screen and (min-width: 1024px) {
  #main-nav .sub-level ul li a {
    font-size: 26px;
    line-height: 30px;
    padding: 15px 52px 15px 0;
    color: #bab9b9;
  }
  #main-nav .sub-level ul li a:hover {
    padding-left: 12px;
    padding-right: 40px;
  }
}
#main-nav .third-level {
  display: none;
}
@media screen and (min-width: 768px) {
  #main-nav .third-level {
    margin: 0;
  }
}
#main-nav .third-level ul li {
  padding-left: 20px;
}
#main-nav .third-level ul li a {
  font-size: 18px;
  line-height: 20px;
}
#main-nav .main-nav-bottom {
  background: #FFF;
  display: none;
}
@media screen and (min-width: 1024px) {
  #main-nav .main-nav-bottom {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  #main-nav .main-nav-bottom .search {
    padding-top: 42px;
  }
}

#header-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  z-index: 5009;
  transition: all 400ms ease-out;
  display: none;
  opacity: 0;
}

#header-search-close {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
}
#header-search-close:hover div:before, #header-search-close:hover div:after {
  background: #505050;
}
#header-search-close span {
  margin-left: -9999px;
}
#header-search-close div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 2px;
  background: transparent;
  transform: translate3d(-50%, -50%, 0);
}
#header-search-close div:before, #header-search-close div:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 2px;
  background: #d40009;
  transform: translate3d(-50%, -50%, 0);
  margin: -10px 0 0 0;
  transition: all 300ms ease;
}
#header-search-close div:before {
  transform: translate3d(-50%, calc(-50% + 10px), 0) rotateZ(45deg);
}
#header-search-close div:after {
  margin: 10px 0 0 0;
  transform: translate3d(-50%, calc(-50% - 10px), 0) rotateZ(-45deg);
}

#footer {
  padding: 180px 0 0 0;
  background: url(../../img/bg/footer-bg-new.svg) no-repeat top right;
  background-size: 100%;
  -webkit-box-shadow: 0 0 10px 0 rgba(153, 153, 153, 0.8);
  box-shadow: 0 0 10px 0 rgba(153, 153, 153, 0.8);
}
@media screen and (min-width: 768px) {
  #footer {
    padding: 150px 0;
    background-size: 35%;
  }
  #footer.with-footer-logos {
    padding: 150px 0 0 0;
  }
}
#footer .logo-wrapper {
  display: block;
  margin: 0 auto 68px auto;
  text-align: center;
  max-width: 170px;
}
@media screen and (min-width: 768px) {
  #footer .logo-wrapper {
    max-width: 220px;
    margin: 0 auto 32px auto;
  }
}
@media screen and (min-width: 1024px) {
  #footer .logo-wrapper {
    max-width: none;
    margin: 7px 0 0 0;
  }
}
#footer .logo-wrapper small {
  text-transform: none;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  #footer .logo-wrapper small {
    font-size: 15px;
  }
}
#footer .nav-wrapper {
  text-align: center;
}
@media screen and (min-width: 768px) {
  #footer .nav-wrapper {
    background: transparent;
    text-align: left;
  }
}
#footer .nav-wrapper.nav-wapper-first {
  padding-top: 32px;
}
@media screen and (min-width: 768px) {
  #footer .nav-wrapper.nav-wapper-first {
    padding-top: 0;
  }
}
#footer .nav-footer-top ul {
  list-style: none;
}
#footer .nav-footer-top ul li {
  padding: 0 0 0 30px;
}
@media screen and (min-width: 1024px) {
  #footer .nav-footer-top ul li {
    padding: 0;
  }
}
#footer .nav-footer-top ul li a {
  display: inline-block;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  padding: 10px 0;
  font-weight: 400;
  color: #505050;
  transition: all 300ms ease;
}
#footer .nav-footer-top ul li a:hover {
  color: #0091AF;
}
#footer .nav-footer-top ul li a:hover:after {
  transform: translate3d(8px, 0, 0);
}
#footer .nav-footer-top ul li a:after {
  content: "";
  position: absolute;
  top: 14px;
  left: -20px;
  display: block;
  width: 11px;
  height: 11px;
  background: url(../../img/icons/arrow-gt-blue.svg) no-repeat top center;
  background-size: 11px 11px;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  #footer .nav-footer-top ul li a {
    display: block;
  }
}
#footer.with-footer-logos .nav-footer-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem auto;
}
#footer.with-footer-logos .nav-footer-center .footer-logo-wrapper {
  max-width: 150px;
  padding: 0 -20px;
}
#footer.with-footer-logos .nav-footer-center .footer-logo-wrapper img {
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  #footer.with-footer-logos .nav-footer-center {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8.3333333333%;
    margin-right: inherit;
  }
}
#footer .footer-breadcrumb-wrapper {
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  #footer .footer-breadcrumb-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
#footer .footer-breadcrumb ul {
  list-style: none;
}
#footer .footer-breadcrumb ul li {
  float: left;
  color: #505050;
}
@media screen and (min-width: 768px) {
  #footer .footer-breadcrumb ul li:first-child a {
    padding-left: 0;
  }
}
#footer .footer-breadcrumb ul li a {
  display: inline-block;
  color: #505050;
  font-size: 16px;
  transition: all 300ms ease;
  font-weight: 500;
}
#footer .footer-breadcrumb ul li a:hover {
  color: #0091AF;
}
#footer.editmode .footer-logo-wrapper img {
  min-width: 150px;
  height: auto;
}

.footer-bottom {
  margin: 0;
  background: #FFF;
}
@media screen and (min-width: 768px) {
  .footer-bottom {
    margin: 18px 0 0 0;
  }
}
.footer-bottom .nav-footer-bottom ul {
  list-style: none;
}
.footer-bottom .nav-footer-bottom ul li {
  float: left;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  color: #505050;
  padding: 0;
  margin-right: 15px;
  letter-spacing: 0.05em;
}
.footer-bottom .nav-footer-bottom ul li:first-child {
  float: none;
}
@media screen and (min-width: 768px) {
  .footer-bottom .nav-footer-bottom ul li {
    padding: 8px 0;
    font-size: 16px;
  }
  .footer-bottom .nav-footer-bottom ul li:first-child {
    float: left;
  }
}
@media screen and (min-width: 1024px) {
  .footer-bottom .nav-footer-bottom ul li {
    margin-right: 35px;
  }
}
.footer-bottom .nav-footer-bottom ul li a {
  color: inherit;
  transition: all 300ms ease;
  letter-spacing: 0;
}
.footer-bottom .nav-footer-bottom ul li a:hover {
  color: #0091AF;
}
.footer-bottom.lp-footer {
  -webkit-box-shadow: 0 0 10px 0 rgba(153, 153, 153, 0.8);
  box-shadow: 0 0 10px 0 rgba(153, 153, 153, 0.8);
}
@media screen and (min-width: 768px) {
  .footer-bottom.lp-footer {
    padding: 10px 0;
  }
}

.keyvisual {
  background: #505050;
  border-top: #A71680 4px solid;
  border-bottom: #A71680 6px solid;
  min-height: 49vw;
}
@media screen and (min-width: 1920px) {
  .keyvisual {
    min-height: 950px;
  }
}
.keyvisual .flickity-viewport {
  transition: height 300ms ease;
}
.keyvisual .flickity-page-dots {
  top: 100%;
  height: 45px;
  margin: 9px 0 0 0;
  counter-reset: dot-count;
  display: none;
}
@media screen and (min-width: 1024px) {
  .keyvisual .flickity-page-dots {
    display: block;
  }
}
.keyvisual .flickity-page-dots .dot {
  width: 45px;
  height: 50px;
  line-height: 48px;
  opacity: 1;
  background: transparent;
  border: 0;
  border-bottom: #d40009 2px solid;
  border-radius: 0;
  color: #FFF;
  margin: 0 12px;
  transition: all 300ms ease;
}
.keyvisual .flickity-page-dots .dot:after {
  counter-increment: dot-count;
  content: "0" counter(dot-count);
  pointer-events: none;
}
.keyvisual .flickity-page-dots .dot.is-selected {
  color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}
.keyvisual .cell {
  width: 100%;
}
.keyvisual .cell .cell-inner {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 1024px) {
  .keyvisual .cell .cell-inner {
    height: 0;
  }
  .keyvisual .cell .cell-inner .con {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .keyvisual .cell .cell-inner {
    padding: 49% 0 0 0;
  }
}
.keyvisual .box {
  margin: 196px 0 30px 0;
  opacity: 0;
}
.keyvisual .box:first-child {
  opacity: 1;
}
@media screen and (min-width: 1024px) {
  .keyvisual .box {
    margin: 0 0 30px 0;
  }
  .keyvisual .box h1 {
    margin-bottom: 26px;
  }
}
.keyvisual .box h1 {
  color: #d40009;
  margin-bottom: 20px;
  font-weight: 400;
}
.keyvisual .box p {
  margin-bottom: 18px;
}

.animated-hero {
  background: #505050;
  border-top: #A71680 4px solid;
  border-bottom: #A71680 6px solid;
  min-height: 49vw;
}
@media screen and (min-width: 1920px) {
  .animated-hero {
    min-height: 950px;
  }
}
.animated-hero .cell {
  width: 100%;
  position: relative;
}
.animated-hero .cell .cell-inner {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 1024px) {
  .animated-hero .cell .cell-inner {
    height: 0;
  }
  .animated-hero .cell .cell-inner .con {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .animated-hero .cell .cell-inner {
    padding: 49% 0 0 0;
  }
}
.animated-hero .box {
  margin: 196px 0 30px 0;
  opacity: 0;
}
.animated-hero .box:first-child {
  opacity: 1;
}
@media screen and (min-width: 1024px) {
  .animated-hero .box {
    margin: 0 0 30px 0;
  }
  .animated-hero .box h1 {
    margin-bottom: 26px;
  }
}
.animated-hero .box h1 {
  color: #000000;
  margin-bottom: 20px;
  font-weight: 400;
}
.animated-hero .box p {
  margin-bottom: 18px;
}
.animated-hero .wrap-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}
.animated-hero video {
  display: inline-block;
  vertical-align: baseline;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 1022.98px) {
  .keyvisual .flickity-viewport {
    min-height: 49vw;
  }
  .keyvisual .cell .cell-inner {
    min-height: 49vw;
  }
}
.teasers {
  padding: 30px 0 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 768px) {
  .teasers {
    padding: 115px 0 60px 0;
  }
}
.teasers.teasers-alternative {
  padding: 0 0 40px 0;
}
@media screen and (min-width: 768px) {
  .teasers.teasers-alternative {
    margin: 77px 0 0 0;
    padding: 0 0 97px 0;
  }
}
.teasers.teasers-alternative h3 {
  margin: 35px 0 45px 0;
}
.teasers.teasers-alternative .box .box-inner {
  padding-bottom: 156px;
}
.teasers.teasers-alternative .box .link {
  bottom: 25px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .teasers.teasers-alternative .box .link {
    bottom: 25px;
    margin: 0;
  }
}
.teasers.teasers-alternative.teaser-lp {
  border-bottom: solid 8px #0091AF;
}
.teasers.teasers-alternative.layover {
  position: relative;
}
.teasers.teasers-alternative.layover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 145, 175, 0.35);
}
.teasers.teasers-alternative .with-teaser {
  padding: 50px 10px 0;
}
@media screen and (min-width: 768px) {
  .teasers.teasers-alternative .with-teaser {
    padding: 140px 15px 0;
  }
}
.teasers.teasers-alternative .single-teaser {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 30px 10px;
}
.teasers.teasers-alternative .single-teaser h2 {
  margin: 0 0 45px 0;
}
.teasers.teasers-alternative .single-teaser h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 85px;
  height: 4px;
  transform: translateX(-50%);
  background: #0091AF;
  margin: 12px 0 0 0;
}
.teasers.teasers-alternative .single-teaser .btn-arrow {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .teasers.teasers-alternative .single-teaser {
    padding: 90px 90px 60px;
  }
  .teasers.teasers-alternative .single-teaser h2 {
    margin: 0 0 50px 0;
  }
  .teasers.teasers-alternative .single-teaser h2:after {
    margin: 18px 0 0 0;
  }
}
.teasers .xs-carousel {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}
@media screen and (max-width: 1023px) {
  .teasers .xs-carousel {
    width: 100%;
    padding: 0 15px;
    display: block;
    max-width: none;
  }
}
@media screen and (max-width: 1023px) {
  .teasers .col-xs-12, .teasers .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .teasers .col-xs-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.teasers .flickity-viewport {
  overflow: visible;
}
.teasers .flickity-prev-next-button {
  border-radius: 0;
  width: 30px;
  height: 45px;
  background: rgba(255, 255, 255, 0.25);
}
.teasers .flickity-prev-next-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  background: url(../../img/icons/arrow-gt-red.svg) no-repeat center center;
  background-size: 22px 22px;
  transform: translate3d(-50%, -50%, 0);
}
.teasers .flickity-prev-next-button.next {
  right: 0;
}
.teasers .flickity-prev-next-button.previous {
  left: 0;
  transform: rotateZ(180deg) translateY(50%);
}
.teasers .flickity-prev-next-button svg {
  display: none;
}
.teasers .box .link {
  margin: 0 0 20px 0;
  position: absolute;
  bottom: 45px;
  left: 20px;
  right: 20px;
}
@media screen and (min-width: 1024px) {
  .teasers .box .link {
    bottom: 60px;
  }
}
.teasers .select {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 1024px) {
  .teasers .select button {
    height: 57px;
    line-height: 57px;
  }
}

.quotes {
  padding: 30px 0 0 0;
  background: #FFFFFF url(../../img/bg/quotes-bg_new.png) no-repeat center center;
  text-align: center;
  border-bottom: 6px solid #0091AF;
}
@media screen and (min-width: 768px) {
  .quotes {
    padding: 34px 0 0 0;
  }
}
.quotes .flickity-page-dots {
  bottom: 38px;
  counter-reset: dot-count;
}
@media screen and (min-width: 768px) {
  .quotes .flickity-page-dots {
    bottom: 30px;
  }
}
.quotes .flickity-page-dots .dot {
  width: 45px;
  height: 2px;
  opacity: 1;
  background: rgb(235.4444444444, 106.5555555556, 200.7777777778);
  border: 0;
  border-radius: 0;
  color: white;
  margin: 0 12px;
  transition: all 300ms ease;
  text-indent: 1rem;
}
.quotes .flickity-page-dots .dot.is-selected {
  background: #A71680;
}
.quotes .cell {
  width: 100%;
  padding: 0 0 58px 0;
}
.quotes .cell:first-child h3 {
  opacity: 1;
}
.quotes .cell:first-child .author {
  opacity: 1;
}
.quotes .title {
  text-transform: uppercase;
  color: #000000;
  margin: 0 40px 17px 40px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .quotes .title {
    margin: 0 0 5px 0;
  }
}
.quotes h3 {
  color: #0091AF;
  margin: 0 20px 9px 20px;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.08em;
  font-weight: 500;
  font-style: italic;
  opacity: 0;
}
@media screen and (min-width: 1024px) {
  .quotes h3 {
    margin: 9px 0;
    font-size: 39px;
    line-height: 60px;
  }
}
.quotes .author {
  font-size: 17px;
  letter-spacing: 0.02em;
  margin: 0 30px;
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .quotes .author {
    font-size: 20px;
    margin: 0;
  }
}

.reference {
  background: #35465c;
  background: linear-gradient(to bottom, #35465c 0%, #0b0b1c 100%);
}
@media screen and (min-width: 768px) {
  .reference + .share {
    margin-top: 134px;
  }
}
@media screen and (min-width: 768px) {
  .reference .carousel {
    margin-bottom: 50px;
  }
}
.reference .cell {
  padding: 25px 0 32px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .reference .cell {
    padding: 60px 0 12px 0;
  }
}
.reference .flickity-slider .cell {
  position: absolute;
}
.reference .flickity-page-dots {
  top: 100%;
  height: 45px;
  margin: 7px 0 0 0;
  counter-reset: dot-count;
  display: none;
  z-index: 500;
}
@media screen and (min-width: 768px) {
  .reference .flickity-page-dots {
    display: block;
  }
}
.reference .flickity-page-dots .dot {
  width: 45px;
  height: 50px;
  line-height: 48px;
  opacity: 1;
  background: transparent;
  border: 0;
  border-bottom: #A71680 2px solid;
  border-radius: 0;
  color: #A71680;
  margin: 0 12px;
  transition: all 300ms ease;
}
.reference .flickity-page-dots .dot:after {
  counter-increment: dot-count;
  content: "0" counter(dot-count);
  pointer-events: none;
}
.reference .flickity-page-dots .dot.is-selected {
  color: rgb(235.4444444444, 106.5555555556, 200.7777777778);
  border-color: rgb(235.4444444444, 106.5555555556, 200.7777777778);
}
.reference .figure-wrapper {
  margin: -28px 0 18px 0;
}
@media screen and (min-width: 768px) {
  .reference .figure-wrapper {
    display: none;
  }
}
.reference .figure-wrapper figure {
  padding: 54% 0 0 0;
}
.reference .box.box-standard-margin h3, .reference .box.box-standard-margin p {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .reference .box.box-standard-margin h3, .reference .box.box-standard-margin p {
    margin-left: 0;
    margin-right: 0;
  }
}
.reference p {
  font-weight: 300;
  color: #a7a6a6;
}
.reference .link.link-arrow {
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .reference .link.link-arrow {
    margin-top: 2px;
    margin-bottom: 0;
  }
}
.reference .link.link-arrow-light {
  padding-left: 57px;
}
.reference .link.link-arrow-light:after {
  display: none;
}
@media screen and (min-width: 768px) {
  .reference .link.link-arrow-light {
    float: right;
    padding-left: 38px;
  }
  .reference .link.link-arrow-light:after {
    display: block;
  }
}

.teaser-big {
  padding: 25px 0 32px 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .teaser-big {
    padding: 55px 0 50px 0;
  }
}
.teaser-big h2 {
  position: relative;
  text-transform: uppercase;
  color: #000000;
  margin: 0 0 45px 0;
}
.teaser-big h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 85px;
  height: 4px;
  transform: translateX(-50%);
  background: #A71680;
  margin: 12px 0 0 0;
}
@media screen and (min-width: 768px) {
  .teaser-big h2 {
    margin: 0 0 50px 0;
  }
  .teaser-big h2:after {
    margin: 18px 0 0 0;
  }
}
.teaser-big p {
  line-height: 24px;
  margin: 0 0 13px 0;
}
@media screen and (min-width: 768px) {
  .teaser-big p {
    margin: 0 0 33px 0;
  }
}

.teasers-light {
  padding: 3px 0 32px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.teasers-light .col-xs-12 {
  padding-top: 27px;
}
@media screen and (min-width: 768px) {
  .teasers-light {
    padding: 52px 0 91px 0;
  }
  .teasers-light .col-xs-12 {
    padding-top: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .teasers-light {
    padding: 32px 0 91px 0;
  }
  .teasers-light .col-xs-12 {
    padding-top: 50px;
  }
}
.teasers-light .box {
  position: relative;
  padding-bottom: 54px;
  height: 100%;
}
.teasers-light .box small {
  margin: 0 0 10px 0;
}
.teasers-light .box > small {
  padding-top: 16px;
  margin-left: 10px;
  margin-bottom: 15px;
  letter-spacing: 0.02em;
}
.teasers-light .box h3,
.teasers-light .box .h3 {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #0091AF;
  letter-spacing: 0;
}
.teasers-light .box .link {
  margin-top: 15px;
}
@media screen and (min-width: 1024px) {
  .teasers-light .box .link {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.teasers-light .box .link.link-red {
  color: #0091AF;
}
.teasers-light .box .link.link-red span {
  border-color: #0091AF;
}
.teasers-light .box .link.link-red span:before, .teasers-light .box .link.link-red span:after {
  background-image: url(../../img/icons/arrow-gt-blue.svg);
}
@media screen and (max-width: 1023px) {
  .teasers-light .box {
    padding-bottom: 10px;
  }
  .teasers-light .box .box-inner {
    padding: 14px 10px;
  }
  .teasers-light .box .box-inner p {
    line-height: 30px;
  }
}
.teasers-light > .con > .row {
  justify-content: center;
}

.background-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.figure-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
}
.figure-wrapper:hover:after {
  opacity: 1;
}
.figure-wrapper:hover figure {
  transform: scale(1.1);
}
.figure-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  opacity: 0;
  transition: all 300ms ease;
}
.figure-wrapper figure {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 0;
  padding: 53.333333333% 0 0 0;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  .figure-wrapper figure {
    padding: 43.25% 0 0 0;
  }
}
.figure-wrapper figure img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.teasers-white {
  padding: 10px 0 32px 0;
  background: #F1F2F1;
}
@media screen and (min-width: 768px) {
  .teasers-white {
    padding: 12px 0 112px 0;
  }
}
.teasers-white .col-xs-12 {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .teasers-white .col-xs-12 {
    margin-top: 70px;
  }
}
.teasers-white .box {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #0091AF;
}
@media screen and (min-width: 1440px) {
  .teasers-white .box {
    padding-bottom: 54px;
  }
  .teasers-white .box.box-shares {
    padding-bottom: 21px;
  }
}
.teasers-white .box .figure-wrapper figure {
  padding: 45% 0 0 0;
}
.teasers-white .box .box-inner {
  padding: 17px 10px 4px 10px;
}
@media screen and (min-width: 768px) {
  .teasers-white .box .box-inner {
    padding: 19px 20px 5px 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1440px) {
  .teasers-white .box .box-inner {
    padding: 19px 20px 25px 20px;
  }
}
.teasers-white .box small {
  background: #0091AF;
  color: #FFFFFF;
  padding: 14px 10px 14px 10px;
}
@media screen and (min-width: 768px) {
  .teasers-white .box small {
    padding: 14px 10px 14px 10px;
  }
}
@media screen and (min-width: 1024px) {
  .teasers-white .box small {
    padding: 14px 10px 14px 10px;
  }
}
.teasers-white .box h4 {
  text-transform: uppercase;
  margin-bottom: 22px;
  letter-spacing: 0;
}
@media screen and (min-width: 768px) {
  .teasers-white .box h4 {
    margin-bottom: 0;
  }
}
.teasers-white .box h4.red {
  color: #000000;
}
.teasers-white .box .multi h4 {
  margin-bottom: 8px;
}
.teasers-white .box .multi p {
  margin-bottom: 20px;
}
.teasers-white .box p {
  /* In Extra Light Klasse auslagern*/
  line-height: 20px;
  font-weight: 300;
  font-size: 16px;
}
.teasers-white .box p + p {
  margin-top: 20px;
}
.teasers-white .box .link-wrapper {
  margin-top: 34px;
}
.teasers-white .box .link-wrapper .link {
  line-height: 40px;
  width: 100%;
  transition: all 300ms ease;
  padding: 11px 0 11px 18px;
}
.teasers-white .box .link-wrapper .link:hover:after {
  transform: translate3d(8px, 0, 0);
}
.teasers-white .box .link-wrapper .link:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 0;
  display: block;
  width: 11px;
  height: 11px;
  background: url(../../img/icons/arrow-gt-lila.svg) no-repeat top center;
  background-size: 11px 11px;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  .teasers-white .box .link-wrapper {
    margin-top: auto;
  }
}
@media screen and (min-width: 1440px) {
  .teasers-white .box .link-wrapper {
    margin-top: 0;
    position: absolute;
    bottom: 5px;
    left: 20px;
    right: 20px;
  }
}
.teasers-white .box .link.link-arrow-gt {
  margin-left: 18px;
}
@media screen and (min-width: 768px) {
  .teasers-white .box .link.link-arrow-gt {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 7px 0 0 0;
  }
}
.teasers-white .box iframe {
  margin: 25px 0 0 0;
  max-width: 100%;
}
.teasers-white .box iframe.hidden {
  display: none;
}
.teasers-white .box.box-contact {
  border: 1px solid #90BF51;
}
.teasers-white .box.box-contact small {
  background: #90BF51;
}
.teasers-white .box.box-contact .link-wrapper .link {
  color: #90BF51;
}
.teasers-white .box.box-contact .link-wrapper .link:after {
  background: url(../../img/icons/arrow-gt-green.svg) no-repeat top center;
}
.teasers-white .green {
  color: #90BF51;
}

.nav-tab {
  margin: 21px 0 0 0;
}
.nav-tab ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-tab ul li {
  float: left;
  margin: 0;
  padding: 0 3px 0 0;
}
.nav-tab ul li.active a {
  color: #d40009;
  border-color: #d40009;
}
.nav-tab ul li a {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: #e5e4e4 1px solid;
  line-height: 24px;
  padding: 0 10px;
  color: #737374;
}

.search {
  padding: 35px 0 32px 0;
}
@media screen and (min-width: 768px) {
  .search {
    padding: 70px 0 60px 0;
  }
}
@media screen and (min-width: 768px) {
  .search .form-wrapper {
    margin: 0 -35px;
  }
}
.search form {
  margin: 0;
  position: relative;
  width: 100%;
  height: 62px;
  border-radius: 31px;
  border: #F1F2F1 1px solid;
}
.search form input {
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #0091AF;
  background: transparent;
  height: 60px;
  width: 100%;
  padding: 0 115px 0 60px;
  outline: none;
  color: #505050;
  border-radius: 28px;
}
@media screen and (min-width: 768px) {
  .search form input {
    padding: 0 120px 0 65px;
    font-size: 22px;
  }
}
.search form .btn {
  position: absolute;
  top: 3px;
  right: 4px;
  padding: 0 15px;
  outline: none;
  height: 55px;
}
@media screen and (min-width: 768px) {
  .search form .btn {
    padding: 0 45px;
  }
}
.search form span {
  position: absolute;
  top: 50%;
  left: 18px;
  display: block;
  width: 36px;
  height: 36px;
  border: #0091AF 2px solid;
  transform: translateY(-50%);
  border-radius: 50%;
  background: url(../../img/icons/magnifier-blue.svg) no-repeat center center;
  background-size: 20px 20px;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .search form span {
    width: 45px;
    height: 45px;
    left: 8px;
    background-size: 20px 22px;
  }
}

main .search:first-child {
  padding-top: 91px;
}
@media screen and (min-width: 768px) {
  main .search:first-child {
    padding-top: 126px;
  }
}

.autocomplete-suggestions {
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  color: #505050;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 4px 6px 0 rgba(80, 80, 80, 0.4);
}
.autocomplete-suggestions .autocomplete-suggestion {
  margin: 5px 0;
  padding-left: 65px;
}

.share {
  padding: 20px 0 12px 0;
  text-align: center;
}
.share h4 {
  margin: 0 0 18px 0;
}
@media screen and (min-width: 1024px) {
  .share h4 {
    margin: 0 0 30px 0;
  }
}
.share h4.red {
  color: #0091AF;
}
.share nav ul {
  list-style: none;
  display: inline-block;
}
.share nav ul li {
  float: left;
  padding-bottom: 10px;
  width: 33.33%;
}
@media screen and (min-width: 768px) {
  .share nav ul li {
    margin: 0 20px;
    padding-bottom: 0;
    width: auto;
  }
}
.share nav ul li.share-linkedin a {
  background-image: url(../../img/icons/share-linkedin.svg);
}
.share nav ul li.share-whatsapp a {
  background-image: url(../../img/icons/share-whatsapp.svg);
}
@media screen and (min-width: 768px) {
  .share nav ul li.share-whatsapp a {
    display: none;
    margin: 0;
  }
}
.share nav ul li.share-xing a {
  background-image: url(../../img/icons/share-xing.svg);
}
.share nav ul li.share-twitter a {
  background-image: url(../../img/icons/share-twitter.svg);
}
.share nav ul li.share-mail a {
  background-image: url(../../img/icons/share-mail.svg);
}
.share nav ul li a {
  display: block;
  width: 44px;
  height: 44px;
  text-indent: -9999px;
  overflow: hidden;
  background: url(../../img/icons/share-facebook.svg) no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .share nav ul li a {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .share nav ul li a {
    width: 54px;
    height: 54px;
  }
}

.header {
  position: relative;
  background: #505050;
  border-top: #A71680 4px solid;
  margin-bottom: 10px;
}
.header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  display: block;
  width: 100%;
  height: 90px;
  background: url(../../img/bg/header-border-lila.svg) no-repeat center center;
  background-size: 1920px 90px;
  z-index: 400;
}
.header.without-skew-cut:after {
  display: none;
}
.header .flickity-viewport {
  transition: height 300ms ease;
}
.header .flickity-page-dots {
  bottom: -12px;
  height: 45px;
  margin: 0;
  counter-reset: dot-count;
  display: none;
  z-index: 500;
}
@media screen and (min-width: 1024px) {
  .header .flickity-page-dots {
    display: block;
  }
}
.header .flickity-page-dots .dot {
  width: 45px;
  height: 50px;
  line-height: 48px;
  opacity: 1;
  background: transparent;
  border: 0;
  border-bottom: #d40009 2px solid;
  border-radius: 0;
  color: #505050;
  margin: 0 12px;
  transition: all 300ms ease;
}
.header .flickity-page-dots .dot:after {
  counter-increment: dot-count;
  content: "0" counter(dot-count);
  pointer-events: none;
}
.header .flickity-page-dots .dot.is-selected {
  color: rgba(80, 80, 80, 0.3);
  border-color: rgba(80, 80, 80, 0.3);
}
.header .cell {
  width: 100%;
}
.header .cell .cell-inner {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 80% 0 0 0;
}
@media screen and (min-width: 768px) {
  .header .cell .cell-inner {
    padding: 36.11111% 0 0 0;
  }
}
.header.header-lp {
  border-top: none;
}
.header.header-lp .cell-inner {
  min-height: 320px;
}
@media screen and (min-width: 768px) {
  .header.header-lp .cell-inner {
    min-height: 600px;
  }
}
.header.header-lp:after {
  background-image: url(../../img/bg/header-border-blue.svg);
}
.header.header-lp .layer-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.header.header-lp .layer {
  display: inline-block;
  color: #fff;
  font-size: 32px;
  line-height: 1.15;
  font-weight: 500;
  position: relative;
  background-color: rgba(0, 145, 175, 0.85);
  padding: 30px 20px;
}
@media screen and (min-width: 768px) {
  .header.header-lp .layer {
    font-size: 60px;
    padding: 50px 40px 30px;
  }
}
@media screen and (min-width: 1024px) {
  .header.header-lp .layer {
    font-size: 74px;
    padding: 72px 90px 68px 110px;
  }
}
.header.header-lp .layer .skew {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 35px;
}
.header.header-lp .layer .skew:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 35px;
  opacity: 0.85;
  background: url(../../img/bg/header-border-blue-total.svg) 100% 100% no-repeat;
  transform: scale(-1);
}

.animation {
  background: #505050;
  border-top: #d40009 4px solid;
  border-bottom: #d40009 6px solid;
  min-height: 49vw;
}
@media screen and (min-width: 1920px) {
  .animation {
    min-height: 950px;
  }
}
.animation .cell {
  width: 100%;
}
.animation .cell .cell-inner {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 1024px) {
  .animation .cell .cell-inner {
    height: 0;
  }
  .animation .cell .cell-inner .con {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .animation .cell .cell-inner {
    padding: 49% 0 0 0;
  }
}
.animation .video-wrapper { /*    object-fit: cover;*/ }
.animation .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.animation .box {
  margin: 196px 0 30px 0;
  opacity: 0;
}
.animation .box:first-child {
  opacity: 1;
}
@media screen and (min-width: 1024px) {
  .animation .box {
    margin: 0 0 30px 0;
  }
  .animation .box h1 {
    margin-bottom: 26px;
  }
}
.animation .box h1 {
  color: #d40009;
  margin-bottom: 20px;
  font-weight: 400;
}
.animation .box p {
  margin-bottom: 18px;
}

.intro {
  text-align: center;
  margin: 64px 0 32px;
}
@media screen and (min-width: 768px) {
  .intro {
    margin: 32px 0 32px;
  }
  .intro:first-child {
    margin-top: 127px;
  }
}
.intro p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #4e4e4e;
  font-weight: 500;
}
.intro p:first-child {
  text-transform: uppercase;
  font-size: 17px;
}
@media screen and (min-width: 768px) {
  .intro p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }
  .intro p:first-child {
    font-size: 20px;
    line-height: 32px;
  }
}
.intro h2,
.intro .h2 {
  font-size: 24px;
  line-height: 30px;
  color: #505050;
  margin-bottom: 70px;
}
@media screen and (min-width: 768px) {
  .intro h2,
  .intro .h2 {
    font-size: 48px;
    line-height: 60px;
  }
}
.intro h2:after,
.intro .h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 85px;
  height: 4px;
  transform: translateX(-50%);
  background: #A71680;
  margin: 25px 0 0 0;
}
@media screen and (min-width: 768px) {
  .intro h2,
  .intro .h2 {
    margin-bottom: 62px;
  }
}

.cta-button {
  padding-top: 70px;
}

.content-standard-image {
  margin: 32px 10px 32px 10px;
}
.content-standard-image.fullsize {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .content-standard-image {
    margin: 80px 0 95px 0;
  }
  .content-standard-image.fullsize {
    margin: 58px 0 70px 0;
  }
}
.content-standard-image figure figcaption {
  margin-top: 10px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #4e4e4e;
}

.content-text {
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .content-text {
    padding: 0;
  }
}
.content-text h4,
.content-text .h4 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: normal;
  color: #4e4e4e;
  margin-top: 44px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .content-text h4,
  .content-text .h4 {
    margin-top: 78px;
  }
}
.content-text p {
  line-height: 24px;
  color: #4e4e4e;
  margin-bottom: 25px;
}
.content-text p.text-red {
  color: #d40009;
}
.content-text p.text-bigger {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .content-text p.text-bigger {
    margin-bottom: 68px;
  }
}
@media screen and (min-width: 768px) {
  .content-text p {
    line-height: 26px;
  }
}
.content-text ul {
  margin-bottom: 15px;
  padding: 0 0 0 20px;
}
.content-text ul li {
  line-height: 24px;
  color: #4e4e4e;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .content-text .column-text {
    padding: 0;
    margin-top: 36px;
    column-count: 2;
    column-gap: 30px;
  }
}
.content-text .column-text h5 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}
.content-text .column-text ul {
  margin-bottom: 25px;
}
.content-text .column-text ul li {
  color: #0091AF;
  font-size: 20px;
  line-height: 32px;
}
.content-text hr {
  width: 85px;
  height: 4px;
  background: #90BF51;
  margin: 44px auto 24px auto;
  border: 0;
}
@media screen and (min-width: 768px) {
  .content-text hr {
    margin: 32px auto 24px auto;
  }
}
@media screen and (min-width: 1024px) {
  .content-text hr {
    margin: 80px auto 30px auto;
  }
}
.content-text blockquote {
  padding: 24px 0 0 20px;
}
@media screen and (min-width: 768px) {
  .content-text blockquote {
    padding: 26px 0 0 30px;
  }
}
.content-text blockquote.big {
  position: relative;
  padding: 0 0 0 26px;
  margin: 24px 0 24px 0;
  /* Roter strich nicht mit border gemacht, da line-height den Rand zu sehr vertikal aufspannt */
}
@media screen and (min-width: 768px) {
  .content-text blockquote.big {
    padding: 0 0 0 55px;
    margin: 60px 0 50px 0;
  }
}
.content-text blockquote.big:after {
  content: "";
  display: block;
  width: 5pX;
  position: absolute;
  top: 7px;
  bottom: 8px;
  left: 0;
  background: #A71680;
}
@media screen and (min-width: 768px) {
  .content-text blockquote.big:after {
    top: 15px;
    bottom: 12px;
  }
}
.content-text blockquote.big p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  font-style: italic;
  color: #4e4e4e;
}
@media screen and (min-width: 768px) {
  .content-text blockquote.big p {
    font-size: 36px;
    line-height: 60px;
  }
}
.content-text blockquote p {
  color: #A71680;
  font-weight: 500;
  font-style: italic;
}
.content-text .link-arrow-light + p {
  margin-top: 25px;
}

.content-headline {
  text-align: center;
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .content-headline {
    margin: 32px 0 72px 0;
  }
}
.content-headline h3,
.content-headline .h3 {
  font-size: 24px;
  line-height: 1.25;
  color: #4e4e4e;
  margin-bottom: 64px;
}
@media screen and (min-width: 768px) {
  .content-headline h3,
  .content-headline .h3 {
    font-size: 36px;
  }
}
.content-headline h3:after,
.content-headline .h3:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 85px;
  height: 4px;
  transform: translateX(-50%);
  background: #90BF51;
  margin: 30px 0 0 0;
}
.content-headline p {
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .content-headline p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }
}

.content-links {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .content-links {
    margin: 107px 0 32px 0;
  }
}
.content-links:not(.acc-brick) h3,
.content-links:not(.acc-brick) .h3 {
  font-size: 36px;
  line-height: 1.25;
  margin: 0 0 30px 0;
}
.content-links p {
  font-size: 20px;
  line-height: 32px;
}
.content-links .link-wrapper {
  padding: 20px 0;
}
.content-links .link-wrapper .link {
  margin: 0 0 18px 0;
}

.skew-cut-image {
  margin: 32px 0;
  background: #F8F8FC;
}
@media screen and (min-width: 768px) {
  .skew-cut-image {
    margin: 64px 0;
  }
}
.skew-cut-image figure {
  margin: 24px 0;
}
@media screen and (min-width: 768px) {
  .skew-cut-image figure {
    margin: 32px 0;
  }
}
.skew-cut-image figure figcaption {
  font-size: 22px;
  margin-top: 24px;
  font-weight: 400;
  text-align: center;
}

.image-slider {
  margin: 32px 0 90px 0;
}
.image-slider .cell {
  width: 100%;
}
.image-slider .flickity-viewport {
  transition: height 300ms ease;
}
.image-slider .flickity-page-dots {
  bottom: -55px;
  height: 45px;
  margin: 0;
  counter-reset: dot-count;
  z-index: 500;
}
.image-slider .flickity-page-dots .dot {
  width: 45px;
  height: 50px;
  line-height: 48px;
  opacity: 1;
  background: transparent;
  border: 0;
  border-bottom: #A71680 2px solid;
  border-radius: 0;
  color: #505050;
  margin: 0 12px;
  transition: all 300ms ease;
}
.image-slider .flickity-page-dots .dot:after {
  counter-increment: dot-count;
  content: "0" counter(dot-count);
  pointer-events: none;
}
.image-slider .flickity-page-dots .dot.is-selected {
  color: rgba(80, 80, 80, 0.3);
  border-color: rgba(80, 80, 80, 0.3);
}

.content-video {
  margin: 38px 0 44px 0;
}

.image-video-gallery {
  padding: 24px 0 30px 0;
  background: #F1F2F1;
}
.image-video-gallery .headline {
  padding-bottom: 32px;
}
.image-video-gallery .text {
  padding-bottom: 20px;
}
.image-video-gallery .carousel {
  margin: 0 80px;
}
.image-video-gallery .carousel-cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-video-gallery .col-auto {
  width: auto;
  flex: 1 1 0;
}
.image-video-gallery .col-shrink {
  width: auto;
  flex: 0 0 auto;
}
.image-video-gallery .image {
  display: inline-block;
  position: relative;
}
.image-video-gallery .image .link-download {
  display: block;
  position: absolute;
  right: 10px;
  bottom: 16px;
  height: 40px;
  width: 40px;
  background: url(../../img/icons/download-blue.svg) no-repeat center center;
  background-size: 75%;
  background-color: rgba(255, 255, 255, 0.45);
}
.image-video-gallery figcaption {
  font-size: 0.75em;
  padding: 6px 0;
}
.image-video-gallery .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.75em;
  padding: 6px 0;
}
.image-video-gallery .flickity-slider {
  display: flex;
  align-items: center;
}
.image-video-gallery .with-video .flickity-slider {
  padding-bottom: 30px;
}
.image-video-gallery .with-video .video-wrapper {
  margin-bottom: 40px;
}
.image-video-gallery .flickity-prev-next-button {
  background-color: #0091AF;
  right: -80px;
}
.image-video-gallery .flickity-prev-next-button svg {
  display: none;
}
.image-video-gallery .flickity-prev-next-button:after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: 11px;
  top: 14px;
  transform: rotate(-45deg);
  border: solid #FFFFFF;
  border-width: 0 4px 4px 0;
}
.image-video-gallery .flickity-prev-next-button.previous {
  right: auto;
  left: -80px;
}
.image-video-gallery .flickity-prev-next-button.previous:after {
  left: 17px;
  top: 14px;
  transform: rotate(135deg);
}
.image-video-gallery .thumbs {
  margin-top: 16px;
}
.image-video-gallery .thumbs .videothumb .image:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20px, -20px);
  width: 40px;
  height: 40px;
  background: url(../../img/icons/play-video.png) no-repeat center center;
  background-size: 75%;
  background-color: rgba(255, 255, 255, 0.45);
}
.image-video-gallery .item {
  margin-bottom: 30px;
}
.image-video-gallery .item .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  height: 100%;
  border: solid 3px transparent;
  cursor: pointer;
}
.image-video-gallery .item.is--active .inner {
  border-color: #0091AF;
}
.image-video-gallery .asset-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .image-video-gallery {
    padding: 60px 0;
  }
  .image-video-gallery .text {
    padding-bottom: 40px;
  }
  .image-video-gallery figcaption {
    font-size: 0.875em;
  }
  .image-video-gallery .caption {
    font-size: 0.875em;
  }
  .image-video-gallery .flickity-prev-next-button {
    height: 60px;
    width: 60px;
    right: -80px;
  }
  .image-video-gallery .flickity-prev-next-button:after {
    width: 26px;
    height: 26px;
    left: 11px;
    top: 17px;
  }
  .image-video-gallery .flickity-prev-next-button.previous {
    right: auto;
    left: -80px;
  }
  .image-video-gallery .flickity-prev-next-button.previous:after {
    left: 23px;
    top: 17px;
  }
}

.visual .skew-cut {
  position: relative;
  display: block;
  height: 70px;
  overflow: hidden;
}
.visual .skew-cut:before {
  content: "";
  display: block;
  width: 200%;
  height: 100px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) rotateZ(-2deg);
  background: #FFF;
}
.visual .skew-cut.skew-cut-bottom:before {
  transform: translateX(-50%) rotateZ(2deg);
  bottom: auto;
  top: 50%;
}
.visual p {
  color: #4e4e4e;
  line-height: 28px;
  font-weight: 400;
}
.visual p + p {
  margin-top: 18px;
}

.visual-right {
  margin: 0;
  background: #F8F8FC;
}
@media screen and (min-width: 1440px) {
  .visual-right {
    margin: 170px 0 0 0;
  }
}
.visual-right figure {
  margin: 24px 0 0 0;
  position: relative;
}
@media screen and (min-width: 768px) {
  .visual-right figure {
    margin: 44px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .visual-right figure {
    margin: 0;
  }
}
@media screen and (min-width: 1440px) {
  .visual-right figure {
    margin: -170px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .visual-right .left-description {
    padding-bottom: 64px;
  }
}
.visual-right .left-description h4 {
  margin: 32px 0 20px 0;
}
@media screen and (min-width: 1024px) {
  .visual-right .left-description h4 {
    margin: 109px 0 20px 0;
  }
}
.visual-right .center-description {
  padding-top: 24px;
  padding-bottom: 32px;
}
.visual-right .center-description h4 {
  margin: 0 0 20px 0;
}
@media screen and (min-width: 1024px) {
  .visual-right .center-description {
    padding-top: 52px;
    padding-bottom: 107px;
  }
}

.visual-left {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.visual-left h3 {
  margin: 32px 0 24px 0;
  font-size: 36px;
}
@media screen and (min-width: 1024px) {
  .visual-left h3 {
    margin: 60px 0 35px 0;
  }
}
.visual-left .center-description {
  padding-bottom: 44px;
}
@media screen and (min-width: 1024px) {
  .visual-left .center-description {
    padding-bottom: 90px;
  }
}
.visual-left .center-description p {
  font-size: 20px;
  line-height: 32px;
}
.visual-left .right-description {
  padding-top: 44px;
  padding-bottom: 32px;
}
@media screen and (min-width: 1024px) {
  .visual-left .right-description {
    padding-top: 84px;
    padding-bottom: 54px;
  }
}
.visual-left .right-description h4 {
  margin-bottom: 20px;
}

.visual-introduction {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.visual-introduction figure {
  margin-top: 30px;
}
.visual-introduction .right-description {
  padding-top: 25px;
}
.visual-introduction h4 {
  margin-bottom: 23px;
  line-height: 1.25;
}
.visual-introduction .contact-information {
  margin: 15px 0 23px 0;
}
.visual-introduction .contact-information p {
  font-weight: 500;
}
@media screen and (min-width: 1024px) {
  .visual-introduction .order-md-1 {
    order: 1;
  }
  .visual-introduction .order-md-2 {
    order: 2;
  }
}

.contact-person {
  background: #F7F7F7;
  margin: 32px 0 32px 0;
}
@media screen and (min-width: 768px) {
  .contact-person {
    margin: 58px 0 32px 0;
  }
}
.contact-person p {
  color: #4e4e4e;
  line-height: 28px;
  font-weight: 400;
}
.contact-person figure {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  width: 176px;
  height: 176px;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .contact-person figure {
    width: 100%;
    padding: 100% 0 0 0;
    margin: 0 auto 40px auto;
  }
}
.contact-person figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.contact-person h3 {
  margin: 0 0 10px 0;
}
@media screen and (min-width: 768px) {
  .contact-person h3 {
    margin: 35px 0 50px 0;
  }
}
.contact-person .description {
  padding-top: 8px;
}
@media screen and (min-width: 768px) {
  .contact-person .description {
    padding-top: 53px;
  }
}
.contact-person .person {
  text-align: center;
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .contact-person .person {
    max-width: 250px;
    float: left;
    text-align: left;
  }
}
.contact-person .person .competence {
  color: #a0a0a0;
  font-size: 16px;
  margin: 0 0 14px 0;
}
.contact-person .person .name {
  font-size: 20px;
  line-height: 24px;
  color: #4e4e4e;
  font-weight: 500;
  margin-bottom: 24px;
}
.contact-person .person .title {
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .contact-person .person .title {
    margin-bottom: 0;
  }
}
.contact-person .contact-information {
  padding: 0 0 0 30px;
  float: left;
}
.contact-person .contact-information a {
  color: #A71680;
}
.contact-person .contact-information .link.link-arrow-light {
  padding-top: 0;
  padding-bottom: 0;
}
.contact-person .contact-information .link.link-arrow-light:after {
  top: 9px;
}

.choose-contact-person {
  padding: 0 0 34px 0;
}
.choose-contact-person h2 {
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px 45px;
  color: #636364;
  letter-spacing: 0;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 500;
}
.choose-contact-person h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 85px;
  height: 4px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #A71680;
  margin: 12px 0 0 0;
}
.choose-contact-person h2 + p {
  padding: 20px 10px 16px;
}
@media screen and (min-width: 768px) {
  .choose-contact-person .distance {
    height: 40px;
  }
}
.choose-contact-person figure {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  width: 176px;
  height: 176px;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .choose-contact-person figure {
    margin: -40px auto 20px 0;
  }
}
.choose-contact-person figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.choose-contact-person .centered {
  padding-top: 10px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .choose-contact-person .centered {
    text-align: left;
  }
}
.choose-contact-person #chooser {
  font-size: 14px;
  line-height: 1;
  padding: 10px 5px;
  cursor: pointer;
  width: 80%;
  max-width: calc(100% - 20px);
  margin: 0 10px 30px;
}
.choose-contact-person #chooser option {
  font-size: 14px;
  line-height: 1;
  padding: 10px 5px;
}
.choose-contact-person .result {
  display: none;
  padding: 0 10px;
}
.choose-contact-person .result.show {
  display: flex;
}
.choose-contact-person .result .name {
  display: block;
  margin-bottom: 16px;
}
.choose-contact-person .result .position {
  margin-bottom: 16px;
}
@media screen and (min-width: 1024px) {
  .choose-contact-person {
    background: #f7f7f7;
    padding: 30px 0;
    margin: 30px 0 60px;
    position: relative;
  }
  .choose-contact-person:before, .choose-contact-person:after {
    content: "";
    position: absolute;
    height: 30px;
    width: 100%;
    top: 0;
    margin-top: -30px;
    left: 0;
    background: transparent url("../../img/bg/bg-top.svg") 0 0 no-repeat;
    background-size: cover;
  }
  .choose-contact-person:after {
    background-image: url("../../img/bg/bg-bottom.svg");
    top: auto;
    margin: 0 0 -30px;
    bottom: 0;
  }
  .choose-contact-person h2 {
    font-size: 30px;
    line-height: 1;
  }
}

.contact-form {
  margin: 0 0 32px 0;
}
.contact-form h3 {
  font-size: 24px;
  margin: 12px 0 24px 0;
}
@media screen and (min-width: 768px) {
  .contact-form h3 {
    font-size: 36px;
    margin: 40px 0 44px 0;
  }
}

.form .input-wrapper {
  position: relative;
  margin: 0 0 40px 0;
}
@media screen and (min-width: 1024px) {
  .form .input-wrapper {
    padding-right: 50px;
  }
  .form .input-wrapper.wide {
    padding-right: 0;
  }
}
.form .input-wrapper.label-above label {
  font-size: 14px;
  line-height: 15px;
  color: #505050;
  top: -15px;
}
.form .input-wrapper label {
  color: #a7a7a7;
  position: absolute;
  top: 0;
  left: 20px;
  font-size: 16px;
  line-height: 40px;
  pointer-events: none;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  .form .input-wrapper label {
    left: 30px;
    line-height: 62px;
  }
}
.form .input-wrapper input[type=text], .form .input-wrapper input[type=email], .form .input-wrapper input[type=password], .form .input-wrapper textarea {
  -webkit-appearance: none;
  background: #F8F8F8;
  border: 1px solid #0091AF;
  outline: none;
  border-radius: 20px;
  height: 40px;
  padding: 0 20px;
  width: 100%;
  font-size: 16px;
  color: #505050;
}
@media screen and (min-width: 768px) {
  .form .input-wrapper input[type=text], .form .input-wrapper input[type=email], .form .input-wrapper input[type=password], .form .input-wrapper textarea {
    height: 62px;
    border-radius: 31px;
    padding: 0 30px;
  }
}
.form .input-wrapper textarea {
  height: 165px;
  padding-top: 8px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  .form .input-wrapper textarea {
    height: 165px;
    padding-top: 19px;
  }
}
.form .checkbox-wrapper {
  position: relative;
  max-width: 540px;
}
.form .checkbox-wrapper input[type=checkbox] {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.form .checkbox-wrapper input[type=checkbox]:checked + label:after {
  background: #F8F8F8 url(../../img/icons/check-grey.svg) no-repeat center center;
  background-size: 11px 11px;
}
.form .checkbox-wrapper label {
  display: block;
  position: relative;
  padding: 3px 0 0 40px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: #4e4e4e;
}
.form .checkbox-wrapper label a {
  color: #d40009;
}
.form .checkbox-wrapper label a:hover {
  text-decoration: none;
}
.form .checkbox-wrapper label:after {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  border: 1px solid #0091AF;
  border-radius: 4px;
  background: #F8F8F8;
  position: absolute;
  top: 0;
  left: 0;
}
.form .btn-wrapper {
  padding: 10px 0 0 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .form .btn-wrapper {
    padding: 15px 50px 0 0;
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .form .btn-wrapper {
    padding: 36px 50px 0 0;
    text-align: right;
  }
}
.form .recaptcha-wrapper {
  text-align: center;
}
.form .recaptcha-wrapper .recaptcha-block {
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .form .recaptcha-wrapper {
    padding: 15px 50px 0 0;
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .form .recaptcha-wrapper {
    text-align: left;
  }
}

#contact-form-extern,
#press-form-extern {
  min-height: 120px;
}
#contact-form-extern h3,
#press-form-extern h3 {
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  #contact-form-extern h3,
  #press-form-extern h3 {
    margin-bottom: 24px;
  }
}
#contact-form-extern #easyXDM-wrapper,
#press-form-extern #easyXDM-wrapper {
  margin: 0 -10px;
}
@media screen and (min-width: 1024px) {
  #contact-form-extern #easyXDM-wrapper,
  #press-form-extern #easyXDM-wrapper {
    margin-right: 0;
    width: calc(100% - 30px);
  }
}
#contact-form-extern #easyXDM-wrapper > iframe,
#press-form-extern #easyXDM-wrapper > iframe {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .registration-form.btn-left .form .btn-wrapper {
    text-align: left;
  }
}
.article-nav {
  margin: 24px 10px 24px 10px;
}
.article-nav .newsplaceholder {
  height: 60px;
}
@media screen and (min-width: 768px) {
  .article-nav {
    margin: 97px 0 50px 0;
  }
  .article-nav .newsplaceholder {
    height: 90px;
  }
}
@media screen and (min-width: 768px) {
  .article-nav .arrow-wrapper {
    display: table;
    width: 100%;
  }
}
.article-nav .arrow {
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  width: 29px;
  height: 58px;
  transition: all 300ms ease;
  overflow: hidden;
  text-indent: -9999px;
  color: transparent;
}
@media screen and (min-width: 768px) {
  .article-nav .arrow {
    width: 100%;
    overflow: visible;
    text-indent: 0;
    color: #4e4e4e;
    height: 90px;
    vertical-align: middle;
    display: table-cell;
  }
  .article-nav .arrow:hover {
    color: #0091AF;
  }
  .article-nav .arrow:hover:after {
    transform: translateY(-50%) translateX(10px);
  }
  .article-nav .arrow:hover span {
    color: #0091AF;
  }
  .article-nav .arrow span {
    vertical-align: middle;
  }
}
@media screen and (min-width: 1024px) {
  .article-nav .arrow {
    font-size: 24px;
    line-height: 30px;
  }
}
.article-nav .arrow:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: block;
  width: 29px;
  height: 58px;
  background: url(../../img/icons/arrow-big-blue.svg) no-repeat center center;
  background-size: 29px 58px;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  .article-nav .arrow:after {
    left: 60px;
  }
}
@media screen and (min-width: 768px) {
  .article-nav .previous {
    padding: 0 0 0 120px;
  }
}
@media screen and (min-width: 768px) {
  .article-nav .previous:hover:after {
    transform: rotateZ(180deg) translateY(50%) translateX(10px);
  }
}
.article-nav .previous:after {
  transform: rotateZ(180deg) translateY(50%);
}
.article-nav .next {
  float: right;
}
@media screen and (min-width: 768px) {
  .article-nav .next {
    float: none;
    text-align: right;
    padding: 0 120px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .article-nav .next:after {
    left: auto;
    right: 60px;
  }
}
.article-nav .more-link {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #505050;
  margin-top: 18px;
}
.article-nav .more-link:hover {
  color: #0091AF;
}
@media screen and (min-width: 768px) {
  .article-nav .more-link {
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .article-nav .more-link {
    font-size: 24px;
    line-height: 30px;
  }
}

.video {
  background-color: #F1F2F1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: #90BF51 6px solid;
  position: relative;
}
.video .overlay-blue-edge {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../img/bg/flaeche-blau.png);
  background-position: bottom left;
  background-size: 30%;
  background-repeat: no-repeat;
}
.video .overlay-light {
  background-image: url(../../img/bg/flaeche-gruen.png);
  background-position: top right;
  background-size: 30%;
  background-repeat: no-repeat;
}
.video small {
  margin: 45px 0 30px 0;
}
.video h3,
.video .h3 {
  margin: 0 0 12px 0;
}
.video p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 26px 0;
}
.video .right-side {
  text-align: right;
}
.video .video-wrapper {
  margin: 50px 0 4px 0;
}
.video .link {
  margin-bottom: 36px;
}

.video .video-wrapper.asset {
  margin: 20px 0;
}
@media screen and (min-width: 1024px) {
  .video .video-wrapper.asset {
    margin: 50px 0;
  }
}
.video .video-wrapper.asset video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.teasers-story {
  margin: 32px 0 90px 0;
  background: #35465c;
  background: linear-gradient(to bottom, #35465c 0%, #0b0b1c 100%);
}
@media screen and (min-width: 768px) {
  .teasers-story {
    background: transparent;
  }
}
.teasers-story .flickity-viewport {
  transition: height 300ms ease;
}
.teasers-story .flickity-page-dots {
  top: 100%;
  height: 45px;
  margin: 9px 0 0 0;
  counter-reset: dot-count;
  display: none;
}
@media screen and (min-width: 1024px) {
  .teasers-story .flickity-page-dots {
    display: block;
  }
}
.teasers-story .flickity-page-dots .dot {
  width: 45px;
  height: 50px;
  line-height: 48px;
  opacity: 1;
  background: transparent;
  border: 0;
  border-bottom: #A71680 2px solid;
  border-radius: 0;
  color: #505050;
  margin: 0 12px;
  transition: all 300ms ease;
}
.teasers-story .flickity-page-dots .dot:after {
  counter-increment: dot-count;
  content: "0" counter(dot-count);
  pointer-events: none;
}
.teasers-story .flickity-page-dots .dot.is-selected {
  color: rgba(80, 80, 80, 0.3);
  border-color: rgba(80, 80, 80, 0.3);
}
.teasers-story .cell {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.teasers-story .cell .cell-inner {
  position: relative;
  width: 100%;
  padding: 24px 24px 24px 24px;
}
@media screen and (min-width: 1024px) {
  .teasers-story .cell .cell-inner {
    width: 50%;
    margin-left: 50%;
    padding: 60px 30px 10px 0;
  }
}
@media screen and (min-width: 1440px) {
  .teasers-story .cell .cell-inner {
    padding: 60px 30px 10px 90px;
  }
}
.teasers-story .link-wrapper {
  text-align: right;
}
.teasers-story .box {
  margin: 0;
  opacity: 0;
}
.teasers-story .box:first-child {
  opacity: 1;
}
@media screen and (min-width: 1024px) {
  .teasers-story .box {
    margin: 0;
  }
  .teasers-story .box h1 {
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 767px) {
  .teasers-story .box {
    padding: 24px;
  }
}
.teasers-story .box h1 {
  color: #d40009;
  margin-bottom: 20px;
}
.teasers-story .box p {
  margin-bottom: 18px;
}

.products {
  padding: 24px 0 30px 0;
  background: #F1F2F1;
}
@media screen and (min-width: 768px) {
  .products {
    padding: 48px 0 60px 0;
  }
}
.products .category-nav-wrapper {
  text-align: center;
}
.products .category-nav {
  text-align: center;
  margin: 0 0 8px 0;
}
.products .category-nav ul {
  list-style: none;
  display: block;
}
@media screen and (min-width: 768px) {
  .products .category-nav ul {
    display: inline-block;
  }
}
.products .category-nav ul li {
  padding: 0 20px 20px 20px;
}
@media screen and (min-width: 768px) {
  .products .category-nav ul li {
    float: left;
    padding: 0 12px;
  }
}
@media screen and (min-width: 1024px) {
  .products .category-nav ul li {
    padding: 0 25px;
  }
}
.products .category-nav ul li.active button {
  color: rgba(80, 80, 80, 0.3);
  border-color: rgba(80, 80, 80, 0.3);
  cursor: auto;
}
.products .category-nav ul li.active button:hover {
  color: rgba(80, 80, 80, 0.3);
}
.products .category-nav ul li button {
  -webkit-appearance: none;
  border: 0;
  border-bottom: #A71680 2px solid;
  background: transparent;
  border-radius: 0;
  font-size: 20px;
  line-height: 32px;
  padding: 5px;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .products .category-nav ul li button {
    width: 150px;
  }
}
.products .category-nav ul li button:hover {
  color: #d40009;
}
.products .col-md-4 {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .products .col-md-4 {
    padding-top: 48px;
  }
}
.products .box .box-inner {
  padding-bottom: 80px;
}
@media screen and (min-width: 768px) {
  .products .box .box-inner {
    padding-bottom: 156px;
  }
}
.products .box .link {
  margin: 0;
  position: absolute;
  bottom: 22px;
  left: 20px;
  right: 20px;
}
.products .box .link-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.products .box.box-standard-margin .h3.no-transform {
  text-transform: none;
}

.teasers-press .filter-wrapper {
  width: 210px;
  margin: 0 14px 40px 14px;
}
.teasers-press .teasers-wrapper {
  background: #F1F2F1;
}
.teasers-press .link-wrapper {
  text-align: right;
  padding-top: 14px;
  padding-bottom: 14px;
}

@media screen and (max-width: 767px) {
  .teasers-press-left .teasers-wrapper {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.teasers-press-left .filter-wrapper {
  margin: 44px 14px 14px 14px;
}
.teasers-press-left .box-press {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .teasers-press-left .box-press {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.teasers-search .uppercase {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.teasers-search .uppercase + .uppercase {
  margin-bottom: 44px;
}
@media screen and (min-width: 768px) {
  .teasers-search .uppercase {
    margin-left: -35px;
    margin-right: -35px;
  }
}
.teasers-search .search-result {
  border-top: #DDD 1px solid;
  padding: 24px 0;
}
.teasers-search .search-result:last-child {
  border-bottom: #DDD 1px solid;
  margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .teasers-search .search-result {
    margin: 0 -35px;
  }
}
.teasers-search .search-result h4 {
  margin-bottom: 24px;
}
.teasers-search .search-result h4 a {
  color: #d40009;
  text-transform: uppercase;
}
.teasers-search .search-result p {
  margin-bottom: 24px;
}
.teasers-search .search-result p strong {
  font-weight: 600;
}

.press-form {
  margin: 48px 0 0 0;
}
@media screen and (min-width: 768px) {
  .press-form {
    margin: 90px 0 0 0;
  }
}
.press-form p {
  margin: 10px 0 38px 0;
  line-height: 1;
}

.press-dates {
  background: #F1F2F1;
  margin: 64px auto;
  padding-top: 32px;
  padding-bottom: 48px;
}
@media screen and (min-width: 768px) {
  .press-dates {
    margin: 90px auto;
    padding: 64px 0 84px 0;
  }
}
.press-dates h3 {
  margin: 0 0 30px 0;
}
.press-dates .filter-wrapper {
  min-width: 210px;
}
.press-dates .reminder, .press-dates .email-reminder {
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  width: 30px;
  height: 30px;
  background: #505050;
  float: right;
  text-align: left;
  background: url(../../img/icons/reminder-email.svg) no-repeat center center;
  background-size: 25px 25px;
}
.press-dates .reminder {
  background-image: url(../../img/icons/calender-grey.svg);
}
.press-dates .email-reminder {
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .press-dates .email-reminder {
    margin-left: 25px;
  }
}
.press-dates table {
  margin: 30px 0 0 0;
}
@media screen and (max-width: 767px) {
  .press-dates table {
    display: block;
  }
  .press-dates table thead {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .press-dates table tbody {
    display: block;
  }
  .press-dates table tbody tr {
    display: block;
    border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  }
  .press-dates table tbody tr:after {
    content: "";
    display: table;
    clear: both;
  }
}
@media screen and (max-width: 767px) {
  .press-dates table tbody td {
    display: block;
    border: 0;
    width: 50%;
    float: left;
  }
  .press-dates table tbody td:first-child {
    width: 100%;
    float: none;
  }
  .press-dates table tbody td:nth-child(2) {
    line-height: 30px;
  }
}
.press-dates small {
  display: none;
}
@media screen and (max-width: 767px) {
  .press-dates small {
    display: block;
    opacity: 0.5;
  }
}
.press-dates tr[data-type=cid_archive] {
  display: none;
}

main > .press-dates.press-dates-with-tabs {
  max-width: 1170px;
  padding-right: 10px;
  padding-left: 10px;
}
main > .press-dates.dates-landingpage {
  max-width: 720px;
  padding-right: 10px;
  padding-left: 10px;
}

.h30 {
  height: 30px;
}

.press-dates-with-tabs {
  background: #FFF;
  margin: 32px auto;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .press-dates-with-tabs {
    margin: 64px auto;
    padding: 0;
  }
}
.press-dates-with-tabs h3 {
  margin: 0 0 30px 0;
}
.press-dates-with-tabs .tab-wrapper {
  background: #F1F2F1;
  padding: 32px 0 48px 0;
  margin-left: -10px;
  margin-right: -10px;
}
@media screen and (min-width: 768px) {
  .press-dates-with-tabs .tab-wrapper {
    padding: 64px 0 84px 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.press-dates-left table {
  width: calc(100% - 20px - 20px);
  margin: 30px auto 0 auto;
}
@media screen and (min-width: 768px) {
  .press-dates-left table {
    width: calc(100% - 40px - 40px);
  }
}

.dates-landingpage {
  background: #fff;
}
.dates-landingpage h3 {
  margin-bottom: 8px;
}
.dates-landingpage .reminder {
  background-image: url(../../img/icons/calender-blue.svg);
}
.dates-landingpage .link-blue {
  color: #0091AF;
}

.press-kit {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .press-kit {
    margin: 64px 0;
  }
}
.press-kit h3 {
  margin: 0 0 20px 0;
}
.press-kit table {
  margin: 30px 0 0 0;
}
@media screen and (max-width: 767px) {
  .press-kit table {
    display: block;
  }
  .press-kit table thead {
    display: none;
  }
  .press-kit table tbody {
    display: block;
  }
}
.press-kit table th {
  width: 33.3333333333%;
}
@media screen and (max-width: 767px) {
  .press-kit table tr {
    display: block;
    border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  }
}
.press-kit table td {
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  .press-kit table td {
    display: block;
    border-bottom: 0;
  }
  .press-kit table td:last-child {
    padding: 0;
  }
  .press-kit table td:last-child .dropdown-wrapper {
    padding-bottom: 10px;
    padding-left: 25px;
  }
}
.press-kit .toggle-accordion {
  position: relative;
  display: block;
  padding: 11px 0 11px 25px;
  cursor: pointer;
}
.press-kit .toggle-accordion.opened:after {
  transform: translateY(-50%) rotateZ(90deg);
}
.press-kit .toggle-accordion:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../../img/icons/arrow-gt-lila.svg) no-repeat center center;
  background-size: 11px 11px;
  transition: all 300ms ease;
}
.press-kit .dropdown-wrapper {
  display: none;
}
.press-kit .document {
  display: block;
  color: #505050;
  padding: 6px 0;
}
.press-kit .document:hover {
  color: #d40009;
}
.press-kit .document p {
  float: left;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
}
.press-kit .document div {
  float: right;
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
}
.press-kit .document div span {
  display: inline;
}
.press-kit .document img {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 20px;
}

table {
  width: 100%;
  text-align: left;
}
table thead th {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #505050;
  padding: 10px 0;
  border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  vertical-align: middle;
}
table thead th:last-child {
  text-align: right;
}
table tbody td {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #505050;
  padding: 10px 0;
  border-bottom: rgba(80, 80, 80, 0.3) 1px solid;
  vertical-align: middle;
}
table tbody td:first-child {
  font-weight: 500;
}

.news-details tbody td:last-child {
  text-align: left;
}
.news-details tbody td table td:last-child {
  text-align: right;
}

.press-contact-form iframe {
  width: 1px;
  min-width: 100%;
  *width: 100%;
}

.media {
  background: #F1F2F1;
  margin: 32px 0;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .media {
    margin: 64px 0;
    padding: 64px 0 84px 0;
  }
}
.media h3 {
  margin: 0 0 20px 0;
}
.media h4 {
  margin: 44px 0 20px 0;
}
.media .video-box + .video-box {
  margin-top: 40px;
}
.media .link-wrapper {
  text-align: right;
}
.media .image-box {
  position: relative;
  background: #FFF;
  margin: 0 0 24px 0;
}
.media .image-box figure {
  width: 50%;
  float: left;
  padding: 10px 0 10px 10px;
}
.media .image-box .right-description {
  float: left;
  width: 50%;
  padding: 10px 20px 0 20px;
}
.media .image-box .right-description p {
  line-height: 24px;
}
.media .image-box .right-description .link {
  position: absolute;
  bottom: 0;
}

.printOnly {
  display: none;
}

.content-image-text {
  background-color: #F1F2F1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: #0091AF 6px solid;
}
.content-image-text .overlay-light {
  padding-bottom: 20px;
}
.content-image-text small {
  margin: 45px 0 30px 0;
}
.content-image-text h3 {
  margin: 20px 0 26px 0;
}
.content-image-text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 26px 0;
}
.content-image-text .image-wrapper {
  margin-top: 10px;
}
.content-image-text .image-wrapper img {
  height: auto;
}
.content-image-text .mt20-50 {
  margin-top: 20px;
}
@media screen and (min-width: 1024px) {
  .content-image-text .overlay-light {
    padding-bottom: 50px;
  }
  .content-image-text h3 {
    margin-top: 51px;
  }
  .content-image-text .image-wrapper {
    margin-top: 50px;
  }
  .content-image-text .mt20-50 {
    margin-top: 50px;
  }
}

.mediathek {
  padding: 24px 0 30px 0;
  background: #F1F2F1;
}
.mediathek .filter-wrapper {
  width: 210px;
  margin: 0 14px 40px 14px;
}
@media screen and (min-width: 768px) {
  .mediathek {
    padding: 48px 0 60px 0;
  }
}
.mediathek .col-md-4 {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .mediathek .col-md-4 {
    padding-top: 48px;
  }
}
.mediathek .box small, .mediathek .box h3, .mediathek .box p {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.mediathek .box .box-inner {
  padding-bottom: 80px;
}
@media screen and (min-width: 768px) {
  .mediathek .box .box-inner {
    padding-bottom: 156px;
  }
}
.mediathek .box .bottom-row {
  position: absolute;
  bottom: 22px;
  left: 20px;
  right: 20px;
}
.mediathek .box .link {
  margin: 0;
  text-indent: -9999px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.mediathek .box .link.link-arrow span {
  left: auto;
  right: 0;
}
.mediathek .box .link.link-detail {
  float: right;
}
.mediathek .box .link.link-download {
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  height: 43px;
  width: 43px;
  background: url(../../img/icons/download-blue.svg) no-repeat center center;
  background-size: 100%;
}

.mediathek-detail .mediathek-header {
  margin: 75px 0 52px 0;
}
.mediathek-detail .mediathek-header h2 {
  text-transform: uppercase;
}
.mediathek-detail .media-description {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .mediathek-detail .media-description {
    margin-top: 0;
  }
}
.mediathek-detail .media-categories {
  margin-top: 48px;
}
.mediathek-detail .media-categories ul {
  margin-top: 15px;
  padding: 0 0 0 20px;
}
.mediathek-detail .media-categories ul li {
  line-height: 24px;
  color: #505050;
  margin-bottom: 10px;
}
.mediathek-detail .mediathek-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  min-width: 80vw;
}
.mediathek-detail .mediathek-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mediathek-detail .mediathek-footer {
  margin: 100px 0 75px 0;
}
.mediathek-detail .mediathek-footer .btn-download {
  background-color: #d40009;
  color: #FFFFFF;
  width: 90%;
  height: 55px;
  line-height: 55px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .mediathek-detail .mediathek-footer .btn-download {
    width: 387px;
  }
}
.mediathek-detail .mediathek-footer .btn-download span {
  display: inline-block;
  position: relative;
  top: 9px;
  height: 37px;
  width: 37px;
  background: url(../../img/icons/download-white.svg) no-repeat center center;
  background-size: 37px auto;
  margin-right: 10px;
}

.contact-teaser {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .contact-teaser {
    margin: 64px 0;
  }
}
.contact-teaser .wrapper {
  padding: 24px 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .contact-teaser .wrapper {
    padding: 64px 0;
  }
}
.contact-teaser h3,
.contact-teaser .h3 {
  max-width: 90%;
  margin: 0 auto 24px auto;
}
@media screen and (min-width: 768px) {
  .contact-teaser h3,
  .contact-teaser .h3 {
    margin: 0 auto 32px auto;
  }
}
.contact-teaser p {
  max-width: 90%;
  margin: 0 auto 24px auto;
}
.contact-teaser p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .contact-teaser p {
    max-width: 50%;
  }
}

.maps {
  margin: 64px 0 0 0;
}
.maps .wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 150% 0 0 0;
}
@media screen and (min-width: 768px) {
  .maps .wrapper {
    padding: 75% 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .maps .wrapper {
    padding: 50% 0 0 0;
  }
}
.maps .wrapper #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.maps .wrapper h3 {
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: 0;
  pointer-events: none;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .maps .wrapper h3 {
    bottom: auto;
    top: 24px;
  }
}
.maps .wrapper .map-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../img/bg/aixtron_maps_placeholder.jpg") no-repeat center center;
}
.maps .wrapper .map-overlay img {
  display: none;
}
@media screen and (min-width: 1024px) {
  .maps .wrapper .map-overlay {
    background: transparent;
  }
  .maps .wrapper .map-overlay img {
    display: block;
  }
}
.maps .wrapper .map-overlay .inner {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: rgba(244, 244, 244, 0.7);
}
@media screen and (max-width: 479px) {
  .maps .wrapper .map-overlay .inner p {
    font-size: 14px;
  }
  .maps .wrapper .map-overlay .inner .btn {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
  }
}
.maps .wrapper .map-overlay .inner a {
  color: #0091AF;
}
.maps .wrapper .map-overlay .activateGoogleMap {
  margin-top: 10px;
}
@media screen and (min-width: 480px) {
  .maps .wrapper .map-overlay .activateGoogleMap {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .maps .wrapper .map-overlay .activateGoogleMap {
    margin-top: 30px;
  }
}

.map {
  position: relative;
}
.map .map-overlay {
  height: 240px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../img/bg/aixtron_maps_placeholder_small.jpg") no-repeat center center;
  background-size: cover;
}
.map .map-overlay .inner {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: rgba(244, 244, 244, 0.9);
}

.training-center {
  margin: 32px 0 64px 0;
}
@media screen and (min-width: 768px) {
  .training-center {
    margin: 64px 0;
  }
}
.training-center h3 {
  margin: 0 0 32px 0;
}
.training-center p {
  margin-bottom: 25px;
}
.training-center ul {
  margin-bottom: 20px;
  padding: 0 0 0 20px;
}
.training-center ul li {
  line-height: 24px;
  color: #4e4e4e;
  margin-bottom: 5px;
}
.training-center .select-wrapper {
  padding-top: 24px;
}
.training-center .select {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.training-center .select ul {
  margin: 0;
  padding: 0;
}
.training-center .select ul li {
  margin: 0;
}

.upgrades {
  background: #F1F2F1;
  padding-bottom: 25px;
}
.upgrades h3 {
  margin: 32px 0 25px 0;
}
@media screen and (min-width: 768px) {
  .upgrades {
    margin: 64px 0 25px 0;
  }
}
@media screen and (min-width: 768px) {
  .upgrades .column-text {
    padding: 0;
    margin-bottom: 32px;
    column-count: 2;
    column-gap: 30px;
    vertical-align: top;
    transform: translate3d(0, 0, 0);
  }
  .upgrades .column-text p {
    vertical-align: top;
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .upgrades .selects-wrapper {
    max-width: 86%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1440px) {
  .upgrades .selects-wrapper {
    max-width: 68%;
    margin: 0 auto;
  }
}
.upgrades .select-wrapper {
  display: none;
}
.upgrades .select {
  margin-top: 32px;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .upgrades .select {
    margin-top: 0;
    margin-bottom: 64px;
  }
}
.upgrades .upgrade-wrapper {
  display: none;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .upgrades .upgrade-wrapper {
    margin-bottom: 64px;
  }
}

.spare-parts {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .spare-parts {
    margin: 64px 0;
  }
}
.spare-parts h3 {
  margin: 0 0 24px 0;
}
.spare-parts h4 {
  margin: 48px 0 0 0;
}
.spare-parts a:hover {
  text-decoration: underline;
}

.sidebar-shares {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .sidebar-shares {
    text-align: left;
  }
}
.sidebar-shares iframe {
  margin: 25px auto 0 auto;
  max-width: 100%;
  width: 250px;
}
@media screen and (min-width: 1024px) {
  .sidebar-shares iframe {
    margin: 25px auto 0 auto;
  }
}
.sidebar-shares iframe.hidden {
  display: none;
}
.sidebar-shares .nav-tab ul {
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  .sidebar-shares .nav-tab ul {
    display: block;
  }
}

.accordion {
  list-style: none;
  margin: 24px 0 0 0;
}
.accordion li .accordion-header {
  position: relative;
  padding: 11px 40px 11px 20px;
  font-size: 16px;
  line-height: 22px;
  background: #F1F2F1;
  border-bottom: #DDD 1px solid;
  cursor: pointer;
  transition: all 300ms ease;
}
.accordion li .accordion-header:hover {
  color: #0091AF;
}
.accordion li .accordion-header:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotateZ(90deg);
  background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
  background-size: 11px 11px;
  transition: all 300ms ease;
}
.accordion li .accordion-header.opened:after {
  transform: translateY(-50%) rotateZ(-90deg);
}
.accordion li .accordion-body {
  display: none;
  padding-bottom: 20px;
}

.accordion-download {
  padding: 10px;
  border-bottom: #F1F2F1 1px solid;
}
.accordion-download p {
  float: left;
  transition: all 300ms ease;
}
.accordion-download a {
  position: relative;
  float: right;
}
.accordion-download a:hover p {
  color: #d40009;
}
.accordion-download a p {
  font-size: 16px;
}
.accordion-download a img {
  float: left;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 10px 0 0;
}

.accordion-link {
  padding: 10px 20px;
  border-bottom: #F1F2F1 1px solid;
}
.accordion-link p {
  transition: all 300ms ease;
}
.accordion-link a:hover p {
  color: #d40009;
}

.accordion-answer {
  padding: 10px;
}

.tab-wrapper.hidden {
  display: none;
}
.tab-wrapper .accordion {
  margin-top: 0;
}

.booklet {
  margin: 32px 0;
}
@media screen and (min-width: 768px) {
  .booklet {
    margin: 64px 0;
  }
}
.booklet .booklet-wrapper {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
}
.booklet .booklet-wrapper figure {
  margin-bottom: 2px;
}

#sticky-contact {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 499;
}
#sticky-contact > ul {
  width: 60px;
  position: relative;
  padding: 0;
  list-style-type: none;
  margin: 0;
}
#sticky-contact > ul > li {
  background-color: rgba(0, 0, 0, 0.72);
  height: 60px;
  margin-bottom: 8px;
}
#sticky-contact > ul > li:hover {
  background-color: rgba(0, 145, 175, 0.72);
}
#sticky-contact > ul > li.active {
  background-color: rgba(0, 145, 175, 0.72);
}
#sticky-contact > ul > li > a {
  display: block;
  height: 60px;
}
#sticky-contact > ul > li > a span {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
#sticky-contact > ul > li > a:after {
  content: "";
  display: block;
  height: 100%;
  position: relative;
  background: transparent url("../../img/icons/fly-partner.png") center center no-repeat;
}
#sticky-contact > ul > li.location a:after {
  background-image: url("../../img/icons/mercator-white.svg");
  background-size: 40px 40px;
}
#sticky-contact > ul > li.career a:after {
  background-image: url("../../img/icons/career-white.svg");
  background-size: 36px 36px;
}
#sticky-contact > ul > li:last-child {
  margin-bottom: 0;
}

.fly-container {
  display: none;
  position: fixed;
  top: 56px;
  right: 60px;
  width: calc(100% - 60px);
  height: calc(100% - 56px);
  background: #fff;
  border: solid 1px #808080;
  z-index: 499;
  /*.close {
    cursor: pointer;
    position: absolute;
    width: 33px;
    height: 33px;
    top: 60px;
    right: 60px;
    background: url('../../img/icons/close.png') 0 0 no-repeat;

    &:hover {
      background-position: 0 -33px;
    }
  }*/
}
@media screen and (min-width: 1980px) {
  .fly-container {
    max-width: 1920px;
    width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.fly-container.open {
  display: block;
}
.fly-container .frame {
  padding: 60px;
  position: relative;
  max-height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.fly-container .frame > .outer {
  max-width: 90rem;
  margin: 0 auto;
}
.fly-container .frame .fly-inner.light-grey {
  background: #f1f1f1;
}
.fly-container .frame .fly-inner .contact-item {
  padding: 2vw 0;
}
@media screen and (min-width: 1440px) {
  .fly-container .frame .fly-inner .contact-item {
    padding: 32px 0;
  }
}
.fly-container .frame .fly-inner .contact-item.row {
  padding: 22px 0 10px;
}
.fly-container .frame .fly-inner .contact-item:last-child {
  padding-bottom: 0;
}
.fly-container .frame .fly-inner a.link.link-arrow-light {
  padding-top: 0;
  padding-bottom: 0;
}
.fly-container .frame .fly-inner a.link.link-arrow-light:after {
  top: 7px;
}
@media screen and (min-width: 768px) {
  .fly-container .frame .fly-inner a.link-red:not(.link-arrow-light) {
    font-size: 17px;
  }
}
.fly-container .frame .fly-inner h2 {
  font-size: 36px;
  line-height: 1;
  letter-spacing: -0.035em;
  margin-bottom: 15px;
}
.fly-container .frame .fly-inner h3 {
  margin-bottom: 4px;
  font-style: italic;
}
.fly-container .frame .fly-inner p {
  line-height: 1.5;
  margin-bottom: 12px;
}
.fly-container .frame .fly-inner .sub-item {
  margin-bottom: 20px;
}
.fly-container .frame .fly-inner .sub-item:last-child {
  margin-bottom: 0;
}
.fly-container .frame .fly-inner .sub-item p {
  margin-bottom: 0;
}
.fly-container .close {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 33px;
  top: 60px;
  right: 60px;
}
.fly-container .close > div {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2px;
  background: #0091AF;
  transform: translate3d(-50%, -50%, 0);
  width: 0;
}
.fly-container .close > div > div {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 2px;
  background: #0091AF;
  transform: translate3d(-50%, -10px, 0);
  margin: -1px 0 0 0;
}
.fly-container .close > div .top {
  transform: translate3d(-50%, 0px, 0) rotateZ(-45deg);
}
.fly-container .close > div .bottom {
  transform: translate3d(-50%, 0px, 0) rotateZ(45deg);
}
.fly-container .close:hover > div {
  background: #505050;
}
.fly-container .close:hover > div > div {
  background: #505050;
}

#header.small ~ .fly-container {
  top: 42px;
  height: calc(100% - 42px);
}

@media screen and (min-width: 1440px) {
  /*.contact-item {
    display: flex;
    flex-wrap: wrap;
  }*/
  .col-lg-fifth-part {
    min-width: 20%;
    max-width: 20%;
  }
  .col-lg-two-fifth {
    min-width: 40%;
    max-width: 40%;
  }
  .col-lg-three-fifth {
    min-width: 60%;
    max-width: 60%;
  }
  .col-lg-four-fifth {
    min-width: 80%;
    max-width: 80%;
  }
}
@media screen and (max-width: 767px) {
  body {
    padding-bottom: 60px;
  }
  #sticky-contact {
    transform: translateY(0);
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
  #sticky-contact > ul {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #sticky-contact > ul > li {
    margin-bottom: 0;
    width: 33.333%;
    position: relative;
  }
  #sticky-contact > ul > li:after {
    content: "";
    position: absolute;
    top: 5px;
    right: -1px;
    width: 2px;
    height: 50px;
    background: #d40009;
  }
  #sticky-contact > ul > li:last-child:after {
    display: none;
  }
  .fly-container {
    right: 0;
    width: 100%;
    border: none;
  }
  .fly-container .frame {
    padding: 30px 20px 80px;
  }
  .fly-container .frame .fly-inner {
    text-align: center;
  }
  .fly-container .frame .fly-inner .contact-item {
    padding: 15px 0;
  }
  .fly-container .frame .fly-inner .contact-item.light-grey {
    background: #f1f1f1;
  }
  .fly-container .frame .fly-inner .contact-item:last-child {
    padding-bottom: 15px;
  }
  .fly-container .frame .fly-inner h2 {
    font-size: 18px;
    padding-right: 40px;
  }
  .fly-container .frame .fly-inner h3 {
    font-size: 16px;
  }
  .fly-container .frame .fly-inner p {
    margin-bottom: 0;
  }
  .fly-container .frame .fly-inner .txt-left {
    text-align: center;
  }
  .fly-container .close {
    top: 20px;
    right: 20px;
  }
}
#toTop {
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 70px;
  right: 24px;
  border-radius: 100%;
  background-color: #0091AF;
  border: 0;
  line-height: 1;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  display: none;
}
@media screen and (min-width: 1024px) {
  #toTop {
    bottom: 50px;
    right: 50px;
  }
}
#toTop:after {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  left: 17px;
  top: 23px;
  transform: rotate(-135deg);
  border: solid #FFFFFF;
  border-width: 0 4px 4px 0;
}

.contact-bricks {
  padding: 30px 10px 20px;
}
.contact-bricks h4 {
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .contact-bricks a.link.link-red:not(.link-arrow-light) {
    font-size: 17px;
  }
}
.contact-bricks a.link.link-arrow-light {
  padding-top: 0;
  padding-bottom: 0;
}
.contact-bricks a.link.link-arrow-light:after {
  top: 7px;
}
.contact-bricks.contact-list {
  background: #f1f1f1;
}
.contact-bricks.contact-list .item {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
}
.contact-bricks.contact-list .item .distance {
  margin-bottom: 10px;
}
.contact-bricks.contact-list .item:last-child {
  border-bottom: none;
}
.contact-bricks.contact-list .item h5 {
  font-size: 17px;
  line-height: 30px;
  font-style: italic;
  margin-bottom: 10px;
}
.contact-bricks.contact-spareparts .item {
  padding: 20px 0;
}
.contact-bricks.contact-locations {
  background: #f1f1f1;
}
.contact-bricks.contact-locations .item {
  padding: 20px 0;
}
.contact-bricks.contact-locations .item .distance {
  margin-bottom: 10px;
}
.contact-bricks.representatives .item {
  padding: 20px 0;
}
.contact-bricks.representatives .item .distance {
  margin-bottom: 10px;
}
.contact-bricks.contact-headquarter .item {
  padding: 10px 0;
}
.contact-bricks.contact-headquarter .item > .address {
  padding: 16px 10px;
}
.contact-bricks.contact-headquarter .map {
  padding: 20px 10px;
}
.contact-bricks.contact-headquarter #map ~ a {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .contact-bricks h4 {
    text-align: left;
    line-height: 30px;
  }
  .contact-bricks.contact-spareparts .item {
    padding: 0 0 20px;
  }
  .contact-bricks.contact-locations .item {
    padding: 0 0 20px;
  }
  .contact-bricks.representatives .item {
    padding: 0 0 20px;
  }
}

.cookiebot-declaration-content a {
  color: #d40009;
}
.cookiebot-declaration-content .CookieDeclarationTableCell,
.cookiebot-declaration-content .CookieDeclarationType,
.cookiebot-declaration-content .CookieDeclarationTableHeader {
  border-color: #ccc;
}
.cookiebot-declaration-content .CookieDeclarationTableHeader {
  font-size: 16px;
}

#CybotCookiebotDialogBodyLevelButtons input[type=checkbox].CybotCookiebotDialogBodyLevelButton:checked + label {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0NCOTA4NjU0MzQ0MTFFQTlGQzJENkRGMjEyOUUyRjAiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0NCOTA4NjQ0MzQ0MTFFQTlGQzJENkRGMjEyOUUyRjAiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0YxQTQyQTVCQkMyMTFFMzlBQjFDNDBGOTBERjMxQjIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0YxQTQyQTZCQkMyMTFFMzlBQjFDNDBGOTBERjMxQjIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz75Hq73AAAAvklEQVR42mL5//8/w1VGLgYigQMUT2AkQaMAEN+H0h+YGIgH86GaQOAD44ULF/4T0sFiYIku5MgClWDEo08BiM8j2TYBiA+gO9WAgBMvAHEhiMGEpgBkcgKSWAE0FGEgEe58pGBOQDIAZno/mu0XYAwmJIELaLavR9bw58JxFBNgGj+AQgpNswKSXCC6x5H9iE0zCDQC8QN8GpE1L4DyD0CDHzNusYh9gIbeQiy2o2oEevw/MWlO+/83OBsgwABcazRusyDFLwAAAABJRU5ErkJggg==");
}

#CybotCookiebotDialogBodyLevelButtons input[type=checkbox].CybotCookiebotDialogBodyLevelButton.CybotCookiebotDialogBodyLevelButtonDisabled + label {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjgzMjc3NEM2QkJDMjExRTNBN0ExOUJFMzFCMzdBRjdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjgzMjc3NEM3QkJDMjExRTNBN0ExOUJFMzFCMzdBRjdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODMyNzc0QzRCQkMyMTFFM0E3QTE5QkUzMUIzN0FGN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODMyNzc0QzVCQkMyMTFFM0E3QTE5QkUzMUIzN0FGN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz54CbH2AAABFElEQVR42oxSLQ/CQAy9I6hZMrVgQW4aCdnvGIoER8Lm+AcbWBIcFo0cFuw0dkxMMAlyvC69y/hek6VN19e+16ssy1JIKUUTC8Owh9o+wkNbNDSADICmCA18w1ZTIEAeg8juMkmS8h+oKAqR57nIsmzD0pYVVcdx5A+KHRQuLMuaEYj0+b5/br0UdT9QHANgcJwGQbCjWAOjKPKoM/yglhvB9dTW0WCrG5LGOI77SM5rz0IFKXILniRs2564rqsL2tzpQjQQKqoectdao9Q0zScJFVXwvsGteIr612F/Q279ql1rxKYqME8WNV17NL5+BSowCmnykUFngA6fnunt5Jj2Fhs9wV++XlKTy1GGzeptPQQYAF1/e0nsKZ1HAAAAAElFTkSuQmCC);
}

.boards_accordion {
  margin-bottom: 40px;
}
.boards_accordion .image-wrapper {
  margin-bottom: 16px;
}
.boards_accordion .image-wrapper img {
  margin: 0 auto;
}
.boards_accordion .text-wrapper {
  text-align: center;
}
.boards_accordion .text-wrapper h2 {
  margin-bottom: 4px;
}
.boards_accordion .text-wrapper h2 + p {
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .boards_accordion .image-wrapper {
    flex: 0 0 auto;
    margin-right: 5px;
    margin-bottom: 0;
    min-width: 0;
    width: auto;
  }
  .boards_accordion .image-wrapper img {
    margin: 0;
    height: auto;
  }
  .boards_accordion .text-wrapper {
    text-align: left;
    min-width: 0;
    width: auto;
    flex: 1 1 0;
  }
  .boards_accordion .img-right,
  .boards_accordion .img-left {
    flex-wrap: nowrap;
    align-items: center;
  }
  .boards_accordion .img-right .image-wrapper {
    order: 2;
    margin-right: 0;
    margin-left: 5px;
  }
  .boards_accordion .img-right .text-wrapper {
    order: 1;
    text-align: right;
  }
}
.boards_accordion .accordion {
  margin-top: 8px;
}
.boards_accordion .accordion .accordion-link p {
  font-size: 16px;
}

.career-offer img {
  height: auto !important;
}
.career-offer .button-holder {
  text-align: center;
}
.career-offer .button-holder.second {
  margin-top: 45px;
}
.career-offer h2 {
  font-size: 22px;
  color: #90BF51;
  margin: 6px 0 20px;
}
.career-offer h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 6px 0 18px;
}
.career-offer .widget-holder {
  margin: 30px auto;
  width: 256px;
  height: 154px;
}
@media screen and (min-width: 768px) {
  .career-offer .widget-holder {
    margin: 50px auto 56px;
  }
}

#jobSwitcher ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
#jobSwitcher ul li a {
  display: block;
  width: 27px;
  height: 20px;
  cursor: pointer;
  background: url("../../img/icons/filter-liste.svg") 0 0 no-repeat;
  background-size: contain;
}
#jobSwitcher ul li a span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
#jobSwitcher ul li a.is-active {
  background-image: url("../../img/icons/filter-liste-aktiv.svg");
}
#jobSwitcher ul li #showTiles {
  width: 20px;
  background-image: url("../../img/icons/filter-kachel.svg");
}
#jobSwitcher ul li #showTiles.is-active {
  background-image: url("../../img/icons/filter-kachel-aktiv.svg");
}
#jobSwitcher ul li + li {
  margin-left: 15px;
}

#tilesView,
#listView {
  display: none;
}
#tilesView.is-active,
#listView.is-active {
  display: flex;
}

.tabsContent .jobRows {
  padding-top: 5px;
}
.tabsContent .jobRows:first-child {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .tabsContent .jobRows:first-child {
    padding-top: 48px;
  }
}
.tabsContent .jobRows:nth-child(even) .box-background {
  background: #fafafa;
}

#listView .jobRows .box .box-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 20px 15px;
}
#listView .jobRows .box h3 {
  color: #0091AF;
  font-size: 20px;
  margin-bottom: 3px;
}
#listView .jobRows .box .location {
  font-size: 14px;
}
#listView .jobRows .box .location p:last-child {
  margin-bottom: 0;
}
#listView .jobRows .box .details p:last-child {
  margin-bottom: 0;
}
#listView .jobRows .box .link-arrow {
  padding: 0;
  margin: 1px 0 0 20px;
  width: 36px;
  height: 36px;
  position: relative;
  bottom: auto;
  left: 0;
  right: auto;
}
#listView .jobRows .box .title-and-details {
  width: 100%;
}
#listView .jobRows .box .id-and-link {
  width: 100%;
}
#listView .jobRows .box .id-and-link > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#listView .jobRows .box .id-and-link small {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  #listView .jobRows .box .title-and-details {
    flex: 1 1 0;
    width: auto;
    min-width: auto;
  }
  #listView .jobRows .box .id-and-link {
    flex: 0 0 auto;
    width: auto;
    min-width: auto;
    margin-left: 20px;
  }
  #listView .jobRows .box .id-and-link > div {
    align-items: flex-start;
  }
  #listView .jobRows .box .link-arrow {
    width: 45px;
    height: 45px;
  }
}

.jobs-anchor {
  margin-top: 50px;
}

.jobRows .title-and-details ul {
  padding: 5px 0 0 20px;
}

@media screen and (min-width: 768px) {
  .content-text .career-offer h4 {
    margin-top: 52px;
  }
  .content-text .career-offer h2 {
    font-size: 24px;
  }
  .jobRows .title-and-details .details {
    overflow: hidden;
    height: 0;
  }
  .jobRows > .box:hover .title-and-details .details {
    height: auto;
  }
}
.content-adaption-form {
  --padding: 20px;
  --padding-double: calc(var(--padding) * 2);
  --border-radius: var(--padding);
  --border-radius-half: calc(var(--padding) / 2);
  --color-white: #fff;
  --color-red: #c00000;
  --color-blue: #0091AF;
  --color-black: #000;
  --color-gray-1: #f0f0f0;
  --color-gray-2: #d1d1d1;
  --color-gray-3: #505050;
}
.content-adaption-form .icon-pdf {
  background-image: url("../../img/icons/file-pdf.svg");
  display: flex;
  margin: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
}
.content-adaption-form .icon-trash {
  background-image: url("../../img/icons/icon-trash.svg");
}
.content-adaption-form .icon {
  display: block;
  position: relative;
  z-index: 100;
  background-color: transparent;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.content-adaption-form .message {
  padding: var(--padding);
  border: 1px dashed var(--color-gray-2);
  border-radius: var(--border-radius);
  margin-bottom: var(--padding-double);
  background-color: rgba(0, 255, 0, 0.1);
}
.content-adaption-form .message h4 {
  margin-bottom: var(--padding);
}
.content-adaption-form .message.error {
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.content-adaption-form .file-upload label {
  width: 100%;
  min-height: 200px;
  background-color: var(--color-gray-1);
  border: 1px dashed var(--color-black);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--padding);
}
.content-adaption-form .file-upload label:hover {
  cursor: pointer;
}
.content-adaption-form .file-upload label span {
  max-width: 400px;
}
.content-adaption-form .file-upload label .file-upload-icon {
  height: 80px;
  width: 80px;
  --icon-color: var(--color-gray-2);
}
.content-adaption-form .file-upload label .file-upload-icon .ionicon path {
  stroke: var(--icon-color);
}
.content-adaption-form .file-upload .preview-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-adaption-form .file-upload .preview-container .previews {
  display: flex;
  margin-top: var(--padding);
  flex-direction: column;
  gap: var(--padding);
  justify-self: center;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
}
.content-adaption-form .file-upload .preview-container .previews.hidden {
  display: none;
}
.content-adaption-form .file-upload .preview-container .previews .preview {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-gray-1);
  gap: 10px;
  border-radius: var(--border-radius-half);
  width: 100%;
}
.content-adaption-form .file-upload .preview-container .previews .preview .thumbnail {
  height: 50px;
  width: 50px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-half);
  overflow: hidden;
}
.content-adaption-form .file-upload .preview-container .previews .preview span {
  display: flex;
  flex: 1;
  text-align: left;
  font-weight: 600;
}
.content-adaption-form .file-upload .preview-container .previews .preview button {
  height: 30px;
  width: 30px;
  border: none;
  background-color: rgba(255, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.content-adaption-form form {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
}
.content-adaption-form .row {
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 40px;
}
.content-adaption-form .input-wrapper, .content-adaption-form .form-group {
  margin-bottom: var(--padding);
  padding: 0;
}
.content-adaption-form .input-wrapper .is-invalid, .content-adaption-form .form-group .is-invalid {
  border-color: red !important;
}
.content-adaption-form .error label, .content-adaption-form .error.label-above label {
  color: var(--color-red);
}
.content-adaption-form .invalid-feedback {
  color: var(--color-red);
}
.content-adaption-form label, .content-adaption-form .form-label {
  font-weight: 600;
}
.content-adaption-form label ul, .content-adaption-form label ol, .content-adaption-form label p, .content-adaption-form label div, .content-adaption-form .form-label ul, .content-adaption-form .form-label ol, .content-adaption-form .form-label p, .content-adaption-form .form-label div {
  font-weight: inherit;
}
.content-adaption-form label ul, .content-adaption-form label ol, .content-adaption-form .form-label ul, .content-adaption-form .form-label ol {
  padding-left: var(--padding);
}
.content-adaption-form label .required, .content-adaption-form .form-label .required {
  color: var(--color-red);
}
.content-adaption-form .col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content-adaption-form .form-group {
  gap: var(--padding);
}
.content-adaption-form .language-aware-container {
  display: flex;
  flex-direction: column;
}
.content-adaption-form .language-aware-container .language-aware {
  width: 100%;
}
.content-adaption-form .language-aware-container .language-aware .fields {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
}
.content-adaption-form .tabs {
  display: flex;
  flex-direction: column;
  padding-top: var(--padding);
  padding-bottom: var(--padding);
  margin: 0;
}
.content-adaption-form .tabs-header {
  display: flex;
  flex-direction: row;
}
.content-adaption-form .tabs-header a {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  font-weight: 600;
  padding: 15px 30px;
  text-align: center;
  cursor: pointer;
  background-color: var(--color-gray-3);
  color: white;
}
.content-adaption-form .tabs-header a.active {
  background-color: var(--color-blue);
}
.content-adaption-form .tabs-content {
  padding: var(--padding);
  border: 1px dashed var(--color-gray-2);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.content-adaption-form .tabs-content .tab {
  display: none;
}
.content-adaption-form .tabs-content .tab .input-wrapper:last-of-type, .content-adaption-form .tabs-content .tab .form-group:last-of-type {
  margin-bottom: 0;
}
.content-adaption-form .tabs-content .tab.active {
  display: block;
}

@media screen and (max-width: 640px) {
  .content-adaption-form .tabs-header {
    display: flex;
    flex-direction: column;
  }
  .content-adaption-form .tabs-header a {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 10px;
  }
}
.teasers {
    padding: 30px 0 40px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include breakpoint($sm) {
        padding: 115px 0 60px 0;
    }

    &.teasers-alternative {
        padding: 0 0 40px 0;

        @include breakpoint($sm) {
            margin: 77px 0 0 0;
            padding: 0 0 97px 0;
        }


        h3 {
            margin: 35px 0 45px 0;
        }

        .box {
            .box-inner {
                padding-bottom: 156px;
            }

            .link {
                bottom: 25px;
                margin: 0;

                @include breakpoint($sm) {
                    bottom: 25px;
                    margin: 0;
                }
            }
        }

        &.teaser-lp {
            border-bottom: solid 8px $color-blue;
        }

        &.layover {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 145,175, .35);
            }
        }

        .with-teaser {
            padding: 50px 10px 0;

            @include breakpoint($sm) {
                padding: 140px 15px 0;
            }
        }

        .single-teaser {
            text-align: center;
            background-color: rgba(255, 255, 255, .85);
            padding: 30px 10px;

            h2 {
                margin: 0 0 45px 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: 85px;
                    height: 4px;
                    transform: translateX(-50%);
                    background: $color-blue;
                    margin: 12px 0 0 0;
                }
            }

            .btn-arrow {
                margin-top: 30px;
            }

            @include breakpoint($sm) {

                padding: 90px 90px 60px;

                h2 {
                    margin: 0 0 50px 0;

                    &:after {
                        margin: 18px 0 0 0;
                    }
                }
            }
        }
    }

    .xs-carousel {
        display: flex;
        width: 100%;
        flex-flow: row wrap;

        @include max-breakpoint($md) {
            width: 100%;
            padding: 0 15px;
            display: block;
            max-width: none;
        }
    }

    @include max-breakpoint($md) {
        .col-xs-12, .col-md-12 {
            width: 100%;
        }
    }


    @include max-breakpoint($sm) {
        .col-xs-12 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }


    .flickity-viewport {
        overflow: visible;
    }

    .flickity-prev-next-button {
        border-radius: 0;
        width: 30px;
        height: 45px;
        background: rgba(#FFF, 0.25);

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 22px;
            height: 22px;
            background: url(../../img/icons/arrow-gt-red.svg) no-repeat center center;
            background-size: 22px 22px;
            transform: translate3d(-50%, -50%, 0);
        }

        &.next {
            right: 0;
        }

        &.previous {
            left: 0;
            transform: rotateZ(180deg) translateY(50%);
        }

        svg {
            display: none;
        }
    }

    .box {
        small, h3, p {
            //text-shadow: 0 1px 2px rgba(#000, 0.5);
        }

        .link {
            margin: 0 0 20px 0;
            position: absolute;
            bottom: 45px;
            left: 20px;
            right: 20px;
            //text-shadow: 0 1px 2px rgba(#000, 0.5);

            @include breakpoint($md) {
                bottom: 60px;
            }
        }
    }

    .select {
        position: absolute;
        bottom: 0;
        left: 0;

        button {

          @include breakpoint($md) {
            height: 57px;
            line-height: 57px;
          }
        }
    }
}
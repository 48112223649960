.products {
    padding: 24px 0 30px 0;
    background: $color-grey-light;

    @include breakpoint($sm) {
        padding: 48px 0 60px 0;
    }

    .category-nav-wrapper {
        text-align: center;
    }

    .category-nav {
        text-align: center;
        margin: 0 0 8px 0;

        ul {
            list-style: none;
            display: block;

            @include breakpoint($sm) {
                display: inline-block;
            }


            li {
                padding: 0 20px 20px 20px;

                @include breakpoint($sm) {
                    float: left;
                    padding: 0 12px;
                }


                @include breakpoint($md) {
                    padding: 0 25px;
                }


                &.active {
                    button {
                        color: rgba($color-grey, 0.3);
                        border-color: rgba($color-grey, 0.3);
                        cursor: auto;

                        &:hover {
                            color: rgba($color-grey, 0.3);
                        }
                    }
                }

                button {
                    -webkit-appearance: none;
                    border: 0;
                    border-bottom: $color-lila 2px solid;
                    background: transparent;
                    border-radius: 0;
                    font-size: 20px;
                    line-height: 32px;
                    padding: 5px;
                    outline: none;
                    cursor: pointer;
                    transition: all 300ms ease;
                    width: 100%;

                    @include breakpoint($sm) {
                        width: 150px;
                    }


                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
    }

    .col-md-4 {
        padding-top: 20px;

        @include breakpoint($sm) {
            padding-top: 48px;
        }
    }

    .box {
        small, h3, p {
            //text-shadow: 0 1px 2px rgba(#000, 0.5);
        }

        .box-inner {
            padding-bottom: 80px;

            @include breakpoint($sm) {
                padding-bottom: 156px;
            }
        }

        .link {
            margin: 0;
            position: absolute;
            bottom: 22px;
            left: 20px;
            right: 20px;
            //text-shadow: 0 1px 2px rgba(#000, 0.5);
        }

        .link-overlay {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.box-standard-margin {
            .h3 {
                &.no-transform {
                    text-transform: none;
                }
            }
        }
    }
}
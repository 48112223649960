.content-adaption-form {
  --padding: 20px;
  --padding-double: calc(var(--padding) * 2);
  --border-radius: var(--padding);
  --border-radius-half: calc(var(--padding) / 2);
  --color-white: #fff;
  --color-red: #c00000;
  --color-blue: #0091AF;
  --color-black: #000;
  --color-gray-1: #f0f0f0;
  --color-gray-2: #d1d1d1;
  --color-gray-3: #505050;

  .icon-pdf {
    background-image: url('../../img/icons/file-pdf.svg');
    display: flex;
    margin: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
  }
  .icon-trash {
    background-image: url('../../img/icons/icon-trash.svg')
  }
  .icon {
    display: block;
    position: relative;
    z-index: 100;
    background-color: transparent;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .message {
    padding: var(--padding);
    border: 1px dashed var(--color-gray-2);
    border-radius: var(--border-radius);
    margin-bottom: var(--padding-double);

    h4 {
      margin-bottom: var(--padding);
    }
    background-color: rgba(0, 255, 0, 0.1);

    &.error {
      background-color: rgba(255, 0, 0, 0.1) !important;
    }

  }

  .file-upload {
    label {
      width: 100%;
      min-height: 200px;
      background-color: var(--color-gray-1);
      border: 1px dashed var(--color-black);
      border-radius: var(--border-radius);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--padding);
      &:hover {
        cursor: pointer;
      }

      span {
        max-width: 400px;
      }
      .file-upload-icon {
        height: 80px;
        width: 80px;
        --icon-color: var(--color-gray-2);

        .ionicon {
          path {
            stroke: var(--icon-color);
          }
        }

      }

    }
    .preview-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .previews {
        &.hidden {
          display: none;
        }
        display: flex;
        margin-top: var(--padding);
        flex-direction: column;
        gap: var(--padding);
        justify-self: center;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        width: 100%;

        .preview {
          padding: 10px;
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          background-color: var(--color-gray-1);
          gap: 10px;
          border-radius: var(--border-radius-half);
          width: 100%;

          .thumbnail {
            height: 50px;
            width: 50px;
            background-color: var(--color-white);
            border-radius: var(--border-radius-half);
            overflow: hidden;
          }

          span {
            display: flex;
            flex: 1;
            text-align: left;
            font-weight: 600;
          }

          button {
            height: 30px;
            width: 30px;
            border: none;
            background-color: rgba(255, 0,0, 0.2);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
  }
  .row {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 40px;
  }

  .input-wrapper, .form-group {
    margin-bottom: var(--padding);
    padding: 0;
    .is-invalid {
      border-color: red !important;
    }
  }

  .error, .error.label-above {
    label {
      color: var(--color-red);
    }
  }

  .invalid-feedback {
    color: var(--color-red);
  }

  label, .form-label {
    font-weight: 600;

    ul, ol, p, div {
      font-weight: inherit;
    }

    ul, ol {
      padding-left: var(--padding);
    }

    .required {
      color: var(--color-red);
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .form-group {
    gap: var(--padding);
  }

  .language-aware-container {
    display: flex;
    flex-direction: column;
    .language-aware {
      width: 100%;
      .fields {
        display: flex;
        flex-direction: column;
        gap: var(--padding);
      }
    }
  }


  .tabs {
    display: flex;
    flex-direction: column;
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    margin: 0;
    &-header {
      display: flex;
      flex-direction: row;
      a {
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        font-weight: 600;
        padding: 15px 30px;
        text-align: center;
        cursor: pointer;
        background-color: var(--color-gray-3);
        color: white;

        &.active {
          background-color: var(--color-blue);
        }
      }
    }
    &-content {

      padding: var(--padding);
      border: 1px dashed var(--color-gray-2);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      border-top-right-radius: var(--border-radius);
      .tab {
        display: none;

        .input-wrapper,.form-group {
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &.active {
          display: block;
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
  .content-adaption-form {
    .tabs {
      &-header {
        display: flex;
        flex-direction: column;
        a {
          border-radius: 0;
          width: 100%;
          max-width: 100%;
          overflow: hidden;
          padding: 10px;
        }
      }
    }
  }
}

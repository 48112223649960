.sidebar-downloads {}

.accordion {
    list-style: none;
    margin: 24px 0 0 0;

    li {
        .accordion-header {
            position: relative;
            padding: 11px 40px 11px 20px;
            font-size: 16px;
            line-height: 22px;
            background: $color-grey-light;
            border-bottom: #DDD 1px solid;
            cursor: pointer;
            transition: all 300ms ease;

            &:hover {
                color: $color-blue;
            }

            &:after {
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%) rotateZ(90deg);
                background: url(../../img/icons/arrow-gt-blue.svg) no-repeat center center;
                background-size: 11px 11px;
                transition: all 300ms ease;
            }

            &.opened {
                &:after {
                    transform: translateY(-50%) rotateZ(-90deg);
                }
            }
        }

        .accordion-body {
            display: none;
            padding-bottom: 20px;
        }
    }
}

.accordion-download {
    padding: 10px;
    border-bottom: $color-grey-light 1px solid;

    p {
        float: left;
        transition: all 300ms ease;
    }

    a {
        position: relative;
        float: right;

        &:hover {
            p {
                color: $color-red;
            }
        }

        p {
            font-size: 16px;
        }

        img {
            float: left;
            position: relative;
            top: 5px;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin: 0 10px 0 0;
        }
    }
}

.accordion-link {
    padding: 10px 20px;
    border-bottom: $color-grey-light 1px solid;

    p {
        transition: all 300ms ease;
    }

    a {
        &:hover {
            p {
                color: $color-red;
            }
        }
    }
}

.accordion-answer {
    padding: 10px;
}

.tab-wrapper {
    &.hidden {
        display: none;
    }

    .accordion {
        margin-top: 0;
    }
}
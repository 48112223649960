.sidebar-shares {
    text-align: center;

    @include breakpoint($md) {
        text-align: left;
    }


    iframe {
        margin: 25px auto 0 auto;
        max-width: 100%;
        width:250px;

        @include breakpoint($md) {
            margin: 25px auto 0 auto;
        }


        &.hidden {
            display: none;
        }
    }

    .nav-tab {
        ul {
            display: inline-block;

            @include breakpoint($md) {
                display: block;
            }
        }
    }
}
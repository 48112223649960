.mediathek {
  padding: 24px 0 30px 0;
  background: $color-grey-light;

  .filter-wrapper {
    width: 210px;
    margin: 0 14px 40px 14px;
  }

  @include breakpoint($sm) {
    padding: 48px 0 60px 0;
  }

  .col-md-4 {
    padding-top: 20px;

    @include breakpoint($sm) {
      padding-top: 48px;
    }
  }

  .box {
    small, h3, p {
      text-shadow: 0 1px 2px rgba(#000, 0.5);
    }

    .box-inner {
      padding-bottom: 80px;

      @include breakpoint($sm) {
        padding-bottom: 156px;
      }
    }
    .bottom-row {
      position: absolute;
      bottom: 22px;
      left: 20px;
      right: 20px;
    }
    .link {
      margin: 0;
      text-indent: -9999px;
      text-shadow: 0 1px 2px rgba(#000, 0.5);

      &.link-arrow {
        span {
          left: auto;
          right: 0;
        }
      }

      &.link-detail {
        float: right;
      }

      &.link-download {
        display: block;
        text-indent: -9999px;
        overflow: hidden;
        height: 43px;
        width: 43px;
        background: url(../../img/icons/download-blue.svg) no-repeat center center;
        background-size: 100%;
      }
    }
  }
}

.mediathek-detail {

  .mediathek-header {
    margin: 75px 0 52px 0;

    h2 {
      text-transform: uppercase;
    }
  }

  .media-description {
    margin-top: 20px;

    @include breakpoint($sm) {
      margin-top: 0;
    }

  }
  .media-categories{
    margin-top: 48px;

    ul {
      margin-top: 15px;
      padding: 0 0 0 20px;

      li {
        line-height: 24px;
        color: $color-grey;
        margin-bottom: 10px;
      }
    }
  }

  .mediathek-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    min-width: 80vw;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .mediathek-footer{
    margin: 100px 0 75px 0;

    .btn-download {
      background-color: $color-red;
      color: $color-white;
      width: 90%;
      height: 55px;
      line-height: 55px;
      text-align: center;

      @include breakpoint($sm) {
        width: 387px;
      }

      span {
        display: inline-block;
        position: relative;
        top: 9px;
        height: 37px;
        width: 37px;
        background: url(../../img/icons/download-white.svg) no-repeat center center;
        background-size: 37px auto;
        margin-right: 10px;
      }
    }
  }
}
